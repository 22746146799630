import { runInAction } from 'mobx'
import * as XLSX from 'xlsx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const toText = (it = {}) => {
  const { office_name = '', work_at, checkin_at, checkout_at } = it

  if (!checkin_at) return ''

  const workAt = timer.get(work_at).format('DD/MM/YY')
  const checkin = checkin_at ? timer.get(checkin_at).format('HH:mm') : '-'
  const checkout = checkout_at ? timer.get(checkout_at).format('HH:mm') : '-'
  const text = `${workAt} - ${office_name} - in: ${checkin} out: ${checkout}`

  return text
}

const Url = `${config.api}/v1/user-admin/attendance/report/working-summary`
const header = ['name', 'surname', 'nickname', 'counter', 'information']
let state
export class WorkingReport extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
    })
    state = this
  }

  toQuery(query = {}) {
    const {
      index = 1,
      per_page = 20,
      user_id,
      status,
      days,
      office_id,
      start_at,
      finish_at,
    } = query

    let q = `index=${index}&per_page=${per_page}`

    if (status) q += `&operator=${status}&days=${days}`
    if (user_id) q += `&user_id=${user_id}`
    if (office_id) q += `&office_id=${office_id}`
    if (start_at) q += `&start_at=${start_at.toISOString()}`
    if (finish_at) q += `&finish_at=${finish_at.toISOString()}`

    return q
  }

  async getList(query) {
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.list = list
      state.page = {
        index: 1,
        per_page: 20,
        total: list.length,
      }
    })
  }

  async export() {
    const list = this.toJS().list || []

    const today = timer.get()
    const date = today.format('DDMMYY-HHmm')

    const data_list = []
    for (const item of list) {
      const attendance_list = item.attendance_list || []
      const detail = attendance_list
        .map((it) => {
          return toText(it)
        })
        .join(', ')

      data_list.push([
        item.name || '',
        item.surname || '',
        item.nickname || '',
        item.counter || 0,
        detail,
      ])
    }

    data_list.unshift(header)
    const ws = XLSX.utils.aoa_to_sheet(data_list)
    const width = 25
    ws['!cols'] = [
      { width },
      { width },
      { width },
      { width: 10 },
      { width: 28 },
    ]
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, ws, 'attendance')

    XLSX.writeFile(workbook, `attendance-${date}.xlsx`)
  }
}

export default new WorkingReport()
