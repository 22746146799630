import {
  Home,
  Employee,
  LeaveReq,
  Organization,
  Task,
  Document,
  Payroll,
  Setting,
  Time,
  Department,
} from 'menu/icon'

const company = {
  key: 'company',
  icon: Organization,
  name: 'Company',
  name_th: 'บริษัท',
  submenu: [
    {
      key: 'company-info',
      name: 'Information',
      name_th: 'ข้อมูลบริษัท',
      link: `/company`,
      validate: /\x2Fcompany$/gi,
    },
    {
      key: 'company-department',
      name: 'Department',
      name_th: 'ฝ่าย/แผนก',
      link: '/department',
      validate: /\x2Fdepartment/gi,
    },
    {
      key: 'company-team',
      name: 'Team',
      name_th: 'ทีม',
      link: '/team',
      validate: /\x2Fteam/gi,
    },
  ],
}

const employee = {
  key: 'employee',
  icon: Employee,
  name: 'Employee',
  name_th: 'พนักงาน',
  submenu: [
    {
      key: 'employee-info',
      name: 'Employee List',
      name_th: 'พนักงาน',
      link: `/employee/detail`,
      validate: /\x2Femployee\x2Fdetail/,
    },
    {
      key: 'employee-import',
      name: 'Import',
      name_th: 'นำเข้าข้อมูล',
      link: '/employee/import',
      validate: /\x2Femployee\x2Fimport/gi,
    },
    {
      key: 'employee-allowance',
      name: 'Allowance',
      name_th: 'สวัดิการพนักงาน',
      link: `/employee/allowance`,
      validate: /\x2Femployee\x2Fallowance/gi,
    },
    {
      key: 'employee-config',
      name: 'Setting',
      name_th: 'ตั้งค่า',
      link: `/employee/setting`,
      validate: /\x2Femployee\x2Fsetting/gi,
    },
  ],
}

const leaveReq = {
  key: 'leave-request',
  icon: LeaveReq,
  name: 'Leave Request',
  name_th: 'การลางาน',
  submenu: [
    {
      key: 'submit-leave-request',
      name: 'Submit Request',
      name_th: 'ส่งใบลา',
      link: `/leave-req/submit`,
      validate: /\x2Fleave-req\x2Fsubmit/gi,
    },
    {
      key: 'pending-leave-request',
      name: 'Pending Request',
      name_th: 'รายการรอดำเนินการ',
      link: `/leave-req/pending`,
      validate: /\x2Fleave-req\x2Fpending/gi,
    },
    {
      key: 'balance-leave-request',
      name: 'Leave Balance',
      name_th: 'จำนวนการลา',
      link: `/leave-req/balance-user`,
      validate: /\x2Fleave-req\x2Fbalance-user/gi,
    },
    {
      key: 'report-leave-request',
      name: 'Leave Report',
      name_th: 'การลาของพนักงาน',
      link: `/leave-req/report`,
      validate: /\x2Fleave-req\x2Freport/gi,
    },
    {
      key: 'balance-report-leave-request',
      name: 'Summary Report',
      name_th: 'สรุปรายงาน',
      link: `/leave-req/balance-report`,
      validate: /\x2Fleave-req\x2Fbalance-report/gi,
    },
    {
      key: 'type-leave-request',
      name: 'Type Of Leave',
      name_th: 'ประเภทการลา',
      link: `/leave-req/type`,
      validate: /\x2Fleave-req\x2Ftype/gi,
    },
    {
      key: 'notification-leave-request',
      name: 'Notification',
      name_th: 'การแจ้งเตือน',
      link: `/leave-req/notification`,
      validate: /\x2Fleave-req\x2Fnotification/gi,
    },
    {
      key: 'setting-leave-request',
      name: 'Setting',
      name_th: 'ตั้งค่า',
      link: `/leave-req/setting`,
      validate: /\x2Fleave-req\x2Fsetting/gi,
    },
  ],
}

const reimburseMenu = {
  key: 'reimburse',
  icon: Task,
  name: 'Expense',
  name_th: 'การเบิกเงิน',
  submenu: [
    {
      key: 'pending-reimburse',
      name: 'Pending Request',
      name_th: 'รายการรอดำเนินการ',
      link: `/reimburse/pending`,
      validate: /\x2Freimburse\x2Fpending/gi,
    },
    {
      key: 'balance-reimburse',
      name: 'Expense Balance',
      name_th: 'จำนวนการเบิกเงินของพนักงาน',
      link: `/reimburse/balance`,
      validate: /\x2Freimburse\x2Fbalance/gi,
    },
    {
      key: 'report-reimburse',
      name: 'Expense Report',
      name_th: 'การเบิกเงินของพนักงาน',
      link: `/reimburse/report`,
      validate: /\x2Freimburse\x2Freport/gi,
    },
    {
      key: 'type-reimburse',
      name: 'Cost Center',
      name_th: 'ศูนย์ค่าใช้จ่าย',
      link: `/reimburse/cost-center`,
      validate: /\x2Freimburse\x2Fcost-center/gi,
    },
    {
      key: 'notification-reimburse',
      name: 'Notification',
      name_th: 'การแจ้งเตือน',
      link: `/reimburse/notification`,
      validate: /\x2Freimburse\x2Fnotification/gi,
    },
    {
      key: 'setting-reimburse',
      name: 'Setting',
      name_th: 'ตั้งค่า',
      link: `/reimburse/setting`,
      validate: /\x2Freimburse\x2Fsetting/gi,
    },
  ],
}
/*
const documentMenu = {
  key: 'doc',
  icon: Document,
  name: 'Document',
  name_th: 'เอกสาร',
  submenu: [
    {
      key: 'eslip-doc',
      name: 'E-Payslip',
      name_th: 'สลิปเงินเดือน',
      link: `/document/eslip`,
      validate: /\x2Fdocument\x2Feslip/gi,
    },
  ],
}
*/

const attendanceMenu = {
  key: 'attendance',
  icon: Time,
  name: 'Attendance',
  name_th: 'ลงเวลาเข้าออกงาน',
  submenu: [
    {
      key: 'report-attendance',
      name: 'Report',
      name_th: 'รายงาน',
      link: `/attendance/report`,
      validate: /\x2Fattendance\x2Freport/gi,
    },
    {
      key: 'working-report-attendance',
      name: 'Working Report',
      name_th: 'รายงานการลงเวลา',
      link: `/attendance/working-report`,
      validate: /\x2Fattendance\x2Fworking-report/gi,
    },
    {
      key: 'summary-attendance',
      name: 'Summary',
      name_th: 'สรุปรายงาน',
      link: `/attendance/summary`,
      validate: /\x2Fattendance\x2Fsummary/gi,
    },
    {
      key: 'monthly-attendance',
      name: 'Monthly Report',
      name_th: 'รายงานรายเดือน',
      link: `/attendance/monthly-report`,
      validate: /\x2Fattendance\x2Fmonthly-report/gi,
    },
    {
      key: 'employee-attendance',
      name: 'Shift',
      name_th: 'กะทำงาน',
      link: `/attendance/working-employee`,
      validate: /\x2Fattendance\x2Fworking-employee/gi,
    },
    {
      key: 'import-attendance',
      name: 'Import Attendance',
      name_th: 'นำเข้าข้อมูลลงเวลา',
      link: `/attendance/import-check`,
      validate: /\x2Fattendance\x2Fimport/gi,
    },
    {
      key: 'working-time-attendance',
      name: 'Working Time',
      name_th: 'เวลาทำงาน',
      link: `/attendance/working-time`,
      validate: /\x2Fattendance\x2Fworking-time/gi,
    },
    {
      key: 'attendance-office',
      name: 'Office',
      name_th: 'สถานที่',
      link: `/attendance/office`,
      validate: /\x2Fattendance\x2Foffice/gi,
    },
    {
      key: 'attendance-notification',
      name: 'Notification',
      name_th: 'การแจ้งเตือน',
      link: `/attendance/notification`,
      validate: /\x2Fattendance\x2Fnotification/gi,
    },
    {
      key: 'attendace-setting',
      name: 'Setting',
      name_th: 'ตั้งค่า',
      link: `/attendance/setting`,
      validate: /\x2Fattendance\x2Fsetting/gi,
    },
  ],
}

/*
{
      key: 'report-payroll',
      name: 'Tvi 50 Report',
      name_th: 'รายงาน (ทวิ 50)',
      link: `/payroll/tvi50-report`,
      validate: /\x2Fpayroll\x2Ftvi50-report/gi,
    },
*/
const payrollMenu = {
  key: 'payroll',
  icon: Payroll,
  name: 'Payroll',
  name_th: 'เงินเดือน',
  submenu: [
    {
      key: 'report-payroll',
      name: 'Report',
      name_th: 'รายงาน',
      link: `/payroll/report`,
      validate: /\x2Fpayroll\x2Freport/gi,
    },
    {
      key: 'welfare-report-payroll',
      name: 'Welfare Report',
      name_th: 'รายงานสวัสดิการ',
      link: `/payroll/wel-report`,
      validate: /\x2Fpayroll\x2Fwel-report/gi,
    },
    {
      key: 'report-payroll',
      name: 'Tvi 50 Report',
      name_th: 'รายงาน (ทวิ 50)',
      link: `/payroll/tvi50`,
      validate: /\x2Fpayroll\x2Ftvi50/gi,
    },
    {
      key: 'import-payroll',
      name: 'Import Payroll',
      name_th: 'นำเข้าข้อมูล',
      link: `/payroll/importer`,
      validate: /\x2Fpayroll\x2Fimport/gi,
    },
    {
      key: 'welfare-payroll',
      name: 'Welfare',
      name_th: 'สวัสดิการ',
      link: `/payroll/welfare`,
      validate: /\x2Fpayroll\x2Fwelfare/gi,
    },
    {
      key: 'paycycle-payroll',
      name: 'Paycycle',
      name_th: 'รอบการจ่าย',
      link: `/payroll/paycycle`,
      validate: /\x2Fpayroll\x2Fpaycycle/gi,
    },
    {
      key: 'setting-payroll',
      name: 'Setting',
      name_th: 'ตั้งค่า',
      link: `/payroll/setting`,
      validate: /\x2Fpayroll\x2Fsetting/gi,
    },
    {
      key: 'payslip-payroll',
      name: 'Payslip Setting',
      name_th: 'ตั้งค่าสลิปเงินเดือน',
      link: `/payroll/payslip-setting`,
      validate: /\x2Fpayroll\x2Fpayslip-setting/gi,
    },
    {
      key: 'Incompleted-payroll',
      name: 'Incompleted Info',
      name_th: 'ข้อมูลไม่สมบูรณ์',
      link: `/payroll/incompleted`,
      validate: /\x2Fpayroll\x2Fincompleted/gi,
    },
  ],
}

const overtimeMenu = {
  key: 'overtime',
  icon: Document,
  name: 'Overtime',
  name_th: 'งานล่วงเวลา',
  submenu: [
    {
      key: 'pending-overtime',
      name: 'Pending Request',
      name_th: 'รายการรอดำเนินการ',
      link: `/overtime/pending`,
      validate: /\x2Fovertime\x2Fpending/gi,
    },
    {
      key: 'report-overtime',
      name: 'OT Report',
      name_th: 'รายงานล่วงเวลา',
      link: `/overtime/report`,
      validate: /\x2Fovertime\x2Freport/gi,
    },
    {
      key: 'category-overtime',
      name: 'Category',
      name_th: 'หมวดหมู่',
      link: `/overtime/category`,
      validate: /\x2Fovertime\x2Fcategory/gi,
    },
    {
      key: 'settting-overtime',
      name: 'Setting',
      name_th: 'ตั้งค่า',
      link: `/overtime/setting`,
      validate: /\x2Fovertime\x2Fsetting/gi,
    },
  ],
}

const feedMenu = {
  key: 'feed',
  icon: Document,
  name: 'Feed',
  name_th: 'ข่าวสาร',
  submenu: [
    {
      key: 'pending-feed',
      name: 'Pending Request',
      name_th: 'รายการรอดำเนินการ',
      link: `/feed/pending`,
      validate: /\x2Fovertime\x2Fpending/gi,
    },
    {
      key: 'report-feed',
      name: 'Timeline',
      name_th: 'ไทม์ไลน์',
      link: `/feed/timeline`,
      validate: /\x2Ffeed\x2Ftimeline/gi,
    },
    {
      key: 'settting-feed',
      name: 'Setting',
      name_th: 'ตั้งค่า',
      link: `/feed/setting`,
      validate: /\x2Ffeed\x2Fsetting/gi,
    },
  ],
}

const timesheetMenu = {
  key: 'timesheet',
  icon: Time,
  name: 'Timesheet',
  name_th: 'ใบบันทึกเวลา',
  submenu: [
    {
      key: 'dashboard-timesheet',
      name: 'Dashboard',
      name_th: 'แดชบอร์ด',
      link: `/timesheet/dashboard`,
      validate: /\x2Ftimesheet\x2Fdashboard/gi,
    },
    /*
    {
      key: 'report-timesheet',
      name: 'Report',
      name_th: 'รายงาน',
      link: `/timesheet/report`,
      validate: /\x2Ftimesheet\x2Freport/gi,
    },
    */
    {
      key: 'cost-center-timesheet',
      name: 'Cost Center',
      name_th: 'ศูนย์ค่าใช้จ่าย',
      link: `/timesheet/cost-center`,
      validate: /\x2Ftimesheet\x2Fcost-center/gi,
    },
    {
      key: 'activity-timesheet',
      name: 'Activity',
      name_th: 'กิจกรรม',
      link: `/timesheet/activity`,
      validate: /\x2Ftimesheet\x2Factivity/gi,
    },
    /*
    {
      key: 'setting-timesheet',
      name: 'Setting',
      name_th: 'ตั้งค่า',
      link: `/timesheet/setting`,
      validate: /\x2Ftimesheet\x2Fsetting/gi,
    },
    */
  ],
}

const adminMenu = {
  key: 'administrator',
  icon: Department,
  name: 'Administrator',
  name_th: 'ผู้ดูแลระบบ',
  submenu: [
    {
      key: 'account-admin',
      name: 'Account',
      name_th: 'บัญชีผู้ดูแล',
      link: `/admin/account`,
      validate: /\x2Fadmin\x2Faccount/gi,
    },
    {
      key: 'role-admin',
      name: 'Role',
      name_th: 'สิทธิ์การเข้าถึง',
      link: `/admin/role`,
      validate: /\x2Fadmin\x2Frole/gi,
    },
  ],
}

const menu_list = [
  {
    key: 'dashboard',
    icon: Home,
    name: 'Dashboard',
    name_th: 'แดชบอร์ด',
    link: '/',
    validate: /\x2F$/gi,
  },
  company,
  employee,
  leaveReq,
  // documentMenu,
  reimburseMenu,
  attendanceMenu,
  overtimeMenu,
  payrollMenu,
  feedMenu,
  timesheetMenu,
  adminMenu,
  {
    key: 'setting',
    icon: Setting,
    name: 'Setting',
    name_th: 'ตั้งค่า',
    submenu: [
      {
        key: 'holiday',
        name: 'Holiday',
        name_th: 'วันหยุด',
        link: `/holiday`,
        validate: /\x2Fholiday/gi,
      },
    ],
  },
]

export default menu_list
