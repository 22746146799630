import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/overtime/config`
let state
export class OvertimeConfig extends BaseStore {
  constructor() {
    super()
    this.observable({
      setting: {
        request_type: 'both',
        request_part_day: 30,
        request_future_day: 30,
        after_workday_rate: 1.5,
        holiday_rate: 1,
        after_holiday_rate: 3,
      },
    })
    state = this
  }

  async getSetting(isForce = true) {
    if (!isForce) {
      const setting = this.toJS().setting
      if (setting.ot_config_id) return
    }

    const url = `${Url}`
    const resp = await http.get(url)

    const data = resp.body || {}

    runInAction(() => {
      state.setting = data
    })
  }

  async updateSetting(json = {}) {
    const url = `${Url}`
    await http.put(url, { json })

    runInAction(() => {
      state.setting = json
    })
  }
}

export default new OvertimeConfig()
