import { inject, observer } from 'mobx-react'
import { Layout, Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { Logo } from 'icon'

import menu_list from './menuApp'

const { Sider } = Layout

const SideMenu = (props) => {
  const { lang } = props

  const toggle = () => {
    props.menu.toggleMenu()
  }

  const location = useLocation()
  const pathname = location.pathname

  const { openning } = props.menu.toJS()
  const css = openning ? 'anticon openning' : 'anticon'

  const content = menu_list.map((item, index) => {
    const Icon = item.icon
    const submenu = item.submenu || []
    if (submenu.length > 0) {
      let isSelected = false
      const submenu_list = submenu.map((it, i) => {
        const isAcitve = pathname.match(it.validate) ? true : false
        isSelected = isSelected || isAcitve
        const active = isAcitve ? 'active' : ''
        return (
          <Menu.Item key={`${index}-${i}`} className={active}>
            <Link to={it.link}>
              <Text className={active}>
                {lang.getMessage(it.name, it.name_th)}
              </Text>
            </Link>
          </Menu.Item>
        )
      })

      const isMenuActive = isSelected && !openning
      const title = (
        <MenuItem>
          <MenuIcon className={css}>
            <Icon color={isMenuActive ? '#F36273' : 'white'} />
          </MenuIcon>
          <Text className={isMenuActive ? 'active' : ''}>
            {lang.getMessage(item.name, item.name_th)}
          </Text>
        </MenuItem>
      )
      return (
        <Menu.SubMenu
          key={index}
          className={isMenuActive ? 'active' : ''}
          title={title}
        >
          {submenu_list}
        </Menu.SubMenu>
      )
    } else {
      const isSelected = pathname.match(item.validate) ? true : false
      const active = isSelected ? 'active' : ''

      return (
        <Menu.Item key={index} className={active}>
          <Link to={item.link}>
            <MenuItem>
              <MenuIcon className={`${css} ${active}`}>
                <Icon color={isSelected ? '#F36273' : 'white'} />
              </MenuIcon>
              <Text className={active}>
                {lang.getMessage(item.name, item.name_th)}
              </Text>
            </MenuItem>
          </Link>
        </Menu.Item>
      )
    }
  })

  return (
    <OuterMenu>
      <Sider
        className="sider"
        trigger={null}
        width={200}
        collapsible
        collapsed={!openning}
      >
        <Title key="header" onClick={toggle}>
          <Logo size={openning ? 0.8 : 0.4} color={'white'} />
        </Title>
        <ContentMenu>
          <Menu theme="light" mode="inline">
            {content}
          </Menu>
        </ContentMenu>
      </Sider>
    </OuterMenu>
  )
}

const OuterMenu = styled.div`
  background-color: ${(p) => p.theme.color.red};
  border-radius: 20px;
  padding: 25px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  .ant-menu-item {
    color: white;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item a {
    color: white;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title,
  i {
    color: white !important;
  }

  .ant-menu-inline-collapsed .active,
  .ant-menu-inline.ant-menu-root .active {
    background-color: white;
  }

  .ant-layout-sider {
    background: transparent;
  }

  .ant-menu-inline .ant-menu-item-selected::after {
    border-right: 0px solid ${(p) => p.theme.color.red};
  }

  .ant-menu {
    border: 0px;
    color: white;
    background-color: ${(p) => p.theme.color.red};
    border-radius: 20px;
  }

  .anticon svg {
    display: block;
  }

  @media print {
    display: none !important;
  }
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  cursor: pointer;
`

const ContentMenu = styled.div`
  padding-top: 30px;
`

const MenuItem = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const MenuIcon = styled.i`
  position: relative;
  left: -5px;
  width: 30px;

  &.openning {
    left: 0px;
  }

  &.active {
    color: ${(p) => p.theme.color.red};
  }
`

const Text = styled.span`
  &.active {
    color: ${(p) => p.theme.color.red};
  }
`

export default inject('menu', 'lang')(observer(SideMenu))
