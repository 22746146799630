import { initMenu } from 'utils/helper'

// Document Type List
const DocumentTypeList = [
  { name: 'National ID', name_th: 'หมายเลขบัตรประชาชน', value: 'national_id' },
  { name: 'House Registration', name_th: 'ทะเบียนบ้าน', value: 'house' },
  { name: 'Passport', name_th: 'หนังสือเดินทาง', value: 'passport' },
  { name: 'Book Bank', name_th: 'บัญชีธนาคาร', value: 'bank' },
  { name: 'Transcript', name_th: 'รายงานผลการศึกษา', value: 'transcript' },
  { name: 'Resume', name_th: 'ประวัติการทำงาน', value: 'resume' },
  { name: 'Other', name_th: 'อื่นๆ', value: 'other' },
]

const docType = initMenu(DocumentTypeList)

export const DocumentType = docType.keys
export const DocumentTypeMenu = {
  eng: docType.menu,
  thai: docType.menu_th,
}

export const getDocumentType = (value) => {
  const item = DocumentType[value] || DocumentType.other
  return item
}

// Title Name
const TitleNameList = [
  { name: 'Mr', name_th: 'นาย', value: 'mr' },
  { name: 'Mrs', name_th: 'นาง', value: 'mrs' },
  { name: 'Miss', name_th: 'นางสาว', value: 'miss' },
  { name: 'Ms', name_th: 'นางสาว', value: 'ms' },
  { name: 'Other', name_th: 'อื่นๆ', value: 'other' },
]

const docTitleName = initMenu(TitleNameList)

export const TitleName = docTitleName.keys
export const TitleNameMenu = {
  eng: docTitleName.menu,
  thai: docTitleName.menu_th,
}

export const geTitleName = (value) => {
  const item = TitleName[value] || {}
  return item
}

// Employee Type
const EmployeeTypeList = [
  { name: 'Full Time', name_th: 'ประจำ', value: 'fulltime' },
  { name: 'Part Time', name_th: 'พาร์ทไทม์', value: 'parttime' },
  { name: 'Probation', name_th: 'ทดลองงาน', value: 'probation' },
  { name: 'Contract', name_th: 'สัญญาจ้าง', value: 'contract' },
  { name: 'Outsource', name_th: 'เอาท์ซอร์ส', value: 'outsource' },
]

const employeeType = initMenu(EmployeeTypeList)

export const EmployeeType = employeeType.keys
export const EmployeeTypeMenu = {
  eng: employeeType.menu,
  thai: employeeType.menu_th,
}

export const getEmployeeType = (value) => {
  const item = EmployeeType[value] || {}
  return item
}

// Calculation Mode
const CalcModeList = [
  { name: 'Inactive', name_th: 'ปิดใช้งาน', value: 'no' },
  { name: 'Calculation', name_th: 'คำนวน', value: 'yes' },
  { name: 'Percent', name_th: 'คิดเป็น %', value: 'percent' },
  { name: 'Fixed', name_th: 'กำหนดค่า', value: 'fixed' },
]

const calcMode = initMenu(CalcModeList)

export const CalcMode = calcMode.keys
export const CalcModeMenu = {
  eng: calcMode.menu,
  thai: calcMode.menu_th,
}

export const getCalcMode = (value) => {
  const item = CalcMode[value] || CalcMode.yes
  return item
}
