import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { config } from 'config'
import { http, timer } from 'utils'

import BaseStore from './BaseStore'

const original = {
  company_id: '',
  holiday_year: 2019,
  data: {
    saturday: true,
    sunday: true,
    january: [],
    february: [],
    march: [],
    april: [],
    may: [],
    june: [],
    july: [],
    august: [],
    september: [],
    october: [],
    november: [],
    december: [],
  },
}

let state
export class Holiday extends BaseStore {
  constructor() {
    super()
    this.observable({
      month: timer.get().format('MMMM').toLowerCase(),
      detail: cloneDeep(original),
      list: [],
      holiday_list: [],
    })

    state = this
  }

  async getYearHoliday(year) {
    const url = `${config.api}/v1/user-admin/account/holiday/${year}`
    const res = await http.get(url)

    return res.body
  }

  async getDocByYear(year) {
    const doc = this.toJS().detail
    if (doc.holiday_year === year) return
    this.reset()

    const newDoc = await this.getYearHoliday(year)
    runInAction(() => {
      state.detail = newDoc
    })
  }

  reset() {
    runInAction(() => {
      state.detail = cloneDeep(original)
    })
  }

  async saveDoc() {
    const doc = this.toJS().detail

    const url = `${config.api}/v1/user-admin/account/holiday/${doc.holiday_id}`
    const res = await http.put(url, { json: doc })

    const newDoc = res.body

    runInAction(() => {
      state.detail = newDoc
      state.list = []
    })
  }

  setDetail(doc) {
    runInAction(() => {
      state.detail = doc
    })
  }

  selectMonth(val) {
    runInAction(() => {
      state.month = val
    })
  }

  async getHolidayList({ force = false } = {}) {
    const list = this.toJS().holiday_list
    if (force === false && list.length > 0) {
      return list
    }

    const now = timer.get()
    const current = now.year()

    const otherYear = now.month() > 5 ? current + 1 : current - 1

    const [resA, resB] = await Promise.all([
      this.getYearHoliday(current),
      this.getYearHoliday(otherYear),
    ])

    const newlist = [resA, resB]

    runInAction(() => {
      state.holiday_list = newlist
    })

    return newlist
  }
}

export default new Holiday()
