/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#1F1F1F' } = props
  const width = size * 25
  const height = size * 24
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.05103" y="5.48584" width="22.5607" height="17.5142" rx="2.64883" stroke={color} strokeWidth="0.993311"/>
      <path d="M7.87842 3.64883C7.87842 2.18592 9.06434 1 10.5272 1H14.333C15.7959 1 16.9819 2.18592 16.9819 3.64883V5.55172H7.87842V3.64883Z" stroke={color} strokeWidth="0.993311"/>
      <path d="M9.21427 8.89933C8.62057 8.30576 7.87844 8.00865 7.13631 8.60235C6.54281 9.07719 6.5427 9.93876 7.13646 10.5322C7.49279 10.8883 8.32384 11.8677 8.91755 12.4614L7.28487 18.25L8.47227 19.4374L11.1439 14.6878L13.8156 17.0626L13.2219 19.1405L14.4093 19.8827L15.7451 17.5078L18.2684 15.8752L17.5262 15.133L15.4483 15.4299L12.925 12.9067L17.8231 10.235L16.4872 9.04759L10.6987 10.5318C10.6987 10.5318 9.57053 9.25552 9.21427 8.89933Z" stroke={color} strokeWidth="0.993311" strokeLinejoin="round"/>
    </svg>
  )
}

/* eslint-enable */
export default Logo
