import React from 'react'
/* eslint-disable */

const Logo = (props) => {
  const { size = 1 } = props

  const width = `${Math.ceil(18 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.125 24H10.875C11.3928 24 11.8125 23.5803 11.8125 23.0625C11.8125 22.5447 11.3928 22.125 10.875 22.125H7.125C6.60722 22.125 6.1875 22.5447 6.1875 23.0625C6.1875 23.5803 6.60722 24 7.125 24Z"
        fill="white"
      />
      <path
        d="M9 0C8.48222 0 8.0625 0.419719 8.0625 0.9375V1.94227C4.88648 2.39855 2.4375 5.13722 2.4375 8.4375V14.2011C2.4375 14.5314 2.28867 14.832 2.06723 14.949C1.13906 15.4391 0.5625 16.3926 0.5625 17.4375C0.5625 18.9883 1.82419 20.25 3.375 20.25H14.625C16.1758 20.25 17.4375 18.9883 17.4375 17.4375C17.4375 16.3926 16.8609 15.4391 15.9328 14.949C15.7113 14.832 15.5625 14.5315 15.5625 14.2011V8.4375C15.5625 5.13722 13.1135 2.39855 9.9375 1.94227V0.9375C9.9375 0.419719 9.51778 0 9 0V0ZM13.6875 8.4375V14.2011C13.6875 15.239 14.2124 16.1609 15.0573 16.607C15.3689 16.7715 15.5625 17.0898 15.5625 17.4375C15.5625 17.9544 15.1419 18.375 14.625 18.375H3.375C2.85806 18.375 2.4375 17.9544 2.4375 17.4375C2.4375 17.0898 2.63109 16.7715 2.94272 16.607C3.78764 16.1609 4.3125 15.239 4.3125 14.2011V8.4375C4.3125 5.85281 6.41531 3.75 9 3.75C11.5847 3.75 13.6875 5.85281 13.6875 8.4375Z"
        fill="white"
      />
    </svg>
  )
}
/* eslint-enable */
export default Logo
