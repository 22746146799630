import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

const Message = (props) => {
  const { message } = props.message.toJS()

  const css = message ? message.type : 'none'
  const text = message ? message.message : ''
  return <PageView className={css}>{text}</PageView>
}

const PageView = styled.div`
  font-size: 16px;
  text-align: center;
  color: white;
  height: 40px;
  width: 100%;
  position: fixed;
  top: 0px;
  padding-top: 6px;
  z-index: 2000;

  &.none {
    display: none;
  }

  &.success {
    background-color: ${(p) => p.theme.color.green};
  }

  &.error {
    background-color: ${(p) => p.theme.color.error};
  }
`

export default inject('message')(observer(Message))
