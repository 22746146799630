import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'
import * as XLSX from 'xlsx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const original = {
  user_id: undefined,
  company_id: undefined,
  status: 'draft',
  leave_type_id: undefined,
  leave_name: '',
  leave_category: undefined,
  leave_type: 'one',
  action_by: undefined,
  action_at: undefined,
  created_at: undefined,
  updated_at: undefined,
  notify: 0,
}

const Url = `${config.api}/v1/user-admin/reimbursement/request`
let state
export class Reimburse extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
      doc: cloneDeep(original),
      leave_type: undefined,
      available_day: undefined,
    })
    state = this
  }

  toQuery(query = {}) {
    const {
      index = 1,
      per_page = 20,
      user_id,
      status,
      cost_center_id,
      category_id,
      start_date,
      end_date,
    } = query

    let q = `index=${index}&per_page=${per_page}`

    if (status) q += `&status=${status}`
    if (user_id) q += `&user_id=${user_id}`
    if (cost_center_id) q += `&cost_center_id=${cost_center_id}`
    if (category_id) q += `&reimburse_category_id=${category_id}`
    if (start_date) q += `&start_date=${start_date.toISOString()}`
    if (end_date) q += `&end_date=${end_date.toISOString()}`

    return q
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getReportList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${config.api}/v1/user-admin/reimbursement/request/report?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCountList(query) {
    const q = this.toQuery(query)
    const url = `${Url}/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async getExportList(query) {
    const q = this.toQuery(query)
    const url = `${Url}/report?no_limit=yes&${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const data_list = list.map((it) => {
      delete it.createdAt
      delete it.updatedAt
      delete it.holiday
      delete it.start_unix
      delete it.end_unix

      return it
    })

    const today = timer.get()
    const date = today.format('DDMMYY-HHmm')

    const ws = XLSX.utils.json_to_sheet(data_list)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, ws, 'reimburse-request')

    XLSX.writeFile(workbook, `reimburse-${date}.xlsx`)

    return list
  }

  updateInList(id, doc) {
    const { list = [] } = this.toJS()
    const i = list.findIndex((it) => it.reimburse_request_id === id)
    if (i !== -1) {
      list[i].status = doc.status
      runInAction(() => {
        state.list = list
      })
    }
  }

  async rejectReimburseReq({ reimburse_request_id, reason = '' }) {
    reason = reason.trim()

    const url = `${Url}/${reimburse_request_id}/reject`
    const res = await http.put(url, { json: { reason } })

    const newDoc = res.body
    this.updateInList(reimburse_request_id, newDoc)
  }

  async approveReimburseReq({ reimburse_request_id }) {
    const url = `${Url}/${reimburse_request_id}/approve`
    const res = await http.put(url)

    const newDoc = res.body
    this.updateInList(reimburse_request_id, newDoc)
  }

  reset() {
    runInAction(() => {
      state.doc = cloneDeep(original)
    })
  }

  setDoc(doc) {
    runInAction(() => {
      state.doc = doc
    })
  }
}

export default new Reimburse()
