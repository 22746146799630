import { runInAction } from 'mobx'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/payroll`
let state
export class Report extends BaseStore {
  constructor() {
    super()
    this.observable({
      report: {
        list: [],
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
      },
    })
    state = this
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20, date } = query
    let q = `index=${index}&per_page=${per_page}`

    if (date) {
      const month = date.clone().startOf('month').toISOString()
      q += `&month=${month}`
    }

    return q
  }

  async getReportList(query = {}) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}/report?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { total } = this.toJS().report.page
    runInAction(() => {
      state.report = {
        list,
        page: {
          index,
          per_page,
          total,
        },
      }
    })
  }

  async getReportCounter(query = {}) {
    const q = this.toQuery(query)
    const url = `${Url}/report/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.report.page.total = counter || 0
    })
  }

  async getPayroll({ payroll_id } = {}) {
    const url = `${Url}/report/${payroll_id}`
    const resp = await http.get(url)

    const payroll = resp.body || {}

    runInAction(() => {
      state.payroll = payroll
    })
  }

  async downloadSocialSecurity({ payroll_id } = {}) {
    const url = `${Url}/report/${payroll_id}/social-security`
    const res = await http.get(url, {})

    const { data_list } = res.body

    const today = timer.get()
    const date = today.format('DDMMYY-HHmm')

    const ws = XLSX.utils.aoa_to_sheet(data_list)
    const w = 15
    const width = 25
    ws['!cols'] = [
      { width },
      { width: w },
      { width },
      { width },
      { width: w },
      { width: w },
    ]
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, ws, '000000')

    XLSX.writeFile(workbook, `social-security-${date}.xlsx`)
  }

  async downloadBankTransfer({ payroll_id } = {}) {
    const url = `${Url}/report/${payroll_id}/bank-transfer`
    const res = await http.get(url, {})

    const { data_list, file_type } = res.body
    const today = timer.get()
    const date = today.format('DDMMYY-HHmm')
    if (file_type === 'xlsx') {
      const ws = XLSX.utils.aoa_to_sheet(data_list)
      const w = 15
      const width = 25
      ws['!cols'] = [
        { width },
        { width: w },
        { width },
        { width },
        { width: w },
        { width: w },
      ]
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, ws, '000000')

      XLSX.writeFile(workbook, `bank-transfer-${date}.xlsx`)
    } else {
      const list = data_list.map((txt) => {
        return txt + '\r\n'
      })
      const blob = new Blob(list, {
        type: 'text/plain;charset=utf-8',
      })
      FileSaver.saveAs(blob, `bank-transfer-${date}.${file_type}`)
    }
  }

  async downloadWHT({ payroll_id } = {}) {
    const url = `${Url}/report/${payroll_id}/tax`
    const res = await http.get(url, {})

    const { data_list } = res.body

    const today = timer.get()
    const date = today.format('DDMMYY-HHmm')

    const list = data_list.map((txt) => {
      return txt + '\r\n'
    })
    const blob = new Blob(list, {
      type: 'text/plain;charset=utf-8',
    })
    FileSaver.saveAs(blob, `wht-${date}.csv`)
  }
}

export default new Report()
