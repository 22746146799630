import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const reimburse_url = `${config.api}/v1/user-admin/reimbursement/category`

let state
export class ReimburseCategory extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: {
        cost_center_id: undefined,
        category_list: [],
      },
    })
    state = this
  }

  reset() {
    runInAction(() => {
      state.doc = {
        cost_center_id: undefined,
        category_list: [],
      }
    })
  }

  async getCategoryList(cost_center_id) {
    const { doc = {} } = this.toJS()
    if (doc.cost_center_id === cost_center_id) {
      return
    }
    const url = `${reimburse_url}/${cost_center_id}`
    const res = await http.get(url)

    const list = res.body || []
    runInAction(() => {
      state.doc = {
        cost_center_id,
        category_list: list,
      }
    })
  }

  async updateDoc(json = {}) {
    const { reimburse_category_id } = json
    const { cost_center_id, category_list } = this.toJS().doc

    let res
    if (reimburse_category_id) {
      const url = `${reimburse_url}/${reimburse_category_id}/detail`
      delete json.reimburse_category_id
      res = await http.put(url, { json })
    } else {
      const url = `${reimburse_url}/${cost_center_id}`
      res = await http.post(url, { json })
    }

    const doc = res.body
    if (reimburse_category_id) {
      const i = category_list.findIndex(
        (it) => it.reimburse_category_id === reimburse_category_id
      )
      category_list[i] = doc
    } else {
      category_list.unshift(doc)
    }

    runInAction(() => {
      state.doc.category_list = category_list
    })
  }

  async deleteDoc(reimburse_category_id) {
    const url = `${reimburse_url}/${reimburse_category_id}/delete`
    await http.put(url, {})

    const { category_list } = this.toJS().doc
    const i = category_list.findIndex(
      (it) => it.reimburse_category_id === reimburse_category_id
    )
    if (i !== -1) {
      category_list.splice(i, 1)

      runInAction(() => {
        state.doc.category_list = category_list
      })
    }
  }
}

export default new ReimburseCategory()
