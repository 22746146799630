import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/payroll`
let state
export class Payroll extends BaseStore {
  constructor() {
    super()
    this.observable({
      payroll: {},
    })
    state = this
  }

  async getPayroll({ payroll_id } = {}) {
    const url = `${Url}/report/${payroll_id}/detail`
    const resp = await http.get(url)

    const payroll = resp.body || {}

    runInAction(() => {
      state.payroll = payroll
    })
  }

  async createPayroll({ date } = {}) {
    const month = date.clone().startOf('month').toISOString()
    const url = `${Url}/request/monthly/${month}`
    const resp = await http.post(url)

    const payroll = resp.body || {}

    return payroll
  }

  async updatePayrollStatus(data = {}) {
    const {
      payroll_id,
      status,
      start_cutoff_at,
      end_cutoff_at,
      start_paycycle_at,
      end_paycycle_at,
      paid_at,
    } = data

    const json = {
      status,
      start_cutoff_at,
      end_cutoff_at,
      start_paycycle_at,
      end_paycycle_at,
      paid_at,
    }
    const url = `${Url}/request/${payroll_id}/status`
    const res = await http.put(url, { json })

    const payroll = res.body || {}

    runInAction(() => {
      state.payroll = payroll
    })
  }

  setPayroll(data) {
    runInAction(() => {
      state.payroll = data
    })
  }

  async processSummaryPayroll({ payroll_id } = {}) {
    const url = `${Url}/request/${payroll_id}/summary`
    const res = await http.put(url, {})

    const payroll = res.body || {}

    runInAction(() => {
      state.payroll = payroll
    })
  }
}

export default new Payroll()
