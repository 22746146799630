import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/document/eslip`
let state
export class DocumentEslip extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
    })
    state = this
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20, user_id, date, status } = query

    let q = `index=${index}&per_page=${per_page}`
    if (status) q += `&status=${status}`
    if (user_id) q += `&user_id=${user_id}`
    if (date) q += `&release_at=${date.toISOString()}`

    return q
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCountList(query) {
    const q = this.toQuery(query)
    const url = `${Url}/counter?${q}`
    const res = await http.get(url)

    const { counter } = res.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async createEslip({ user_id }, json = {}) {
    const url = `${config.api}/v1/user-admin/document/request/${user_id}/e_slip`
    const res = await http.post(url, { json })

    const doc = res.body

    const list = this.toJS().list
    const i = list.findIndex((it) => it.user_id === user_id)
    if (i > -1) {
      list[i].document_request = doc
      runInAction(() => {
        state.list = list
      })
    }
  }

  async deleteEslip({ user_id, document_request_id }) {
    const url = `${config.api}/v1/user-admin/document/request/${document_request_id}/delete`
    await http.put(url, {})

    const list = this.toJS().list
    const i = list.findIndex((it) => it.user_id === user_id)
    if (i > -1) {
      list[i].document_request = undefined
      runInAction(() => {
        state.list = list
      })
    }
  }
}

export default new DocumentEslip()
