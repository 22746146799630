import { runInAction } from 'mobx'
import * as XLSX from 'xlsx'

import { http, format, timer } from 'utils'
import { config } from 'config'
import { attendance } from 'constant'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/timesheet`

const getColumnSize = (size) => {
  const sizeList = []
  for (let i = 0; i < size; i++) {
    const width = 18
    sizeList.push({ width })
  }

  return sizeList
}

let state
export class Timesheet extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
    })
    state = this
  }

  toQuery(query = {}) {
    const {
      index = 1,
      per_page = 20,
      user_id,
      status,
      ts_category_id,
      ts_activity_id,
      start_date,
      end_date,
    } = query

    let q = `index=${index}&per_page=${per_page}`

    if (status) q += `&status=${status}`
    if (user_id) q += `&user_id=${user_id}`
    if (ts_category_id) q += `&ts_category_id=${ts_category_id}`
    if (ts_activity_id) q += `&ts_activity_id=${ts_activity_id}`
    if (start_date) q += `&start_date=${start_date.toISOString()}`
    if (end_date) q += `&end_date=${end_date.toISOString()}`

    return q
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}/report?${q}`
    const res = await http.get(url)

    const list = res.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCounter(query) {
    const q = this.toQuery(query)
    const url = `${Url}/report/counter?${q}`
    const res = await http.get(url)

    const { counter } = res.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async export(query) {
    const q = this.toQuery({ ...query, per_page: -1 })
    const url = `${Url}/report?${q}`
    const res = await http.get(url)

    const reqList = res.body || []
    const list = reqList.map((it) => {
      const employee = `${it.name || ''} ${it.surname || ''}`
      const nickname = it.nickname || ''
      const { status, category_name, overtime_minute, amount, note, reason } =
        it

      const start_at = format.toExportDate(it.start_at)
      const end_at = format.toExportDate(it.finish_at)
      const manHour = attendance.toWorkTime(overtime_minute)
      return [
        employee,
        nickname,
        status,
        category_name,
        start_at,
        end_at,
        manHour,
        amount,
        note,
        reason,
      ]
    })

    const today = timer.get()
    const date = today.format('DDMMYY-HHmm')

    const headers = [
      'employee',
      'nickname',

      'status',
      'category name',
      'start work',
      'end work',

      'man hour',
      'amount',
      'note',
      'reason',
    ]

    list.unshift(headers)

    const ws = XLSX.utils.aoa_to_sheet(list)
    ws['!cols'] = getColumnSize(headers.length)

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, ws, 'overtime')

    XLSX.writeFile(workbook, `timesheet-${date}.xlsx`)

    return list
  }
}

export default new Timesheet()
