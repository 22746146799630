import { runInAction } from 'mobx'

import { timer } from 'utils'

import BaseStore from './BaseStore'

let state

export class Message extends BaseStore {
  constructor() {
    super()
    this.observable({
      message: null,
    })

    state = this
  }

  error({ title, message }) {
    const data = {
      type: 'error',
      title,
      message,
      created_at: timer.get(),
    }

    this.createMessage(data)
  }

  success({ title, message }) {
    const data = {
      type: 'success',
      title,
      message,
      created_at: timer.get(),
    }

    this.createMessage(data)
  }

  createMessage(data) {
    setTimeout(() => {
      state.clearMessage()
    }, 4000)

    runInAction(() => {
      state.message = data
    })
  }

  clearMessage() {
    const { message } = this.toJS()
    const now = timer.get()
    if (message) {
      const val = now.diff(message.created_at, 'seconds')
      if (val > 5) {
        runInAction(() => {
          state.message = null
        })
      } else {
        setTimeout(() => {
          state.clearMessage()
        }, 4000)
      }
    }
  }
}

export default new Message()
