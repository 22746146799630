import { initMenu } from 'utils/helper'

import * as leaveReq from './leaveReq'

// Option Type List
const OptionTypeList = [
  { name: 'No', name_th: 'ไม่ใช้งาน', value: 'unused' },
  { name: 'Yes', name_th: 'กำหนดเอง', value: 'manual' },
]
const optionType = initMenu(OptionTypeList)

export const OptionType = optionType.keys
export const OptionTypeMenu = {
  eng: optionType.menu,
  thai: optionType.menu_th,
}

export const getOptionType = (value) => {
  const item = OptionType[value] || OptionType.unused
  return item
}

export const isCounterBalance = (leave_config, leave_category, leave_type) => {
  if (!leave_category) {
    return false
  }

  const category = leave_config.leave_type[leave_category]

  const isCategoryCounter = category ? category.type !== 'unused' : false
  const isLeaveCounter = leave_type
    ? leave_type.balance_type !== 'unused'
    : false

  return isCategoryCounter || isLeaveCounter
}

export const getAvailable = (item) => {
  if (!item) {
    return undefined
  }

  const total = leaveReq.getTotalBalance(item.leave_type, item.leave_balance)
  const leave_balance = item.leave_balance || {}
  const used_days = leave_balance.balance_day || 0
  return total - used_days
}

export const getAvailableBalance = (
  balance_list = [],
  leave_req = {},
  leave_type = { balance_type: 'unused' }
) => {
  const { leave_category } = leave_req
  const { balance_type, leave_type_id } = leave_type
  const balance_category = balance_list.find((it) => {
    const item_type = it.leave_type
    return (
      item_type.type === 'category' && item_type.category === leave_category
    )
  })

  let balance_day = getAvailable(balance_category)
  if (balance_type !== 'unused') {
    const balance_leave = balance_list.find((it) => {
      const item_type = it.leave_type
      return (
        item_type.type === 'leave_type' &&
        item_type.leave_type_id === leave_type_id
      )
    })
    balance_day = getAvailable(balance_leave)
  }

  return balance_day
}

export const isSameBalance = ({
  leave_config = { leave_type: {} },
  leave_req = {},
  leave_type,
} = {}) => {
  const { leave_category } = leave_req
  const category = leave_config.leave_type[leave_category] || {
    type: 'unused',
  }

  const balance_type = leave_type ? leave_type.balance_type : category.type

  const format = balance_type === 'per_year' ? 'YY' : 'MMYY'
  const startTxt = leave_req.start_date.format(format)
  const endTxt = leave_req.end_date.format(format)

  const isSame = startTxt === endTxt
  return { isSame, balance_type }
}
