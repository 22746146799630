import { initMenu } from 'utils/helper'
import { format } from 'utils'
import { color } from 'utils/color'

// Status List
const Processing = {
  name: 'Processing',
  name_th: 'รอดำเนินการ',
  color: color.dark_grey,
  bg: color.yellow,
  value: 'processing',
}

const Requesting = {
  name: 'Requesting',
  name_th: 'รออนุมัติ',
  color: color.dark_grey,
  bg: color.yellow,
  value: 'requesting',
}

const Reject = {
  name: 'Reject',
  name_th: 'ไม่อนุมัติ',
  color: 'white',
  bg: color.red,
  value: 'completed',
}

const Approved = {
  name: 'Approved',
  name_th: 'อนุมัติ',
  color: color.dark_grey,
  bg: color.light_green,
  value: 'approved',
}

const StatusList = [Processing, Requesting, Reject, Approved]

// Status List
const status = initMenu(StatusList)

export const Status = status.keys
export const StatusMenu = {
  eng: status.menu,
  thai: status.menu_th,
}

// Paycycle Type
const Monthly = {
  name: 'Monthly',
  name_th: 'รายเดือน',
  color: 'white',
  bg: '#F36273',
  value: 'monthly',
}

const BiMonthly = {
  name: 'BiMonthly',
  name_th: 'รายครึ่งเดือน',
  color: 'white',
  bg: '#F36273',
  value: 'bimonthly',
}
const PaycycleTypeList = [Monthly, BiMonthly]
const paycycleType = initMenu(PaycycleTypeList)

export const PaycycleType = paycycleType.keys
export const PaycycleTypeMenu = {
  eng: paycycleType.menu,
  thai: paycycleType.menu_th,
}

// Date Type
const Date = {
  name: 'Date',
  name_th: 'วันที่',
  color: 'white',
  bg: '#F36273',
  value: 'date',
}

const DayCounter = {
  name: 'Day Counter',
  name_th: 'นับวัน',
  color: 'white',
  bg: '#F36273',
  value: 'day_counter',
}
const DateTypeList = [Date, DayCounter]
const dateType = initMenu(DateTypeList)

export const DateType = dateType.keys
export const DateTypeMenu = {
  eng: dateType.menu,
  thai: dateType.menu_th,
}

// Deduction Type List
const DeductionTypeList = [
  {
    name: 'Minute',
    name_th: 'นาที',
    value: 'minute',
  },
  {
    name: 'Hour',
    name_th: 'ชั่วโมง',
    value: 'hour',
  },
  {
    name: 'Half day',
    name_th: 'ครึ่งวัน',
    value: 'half_day',
  },
  {
    name: 'Day',
    name_th: 'รายวัน',
    value: 'day',
  },
]
const deductionType = initMenu(DeductionTypeList)

export const DeductionType = deductionType.keys
export const DeductionTypeMenu = {
  eng: deductionType.menu,
  thai: deductionType.menu_th,
}

// Calculation Type List
const CalculationTypeList = [
  {
    name: 'Inactive',
    name_th: 'ปิดการใช้งาน',
    value: 'none',
  },
  {
    name: 'Everyday',
    name_th: 'คำนวนทุกวัน',
    value: 'everyday',
  },
  {
    name: 'Cutoff day',
    name_th: 'คำนวนวันตัดรอบ',
    value: 'cuteoff_day',
  },
]
const calculationType = initMenu(CalculationTypeList)

export const CalculationType = calculationType.keys
export const CalculationTypeMenu = {
  eng: calculationType.menu,
  thai: calculationType.menu_th,
}

// function
export const getStatus = (value) => {
  const item = Status[value] || Status.requesting
  return item
}

export const getPaycycleType = (value) => {
  const item = PaycycleType[value] || PaycycleType.monthly
  return item
}

export const getDateType = (value) => {
  const item = DateType[value] || DateType.date
  return item
}

export const getDeductionType = (value) => {
  const item = DeductionType[value] || DeductionType.half_day
  return item
}

export const getCalculationType = (value) => {
  const item = CalculationType[value] || CalculationType.none
  return item
}

// calc function

export const toPaycycle = (payroll = {}) => {
  const { paycycle_type, paycycle, start_paycycle_at, end_paycycle_at } =
    payroll
  const result =
    paycycle_type === 'monthly'
      ? format.toMonth(paycycle, { format: 'MMYY' })
      : `${format.toDate(start_paycycle_at)}-${format.toDate(end_paycycle_at)}`

  return result
}

const getTotal = (list = []) => {
  let total = 0
  for (const item of list) {
    total += +item.amount || 0
  }

  return total
}

const getExpenseTotal = (list = []) => {
  let total = 0
  let total_no_tax = 0
  for (const item of list) {
    const { is_income } = item
    const amount = +item.amount || 0
    if (is_income === 'no') total_no_tax += amount
    else total += item.amount
  }

  return { total, total_no_tax }
}

const getPercent = (total, percent) => {
  const val = total * (percent / 100)

  return Math.round(val * 100) / 100
}

export const calcPayrollUser = (data = {}, setting = {}) => {
  const commission_tax = setting.commission_tax || 0

  const salary = +data.salary || 0
  const bonus = +data.bonus || 0
  const overtime = +data.overtime || 0
  const commission = +data.commission || 0
  const severance_pay = +data.severance_pay || 0

  const commission_wht =
    commission <= 0 || commission_tax === 0
      ? 0
      : getPercent(commission, commission_tax)

  const expense = getExpenseTotal(data.expense_list)
  data.expense = expense.total
  data.expense_no_tax = expense.total_no_tax

  data.salary = salary
  data.bonus = bonus
  data.overtime = overtime
  data.commission = commission
  data.severance_pay = severance_pay

  data.allowance = getTotal(data.allowance_list)
  data.other_income = getTotal(data.other_income_list)

  const wht = +data.wht || 0
  const ssf = +data.ssf || 0
  const company_ssf = +data.company_ssf || 0
  const provident_fund = +data.provident_fund || 0
  const company_provident_fund = +data.company_provident_fund || 0
  const unpaid_leave = +data.unpaid_leave || 0
  const student_loan_fund = +data.student_loan_fund || 0

  data.wht = wht
  data.ssf = ssf
  data.company_ssf = company_ssf
  data.commission_wht = commission_wht
  data.provident_fund = provident_fund
  data.company_provident_fund = company_provident_fund
  data.unpaid_leave = unpaid_leave
  data.student_loan_fund = student_loan_fund

  data.other_deduction = getTotal(data.other_deduction_list)

  data.total_income_tax =
    salary +
    bonus +
    overtime +
    data.expense +
    data.allowance +
    data.other_income

  // const commission_net = commission - commission_wht
  data.total_income =
    data.total_income_tax + data.expense_no_tax + severance_pay + commission

  data.total_deduction =
    wht +
    ssf +
    provident_fund +
    student_loan_fund +
    unpaid_leave +
    commission_wht +
    data.other_deduction

  data.total = data.total_income - data.total_deduction

  return data
}

const calc = (amount = 0, value = 0) => {
  if (amount === 0 || value === 0) return 0

  const val = amount * (value / 100)

  const result = Math.ceil(val * 100) / 100
  return result
}

const toCeil = (val) => {
  const amount = val * 100
  return Math.round(amount) / 100
}

const calcSocialFund = (ssf_mode, income = 0, value = 0, maxIncome = 15000) => {
  if (ssf_mode === 'no' || income === 0 || value === 0) return 0

  const amount = income > maxIncome ? maxIncome : income
  const val = amount * (value / 100)

  return Math.round(val)
}

const calcWHT = (
  data = {},
  deduction = 0,
  general_deduction = 0,
  deduction_year = 0,
  tax_list = []
) => {
  const { wht_mode } = data
  const salary = data.salary || 0
  const allowance = data.allowance || 0
  const overtime = data.overtime || 0
  const bonus = data.bonus || 0
  const wht_value = data.wht_value || 0

  const ssf = data.ssf || 0

  const monthly = data.monthly || 12
  const accumulated = data.accumulated || 0

  const income = salary + allowance

  const income_month = +income - +deduction - ssf
  const income_year = income_month * monthly
  const total_income = income_year + bonus + overtime + accumulated
  const tmp = total_income - +deduction_year - +general_deduction
  const amount = tmp < 0 ? 0 : tmp

  if (wht_mode === 'no' || amount === 0 || tax_list.length === 0) return 0
  if (wht_mode === 'percent') {
    const total = salary + bonus + allowance + overtime
    const tax = total * (wht_value / 100)

    return toCeil(tax)
  }

  let index = tax_list.findIndex((it) => {
    return amount >= it.income
  })

  if (index === -1) return 0
  else if (index === 0) index = 1

  const current = tax_list[index]
  const baseIndex = index - 1
  const base = baseIndex > -1 ? tax_list[baseIndex] : undefined

  const remainder = amount - current.income
  const partTax = base ? calc(remainder, base.percent) : 0
  const tax = current.accumulate + partTax
  const tax_per_month = toCeil(tax / 12)

  return tax_per_month
}

// { longevity: 0, percent: 1 },
// { longevity: 4, percent: 3 },
// { longevity: 6, percent: 5 },
const getCompanyProvidentFund = (longevity = 0, provident_fund_list = []) => {
  const doc = provident_fund_list.find((it) => {
    return longevity >= it.longevity
  })

  const val = doc ? doc.percent : 0
  return val || 0
}

const getProvidentFund = (data = {}, employee = {}, setting = {}) => {
  const longevity = employee.longevity || 0
  const { salary, allowance } = data
  const { provident_fund = {} } = setting
  if (provident_fund.status !== 'active') {
    return { providentFund: 0, companyProvidentFund: 0 }
  }

  const companyProvidentFundRate = getCompanyProvidentFund(
    longevity,
    provident_fund.deduction_list
  )

  const income = salary + allowance
  const providentFund = calc(income, employee.provident_fund)
  const companyProvidentFund = calc(income, companyProvidentFundRate)

  return { providentFund, companyProvidentFund }
}

export const calcDeduction = (data = {}, employee = {}, setting = {}) => {
  const total_deduction = employee.total_deduction || 0
  const { salary, allowance, ssf_mode } = data
  const { socail_security_fund = {}, tax_deduction = {} } = setting

  const {
    employee_deduction = 1,
    company_deduction = 4,
    maximum_salary = 15000,
  } = socail_security_fund

  const general_deduction = tax_deduction.general_deduction || 0
  const tax_list = tax_deduction.tax_list || []

  const salaryAllowance = salary + allowance
  const ssf = calcSocialFund(
    ssf_mode,
    salaryAllowance,
    employee_deduction,
    maximum_salary
  )
  const company_ssf = calcSocialFund(
    ssf_mode,
    salaryAllowance,
    company_deduction,
    maximum_salary
  )

  const { providentFund, companyProvidentFund } = getProvidentFund(
    data,
    employee,
    setting
  )

  data.ssf = ssf
  data.company_ssf = company_ssf
  data.provident_fund = providentFund
  data.company_provident_fund = companyProvidentFund

  // total income tax = salary + bonus + ot + allowance + other income
  const wht = calcWHT(
    data,
    providentFund,
    general_deduction,
    total_deduction,
    tax_list
  )

  data.wht = wht

  data.total_deduction =
    wht +
    ssf +
    providentFund +
    data.unpaid_leave +
    data.student_loan_fund +
    data.other_deduction

  data.total = data.total_income - data.total_deduction

  return data
}
