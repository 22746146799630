import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { checkGoogle } from 'app/firebaseApp'
import { error, firebase, http, storage } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  uid: '',
  provider: {},
  img_url: undefined,
  email: '',
  name: '',
  surname: '',
}

const originalRole = {
  dashboard: {},
  company: {},
  employee: {},
  leave_req: {},
  doc: {},
  reimburse: {},
  attendance: {},
  payroll: {},
  admin: {},
  setting: {},
}

let state
export class Member extends BaseStore {
  constructor() {
    super()
    this.observable({
      user: cloneDeep(original),
      role: cloneDeep(originalRole),
      company_list: [],
    })

    state = this
    http.setMember(this)
  }

  reset() {
    firebase.logout()

    runInAction(() => {
      state.user = cloneDeep(original)
    })
  }

  getCurrentUser() {
    return new Promise((resolve) => {
      firebase.auth().onAuthStateChanged((user) => {
        resolve(user)
      })
    })
  }

  async checkLoginUser() {
    try {
      const user = await this.getCurrentUser()
      if (user) {
        runInAction(() => {
          state.firebase = user
        })
        await this.getUserProfile({ user })
      }
    } catch (e) {
      console.log('check user:', e.message)
    }
  }

  getProvider({ user }) {
    const list = user.providerData || []
    return list.map((item) => item.providerId)
  }

  setUserProfile({ user, role, company_list }) {
    if (user) {
      runInAction(() => {
        state.user = user
        state.role = role
        state.company_list = company_list || []
      })
    }
  }

  async getUserProfile(params = {}) {
    const { user } = params

    const token = await user.getIdToken()
    http.setToken(token)

    const url = `${config.api}/v1/user-admin/account/admin-customer/profile`
    const res = await http.get(url)

    const data = res.body
    this.setUserProfile(data)
  }

  isLogin() {
    return this.user.uid !== ''
  }

  logout() {
    http.setToken()
    this.reset()
  }

  async loginByGoogle() {
    const result = await checkGoogle()

    const { user } = result
    error.isNull(user, { message: 'user not found' })
    return await this.getUserProfile({ user })
  }

  async checkEmail({ email = '', mode }) {
    email = email.trim()
    const resp = await firebase.fetchSignInForEmail(email)

    const have = resp.length > 0
    return have
  }

  async loginByEmail(params = {}) {
    const { remember, password } = params
    const email = params.email.trim()

    const resp = await this.checkEmail({ email })
    if (resp === false) {
      error.lunch({ message: `This's email ${email} not found` })
      return
    }

    if (remember) {
      storage.save('remember', { remember, email })
    } else {
      storage.remove('remember')
    }

    const user = await firebase.signInWithEmail(email, password)
    return this.getUserProfile({ user })
  }

  async clearGateway() {
    const url = `${config.api}/v1/clear`
    await http.put(url)
  }

  async changeCompany({ company_id } = {}) {
    const url = `${config.api}/v1/user-admin/account/admin-customer/company/${company_id}`
    const res = await http.put(url)

    const data = res.body
    runInAction(() => {
      state.user = data
    })
  }
}

export default new Member()
