import { initMenu } from 'utils/helper'
import { timer } from 'utils'
import { color } from 'utils/color'

// Status List
const statusPending = {
  name: 'Pending',
  name_th: 'รอดำเนินการ',
  color: color.dark_grey,
  bg: color.yellow,
  value: 'pending',
}

const statusCompleted = {
  name: 'Completed',
  name_th: 'เรียบร้อย',
  color: color.dark_grey,
  bg: color.light_green,
  value: 'completed',
}

const statusWorking = {
  name: 'Working',
  name_th: 'กำลังทำงาน',
  color: color.dark_grey,
  bg: color.yellow,
  value: 'working',
}

const statusBreaking = {
  name: 'Breaking',
  name_th: 'ช่วงพัก',
  color: color.dark_grey,
  bg: color.yellow,
  value: 'breaking',
}

// Status List
const StatusKey = [
  statusPending,
  statusWorking,
  statusBreaking,
  statusCompleted,
]
const statusKey = initMenu(StatusKey)

export const Status = statusKey.keys

const statusNone = {
  name: 'None',
  name_th: 'ไม่มี',
  color: 'white',
  bg: '#8A8A8A',
  value: 'none',
}

const statusWorkLate = {
  name: 'Late Work',
  name_th: 'มาทำงานสาย',
  color: 'white',
  bg: '#8A8A8A',
  value: 'late_work',
}

const statusUncheckTime = {
  name: 'Uncheck Time',
  name_th: 'ลงเวลาไม่สำเร็จ',
  color: 'white',
  bg: '#8A8A8A',
  value: 'check_time_incompleted',
}

const statusLessWorkTime = {
  name: 'Less Work Time',
  name_th: 'เวลาทำงานน้อย',
  color: 'white',
  bg: '#8A8A8A',
  value: 'less_work_time',
}

const statusWorkTimeIncompleted = {
  name: 'Work Time Incompleted',
  name_th: 'เวลาทำงานไม่ครบ',
  color: 'white',
  bg: '#8A8A8A',
  value: 'work_time_incompleted',
}

// Check Status List
const CheckStatusKey = [
  statusNone,
  statusCompleted,
  statusUncheckTime,
  statusWorkLate,
  statusLessWorkTime,
  statusWorkTimeIncompleted,
]
const checkStatusKey = initMenu(CheckStatusKey)

export const CheckStatus = checkStatusKey.keys

const StatusList = [
  statusCompleted,
  statusUncheckTime,
  statusWorkLate,
  statusLessWorkTime,
  statusWorkTimeIncompleted,
]

// Status List
const status = initMenu(StatusList)
export const StatusMenu = {
  eng: status.menu,
  thai: status.menu_th,
}

const none = {
  name: 'Close',
  name_th: 'ปิดการใช้งาน',
  value: 'none',
}

const before = {
  name: 'Before Working Time',
  name_th: 'ก่อนเวลาทำงาน',
  value: 'before',
}

const after = {
  name: 'After Working Time',
  name_th: 'หลังเวลาทำงาน',
  value: 'after',
}

const both = {
  name: 'Both',
  name_th: 'ทั้งคู่',
  value: 'both',
}

// Reminding Option List
const RemindingOptionList = [none, before, after, both]
const remindingOption = initMenu(RemindingOptionList)

export const RemindingOption = remindingOption.keys
export const RemindingMenu = {
  eng: remindingOption.menu,
  thai: remindingOption.menu_th,
}
// function
export const getStatus = (value) => {
  const item = Status[value] || Status.pending
  return item
}

export const getRemindingOption = (value) => {
  const item = RemindingOption[value] || RemindingOption.none
  return item
}

export const getCheckStatus = (value) => {
  const item = CheckStatus[value] || CheckStatus.none
  return item
}

export const getAttendanceStatus = (item = {}) => {
  const { status, check_status } = item
  const statusItem = ['working', 'breaking'].includes(status)
    ? getStatus(status)
    : getCheckStatus(check_status)

  return statusItem
}

export const getWorkTime = (val = 0) => {
  if (val <= 0) return { hour: 0, minute: 0 }
  const hour = Math.trunc(val / 60)
  const minute = Math.trunc(val % 60)

  return { hour, minute }
}

export const toWorkTime = (val = 0, { isThai } = {}) => {
  const h = isThai ? 'ชั่วโมง' : 'hrs'
  const m = isThai ? 'นาที' : 'mins'

  if (val <= 0) return `0 ${m}`

  const { hour, minute } = getWorkTime(val)
  const hTxt = `${hour} ${h}`
  const tTxt = `${minute} ${m}`
  if (hour > 0 && minute > 0) {
    return `${hTxt} ${tTxt}`
  }

  if (hour > 0) return hTxt
  return tTxt
}

export const getDateTime = (now, val, startDate) => {
  const time = timer.get(val)

  const txt = `${now.format('DD/MM/YYYY')} ${time.format('HH:mm')}:00`
  const date = timer.get(txt, 'DD/MM/YYYY HH:mm:ss')

  if (startDate) {
    return startDate > date ? date.clone().add(1, 'days') : date
  }
  return date
}

const addPending = (date, minutes) => {
  const newDate = date.clone().add(minutes, 'minutes')

  return newDate
}

const subtractPending = (date, minutes) => {
  const newDate = date.clone().subtract(minutes, 'minutes')

  return newDate
}

export const toWorkingTime = (date, workingTime = {}) => {
  const { is_break, start_time, end_time, break_start_time, break_end_time } =
    workingTime

  const start_padding_minute = workingTime.start_padding_minute || 0
  const end_padding_minute = workingTime.end_padding_minute || 0
  const break_start_padding_minute = workingTime.break_start_padding_minute || 0
  const break_end_padding_minute = workingTime.break_end_padding_minute || 0

  const work_start_at = getDateTime(date, start_time)
  const work_end_at = getDateTime(date, end_time, work_start_at)
  const check_start_at = subtractPending(work_start_at, start_padding_minute)
  const check_end_at = addPending(work_end_at, end_padding_minute)

  const break_start_at = getDateTime(date, break_start_time)
  const break_end_at = getDateTime(date, break_end_time, break_start_at)

  const check_break_start_at = subtractPending(
    break_start_at,
    break_start_padding_minute
  )
  const check_break_end_at = addPending(break_end_at, break_end_padding_minute)
  return {
    working_time_id: workingTime.working_time_id,
    name: workingTime.name,
    check_start_at,
    check_end_at,
    work_start_at,
    work_end_at,

    is_break,
    check_break_start_at,
    check_break_end_at,
    break_start_at,
    break_end_at,
  }
}

export const getWorkingTime = (date, working_time_list = []) => {
  let workingTime
  for (const item of working_time_list) {
    const doc = toWorkingTime(date, item)

    if (date >= doc.check_start_at && date <= doc.check_end_at) {
      workingTime = doc
      break
    }
  }

  return workingTime
}
