import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/overtime`
let state
export class Category extends BaseStore {
  constructor() {
    super()
    this.observable({
      menu_list: [],
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
    })
    state = this
  }

  async getMenuList() {
    const { menu_list } = this.toJS()
    if (menu_list.length > 0) {
      return
    }

    const url = `${Url}/category?per_page=-1`
    const res = await http.get(url)

    const list = res.body || []
    const menus = list.map((it) => {
      return {
        name: it.category_name,
        value: it.ot_category_id,
        tag: it,
      }
    })

    runInAction(() => {
      state.menu_list = menus
    })
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20 } = query
    const q = `index=${index}&per_page=${per_page}`

    return q
  }

  async getList(query = {}) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}/category?${q}`
    const res = await http.get(url)

    const list = res.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCounter(query = {}) {
    const q = this.toQuery(query)
    const url = `${Url}/category/counter?${q}`
    const res = await http.get(url)

    const { counter } = res.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async createCategory(json = {}) {
    const url = `${Url}/category`
    const res = await http.post(url, { json })
    const data = res.body

    const { list, page } = this.toJS()

    list.unshift(data)
    page.total = page.total + 1

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async updateCategory({ ot_category_id }, json = {}) {
    const url = `${Url}/category/${ot_category_id}`
    await http.put(url, { json })

    const { list, page } = this.toJS()

    const i = list.findIndex((it) => it.ot_category_id === ot_category_id)
    if (i !== -1) {
      list[i] = json

      runInAction(() => {
        state.list = list
        state.page = page
      })
    }
  }

  async deleteCategory({ ot_category_id } = {}) {
    const url = `${Url}/category/${ot_category_id}`
    await http.delete(url)

    const { list, page } = this.toJS()

    const i = list.findIndex((it) => it.ot_category_id === ot_category_id)
    if (i !== -1) {
      list.splice(i, 1)
      page.total = page.total - 1

      runInAction(() => {
        state.list = list
        state.page = page
      })
    }
  }
}

export default new Category()
