const config = {
  mode: 'production',
  firebase: {
    apiKey: 'AIzaSyDFZLgl7KQ9v5YI8BoMdC0o4plxzuPzJEw',
    authDomain: 'hlo-app.firebaseapp.com',
    databaseURL: 'https://hlo-app.firebaseio.com',
    projectId: 'hlo-app',
    storageBucket: 'hlo-app.appspot.com',
    messagingSenderId: '642610830518',
    appId: '1:642610830518:web:b559025038092f34361a17',
  },
  api: 'https://api-v2.hlo.app/api',
  image: {
    width: 1000,
    height: 1000,
    size: 1000000, // 1mb
    default: '/hlo.png',
  },
}

module.exports = config
