import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/timesheet/activity`
let state
export class TimesheetActivity extends BaseStore {
  constructor() {
    super()
    this.observable({
      menu_list: [],
      activity_list: [],
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
    })
    state = this
  }

  async getMenuList() {
    const { menu_list } = this.toJS()
    if (menu_list.length > 0) {
      return
    }

    const url = `${Url}?per_page=-1`
    const res = await http.get(url)

    const list = res.body || []
    const menus = list.map((it) => {
      return {
        name: it.activity_name,
        value: it.ts_activity_id,
        tag: it,
      }
    })

    runInAction(() => {
      state.menu_list = menus
      state.activity_list = list
    })
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20, status } = query
    let q = `index=${index}&per_page=${per_page}`

    if (status) {
      q += `&status=${status}`
    }

    return q
  }

  async getList(query = {}) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const res = await http.get(url)

    const list = res.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCounter(query = {}) {
    const q = this.toQuery(query)
    const url = `${Url}/counter?${q}`
    const res = await http.get(url)

    const { counter } = res.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async create(json = {}) {
    const url = `${Url}`
    const res = await http.post(url, { json })
    const data = res.body

    const { list, page } = this.toJS()

    list.unshift(data)
    page.total = page.total + 1

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async update({ ts_activity_id }, json = {}) {
    const url = `${Url}/${ts_activity_id}`
    await http.put(url, { json })

    const { list, page } = this.toJS()

    const i = list.findIndex((it) => it.ts_activity_id === ts_activity_id)
    if (i !== -1) {
      list[i] = json

      runInAction(() => {
        state.list = list
        state.page = page
      })
    }
  }

  async delete({ ts_activity_id } = {}) {
    const url = `${Url}/${ts_activity_id}`
    await http.delete(url)

    const { list, page } = this.toJS()

    const i = list.findIndex((it) => it.ts_activity_id === ts_activity_id)
    if (i !== -1) {
      list.splice(i, 1)
      page.total = page.total - 1

      runInAction(() => {
        state.list = list
        state.page = page
      })
    }
  }
}

export default new TimesheetActivity()
