import { runInAction } from 'mobx'

import { http, format } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/payroll/config`
let state
export class PayrollConfig extends BaseStore {
  constructor() {
    super()
    this.observable({
      paycycle_menu_list: [],
      employee_list: [],
      doc: {},
    })
    state = this
  }

  async getSetting(isForce = true) {
    if (!isForce) {
      const setting = this.toJS().doc
      if (setting.payroll_config_id) return
    }

    const url = `${Url}`
    const resp = await http.get(url)

    const data = resp.body || {}

    runInAction(() => {
      state.doc = data
    })
  }

  async updateSetting(json = {}) {
    const url = `${Url}`
    await http.put(url, { json })

    runInAction(() => {
      state.doc = json
    })
  }

  async getActivePayCycleList() {
    const url = `${Url}/active-paycycle-list`
    const resp = await http.get(url)

    const { list = [] } = resp.body || {}

    const paycycle_list = list.map((it) => {
      const { label, paycycle } = it
      return { name: label, value: paycycle, tag: it }
    })
    runInAction(() => {
      state.paycycle_menu_list = paycycle_list
    })

    return paycycle_list
  }

  async getEmployeeList() {
    const url = `${config.api}/v1/user-admin/payroll/employee`
    const resp = await http.get(url)

    const list = resp.body || []

    const employee_list = list.map((it) => {
      const name = format.toUsername(it)
      it.username = name
      return { name, value: it.user_id, tag: it }
    })
    runInAction(() => {
      state.employee_list = employee_list
    })

    return employee_list
  }
}

export default new PayrollConfig()
