/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#1F1F1F' } = props
  const width = size * 24
  const height = size * 24
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.5" stroke={color}/>
      <circle cx="6" cy="12" r="1.5" stroke={color}/>
      <circle cx="12" cy="12" r="1.5" stroke={color}/>
      <circle cx="18" cy="12" r="1.5" stroke={color}/>
    </svg>
  )
}

/* eslint-enable */
export default Logo
