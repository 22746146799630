import { runInAction } from 'mobx'

import { http, timer } from 'utils'
import { config } from 'config'
import { attendance } from 'constant'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/attendance`

const infoList = [
  { name: 'user_id', value: 'user id', type: 'int' },
  { name: 'name', value: 'name', type: 'text' },
  { name: 'surname', value: 'surname', type: 'text' },
]

const toItem = (item = {}, nameList = []) => {
  const data = {}

  for (const it of nameList) {
    const { name, value, type } = it
    const def = it.def || ''
    const val = item[value] || def
    switch (type) {
      case 'int':
        data[name] = +val || 0
        break
      case 'date':
        data[name] = val !== '' ? timer.get(val, 'DD/MM/YYYY') : undefined
        break
      default:
        data[name] = val
        break
    }
  }

  return data
}

const getCode = (index, list) => {
  return index < list.length ? list[index] || '' : ''
}

const findItemByCode = (code, list) => {
  if (code === '') return {}

  const doc = list.find((it) => it.code === code)
  return doc || {}
}

const toEmployee = (
  item = {},
  { date, working_time_list = [], office_list = [] } = {}
) => {
  const doc = toItem(item, infoList)
  const num = date.clone().endOf('month').date()
  const start = date.clone().startOf('month')

  doc.shift_list = []

  for (let index = 0; index < num; index++) {
    const i = `${index + 1}`
    const work_at = start.toISOString()
    const timeList = (item[`${i}-time`] || '').split(',')
    const locationList = (item[`${i}-office`] || '').split(',')

    const timeLen = timeList.length
    const locationLen = locationList.length
    const max = timeLen > locationLen ? timeLen : locationLen
    const work_list = []

    for (let p = 0; p < max; p++) {
      const timeCode = getCode(p, timeList)
      const locationCode = getCode(p, locationList)

      const timeItem = findItemByCode(timeCode, working_time_list)
      const officeItem = findItemByCode(locationCode, office_list)

      const { start_time, end_time } = timeItem

      const shift = {
        working_time_id: timeItem.working_time_id,
        time_code: timeItem.code,
        working_time_name: timeItem.name,
        start_time,
        end_time,
        office_id: officeItem.office_id,
        office_code: officeItem.code,
        office_name: officeItem.name,
      }

      if (work_at) {
        const work_date = timer.get(work_at)
        shift.work_start_at = attendance.getDateTime(work_date, start_time)
        shift.work_end_at = attendance.getDateTime(
          work_date,
          end_time,
          shift.work_start_at
        )
      }

      work_list.push(shift)
    }

    doc.shift_list.push({ work_at, date: start.format('DDMMYY'), work_list })
    start.add(1, 'day')
  }

  return doc
}

let state
export class Importer extends BaseStore {
  constructor() {
    super()
    this.observable({
      working: {
        result: {
          success: 0,
          error: 0,
        },
        list: [],
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
      },
      checking: {
        result: {
          success: 0,
          error: 0,
        },
        list: [],
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
      },
    })
    state = this
  }

  resetWorking() {
    runInAction(() => {
      state.working = {
        result: {
          success: 0,
          error: 0,
        },
        list: [],
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
      }
    })
  }

  setWorkingPage({ index, per_page, total } = {}) {
    runInAction(() => {
      state.working.page = {
        index,
        per_page,
        total,
      }
    })
  }

  loadFileWorkingData(list = [], option = {}) {
    const resultList = list.map((it) => toEmployee(it, option))
    runInAction(() => {
      state.working = {
        result: {
          success: 0,
          error: 0,
        },
        list: resultList,
        page: {
          index: 1,
          per_page: 20,
          total: resultList.length,
        },
      }
    })
  }

  async importWorkingUser({ date }, working = {}) {
    try {
      const { user_id } = working
      const id = +user_id

      if (!id) {
        working.status = 'error'
        working.message = 'no user id'
        return working
      }
      const url = `${Url}/working-employee/user-id/${id}/${date}`

      await http.put(url, { json: working })

      working.status = 'success'
    } catch (e) {
      working.status = 'error'
      working.message = e.message
    }

    return working
  }

  async importWorkingData(list = [], work_at) {
    const date = work_at.toISOString()

    let result_list = []
    let process_list = []
    for (const item of list) {
      const { status } = item
      if (status !== 'success') {
        process_list.push(this.importWorkingUser({ date }, item))
      } else {
        result_list.push(item)
      }

      if (process_list.length > 0) {
        const temps = await Promise.all(process_list)
        result_list = result_list.concat(temps)
        process_list = []
      }
    }

    if (process_list.length !== 0) {
      const temps = await Promise.all(process_list)
      result_list = result_list.concat(temps)
    }

    const result = { success: 0, error: 0 }
    for (const item of result_list) {
      const { status } = item
      if (status === 'success') result.success++
      else result.error++
    }

    runInAction(() => {
      state.working = {
        result,
        list: result_list,
        page: {
          index: 1,
          per_page: 20,
          total: list.length,
        },
      }
    })
  }

  // checking
  resetChecking() {
    runInAction(() => {
      state.checking = {
        result: {
          success: 0,
          error: 0,
        },
        list: [],
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
      }
    })
  }

  setCheckingPage({ index, per_page, total } = {}) {
    runInAction(() => {
      state.checking.page = {
        index,
        per_page,
        total,
      }
    })
  }

  loadFileCheckingData(
    lines = [],
    { setting, office_list, working_time_list } = {}
  ) {
    const dataList = lines.map((it) => {
      const item = toCheckingTime(it, {
        setting,
        office_list,
        working_time_list,
      })

      return item
    })

    const list = dataList.filter(
      (it) => !!it.checkin_at && it.status !== 'error'
    )
    runInAction(() => {
      state.checking = {
        result: {
          success: 0,
          error: 0,
        },
        list,
        page: {
          index: 1,
          per_page: 20,
          total: list.length,
        },
      }
    })
  }

  async importCheckingUser({ office_id }, working = {}) {
    try {
      const { employee_id } = working
      if (!working.checkin_office_id || !working.checkout_office_id) {
        working.checkin_office_id = office_id
        working.checkout_office_id = office_id
      }

      if (!employee_id) {
        working.status = 'error'
        working.message = 'no employee id'
        return working
      }

      if (!working.checkin_office_id || !working.checkout_office_id) {
        working.status = 'error'
        working.message = 'no office id'
        return working
      }

      if (!working.working_time_id) {
        working.status = 'error'
        working.message = 'no working time id'
        return working
      }

      const url = `${Url}/request/check/employee-id/${employee_id}`
      await http.put(url, { json: { working } })

      working.status = 'success'
    } catch (e) {
      working.status = 'error'
      working.message = e.message
    }

    return working
  }

  async importCheckingData(list = [], { office_id } = {}) {
    let result_list = []
    let process_list = []
    for (const item of list) {
      const { status } = item
      if (status !== 'success') {
        process_list.push(this.importCheckingUser({ office_id }, item))
      } else {
        result_list.push(item)
      }

      if (process_list.length > 0) {
        const temps = await Promise.all(process_list)
        result_list = result_list.concat(temps)
        process_list = []
      }
    }

    if (process_list.length !== 0) {
      const temps = await Promise.all(process_list)
      result_list = result_list.concat(temps)
    }

    const result = { success: 0, error: 0 }
    for (const item of result_list) {
      const { status } = item
      if (status === 'success') result.success++
      else result.error++
    }
    runInAction(() => {
      state.checking = {
        result,
        list: result_list,
        page: {
          index: 1,
          per_page: 20,
          total: list.length,
        },
      }
    })
  }

  updateWorkingEmployee(data = {}) {
    const { user_id, work_at, work_list } = data
    const date = work_at.toISOString()
    const dateTxt = work_at.format('DDMMYY')

    const { list } = this.toJS().working
    const i = list.findIndex((it) => it.user_id === user_id)

    if (i !== -1) {
      const userWorking = list[i]
      const shift_list = userWorking.shift_list || []

      const index = shift_list.findIndex((it) => {
        return it.date === dateTxt
      })

      if (index === -1) {
        shift_list.push({ work_at: date, date: dateTxt, work_list })
      } else {
        shift_list[index].work_list = work_list
      }

      userWorking.shift_list = shift_list
      list[i] = userWorking
      runInAction(() => {
        state.working.list = list
      })
    }
  }
}

const getOffice = (code, office_list = []) => {
  if (!code || code === '') return {}

  const office = office_list.find((it) => it.code === code)
  return office || {}
}

const toCheckingTime = (
  item,
  { setting = {}, office_list = [], working_time_list = [] }
) => {
  const data = {
    office_id: undefined,
    working_time_id: undefined,
    working_time_name: '',
    work_start_at: undefined,
    work_end_at: undefined,

    check_start_at: undefined,
    check_end_at: undefined,
    work_at: undefined,

    checkin_at: undefined,
    checkout_at: undefined,
    work_minute: 0,
    late_minute: 0,

    checkin_break_at: undefined,
    checkout_break_at: undefined,
    break_minute: 0,
  }

  const {
    employee_id,
    office_code,
    date_name,
    date_format,
    check_list = [],
    check_format,
  } = setting

  if (office_code && office_code !== '') {
    const code = item[office_code]
    const office = getOffice(code, office_list)

    data.checkin_office_id = office.office_id
    data.checkout_office_id = office.office_id
    data.office_name = office.name
  }

  const dateTxt = item[date_name]
  const format = `${date_format} ${check_format}`
  const check_time_list = []

  let lastDate = undefined
  for (const name of check_list) {
    const timeTxt = item[name]
    if (timeTxt) {
      const txt = `${dateTxt} ${timeTxt}`
      let date = timer.get(txt, format)
      const isValid = date.isValid()
      if (!isValid) {
        data.status = 'error'
        data.message = 'invalid datetime format'
        return data
      }

      if (lastDate) {
        if (date < lastDate) {
          date = date.clone().add(1, 'days')
        }
      }
      lastDate = date.clone()
      check_time_list.push(date)
    }
  }

  let workingTime
  for (const checkTime of check_time_list) {
    workingTime = workingTime
      ? workingTime
      : attendance.getWorkingTime(checkTime, working_time_list)

    if (!workingTime) continue

    const {
      working_time_id,
      check_start_at,
      check_end_at,
      is_break,
      check_break_start_at,
      check_break_end_at,
    } = workingTime
    // check in
    if (!data.checkin_at) {
      data.checkin_at = checkTime
      data.working_time_id = working_time_id
      data.working_time_name = workingTime.name
      data.work_start_at = workingTime.work_start_at
      data.work_end_at = workingTime.work_end_at

      data.check_start_at = check_start_at
      data.check_end_at = check_end_at
      data.work_at = checkTime.clone().startOf('day')
    } else if (
      is_break === 'yes' &&
      checkTime >= check_break_start_at &&
      checkTime <= check_break_end_at
    ) {
      // breaking time
      if (!data.checkin_break_at) data.checkin_break_at = checkTime
      else if (!data.checkout_break_at) data.checkout_break_at = checkTime
    } else {
      data.checkout_at = checkTime
    }
  }

  // if checkout is null and checkin breaking not null, set checkout from checkin break
  if (!data.checkout_at && data.checkin_break_at) {
    data.checkout_at = data.checkin_break_at
  }
  // if checkin breaking = checkout, set null to checkin breaking
  if (data.checkin_break_at === data.checkout_at && !data.checkout_break_at) {
    data.checkin_break_at = undefined
  }

  data.employee_id = item[employee_id]

  return data
}

export default new Importer()
