/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#1F1F1F' } = props
  const width = size * 24
  const height = size * 21
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7_547)">
        <path d="M4.37148 10.3583C5.93224 10.3583 7.19748 9.07604 7.19748 7.4943C7.19748 5.91256 5.93224 4.6303 4.37148 4.6303C2.81072 4.6303 1.54547 5.91256 1.54547 7.4943C1.54547 9.07604 2.81072 10.3583 4.37148 10.3583Z" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.97184 10.1997C2.97184 10.1997 0.334351 10.7909 0.334351 12.1823V16.1061H1.6522V21.6629" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.74097 10.1997C5.74097 10.1997 8.37845 10.7909 8.37845 12.1823V16.1061H7.0606V21.6629" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.47287 10.4917L3.41467 14.6192L4.47287 15.9313L5.54529 14.484L4.47287 10.4917Z" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.62988 2.6657C3.63035 2.0492 3.87221 1.45808 4.30236 1.02214C4.73251 0.58621 5.31579 0.341095 5.92412 0.340618H15.3732C15.6745 0.340618 15.9728 0.400757 16.2512 0.517604C16.5295 0.63445 16.7824 0.805715 16.9955 1.02162C17.2085 1.23752 17.3775 1.49384 17.4928 1.77593C17.6081 2.05802 17.6674 2.36037 17.6674 2.6657V19.3378C17.6674 19.6431 17.6081 19.9453 17.4927 20.2273C17.3774 20.5093 17.2084 20.7655 16.9954 20.9813C16.7823 21.197 16.5294 21.3681 16.251 21.4848C15.9727 21.6014 15.6744 21.6614 15.3732 21.6611H5.92412" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.7045 13.1052C14.4961 13.1052 15.1379 12.4548 15.1379 11.6524C15.1379 10.8501 14.4961 10.1997 13.7045 10.1997C12.9128 10.1997 12.271 10.8501 12.271 11.6524C12.271 12.4548 12.9128 13.1052 13.7045 13.1052Z" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.7608 5.80366H14.0571" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.2728 4.09319L14.5159 5.80366L13.2052 7.61506" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_7_547">
          <rect width="18" height="22" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

/* eslint-enable */
export default Logo
