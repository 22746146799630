/* eslint-disable */
const Logo = (props) => {
  const { size = 1 } = props
  const width = `${Math.ceil(61 * size)}`
  const height = `${Math.ceil(57 * size)}`

  return (
    <svg width={width} height={height} viewBox="0 0 61 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9272 25.5L29.4272 40.5L57.9272 11" stroke="#F36273" strokeWidth="5" strokeLinecap="round"/>
      <path d="M47.4823 9.69355C42.6591 4.93804 36.0166 2 28.6832 2C13.9465 2 2 13.8645 2 28.5C2 43.1355 13.9465 55 28.6832 55C38.0169 55 46.2312 50.2406 51 43.0323" stroke="#85E3D1" strokeWidth="4" strokeLinecap="round"/>
    </svg>
  )
}

/* eslint-enable */
export default Logo
