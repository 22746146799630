import { storage } from 'utils'

import BaseStore from './BaseStore'

export class Lang extends BaseStore {
  constructor() {
    super()
    this.observable({
      selected: 'thai',
    })
  }

  async init() {
    const temp = await storage.load('language')
    if (temp) {
      const { lang = 'eng' } = temp
      this.setLanguage(lang)
    }
  }

  setLanguage(val) {
    const lang = val === 'thai' ? 'thai' : 'eng'
    this.selected = lang
    storage.save('language', { lang })
  }

  isThai() {
    return this.selected === 'thai'
  }

  getMessage(eng, thai) {
    let text = this.selected === 'thai' ? thai : eng
    if (text === '' || text === undefined) {
      return eng
    }

    return text
  }

  getTxtSuccess() {
    const text =
      this.selected === 'thai' ? 'บันทึกเรียบร้อยแล้ว' : 'saved successfully'

    return text
  }

  getValidate(invalid, text, text_th) {
    if (invalid) {
      const txt =
        this.selected === 'thai' ? `กรุณากรอก${text_th}` : `please fill ${text}`

      return txt
    }

    return undefined
  }
}

export default new Lang()
