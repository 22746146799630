import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/account/config`

const original = {
  edit_info_status: 'yes',

  birthday_status: 'yes',
  anniversary_status: 'yes',
}

let state

export class EmployeeSetting extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
    })
    state = this
  }

  async getConfig() {
    const url = `${Url}`
    const resp = await http.get(url)

    const data = resp.body
    runInAction(() => {
      state.doc = data
    })
  }

  async updateConfig(json = {}) {
    const url = `${Url}`
    await http.put(url, { json })

    runInAction(() => {
      state.doc = json
    })
  }
}

export default new EmployeeSetting()
