import { runInAction } from 'mobx'

import { http, timer } from 'utils'
import { config } from 'config'
import { payroll } from 'constant'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/payroll`

const names = [
  { name: 'user_id', value: 'user_id', type: 'int' },
  { name: 'monthly', value: 'monthly', type: 'int' },
  { name: 'accumulated', value: 'accumulated', type: 'int' },
  { name: 'salary', value: 'salary', type: 'income_tax' },
  { name: 'bonus', value: 'bonus', type: 'income_tax' },
  { name: 'overtime', value: 'overtime', type: 'income_tax' },
  { name: 'severance_pay', value: 'severance pay', type: 'income_tax' },
  { name: 'commission', value: 'commission', type: 'income' },
  // { name: 'expense', value: 'expense', type: 'income' },
  // { name: 'expense', value: 'expense', type: 'income' },
  // { name: 'other_income', value: 'other income', type: 'income_tax' },

  { name: 'unpaid_leave', value: 'unpaid leave', type: 'deduction' },
  { name: 'student_loan_fund', value: 'student loan fund', type: 'deduction' },
  // { name: 'other_deduction', value: 'other deduction', type: 'deduction' },
  { name: 'calculation', value: 'calculation', type: 'text' },
  { name: 'wht', value: 'wht', type: 'deduction' },
  { name: 'ssf', value: 'ssf', type: 'deduction' },
  { name: 'company_ssf', value: 'ssf (co)', type: 'int' },
  { name: 'provident_fund', value: 'provident fund', type: 'deduction' },
  { name: 'company_provident_fund', value: 'provident fund (co)', type: 'int' },
  { name: 'commission_wht', value: 'commission wht', type: 'int' },
]

const toEmployeePayroll = (
  item = {},
  { setting = {}, benefit_list = [], employee_list = [] }
) => {
  const data = {
    status: 'approved',
    name: item.Name || '',
    other_income: 0,
    other_deduction: 0,
    total_income: 0,
    total_income_tax: 0,
    total_deduction: 0,
    total: 0,
    expense_list: [],
    other_income_list: [],
    other_deduction_list: [],
  }

  for (const it of names) {
    const { name, value, type } = it
    const txt = item[value] || ''
    const val = type !== 'text' ? +`${txt}` || 0 : txt

    if (name === 'wht') {
      const len = txt.length - 1
      if (txt === 'no') {
        data.wht = 0
        data.wht_mode = 'no'
        continue
      } else if (val > 0 && val < 1) {
        data.wht = 0
        data.wht_mode = 'percent'
        data.wht_value = val
        continue
      } else if (txt[len] === '%') {
        const percent = +txt.substring(0, len) || 0
        data.wht = 0
        data.wht_mode = 'percent'
        data.wht_value = percent > 0 ? percent / 100 : 0
        continue
      }
    } else if (name === 'ssf') {
      if (txt === 'no') {
        data.ssf = 0
        data.ssf_mode = 'no'
        continue
      }
    }

    // no option
    switch (type) {
      case 'income':
        data.total_income += val
        break
      case 'income_tax':
        data.total_income += val
        data.total_income_tax += val
        break
      case 'deduction':
        data.total_deduction += val
        break
      default:
        break
    }

    data[name] = val
  }

  const employee = employee_list.find((it) => {
    return +it.user_id === +data.user_id
  })

  if (employee) {
    data.user_id = employee.user_id
    data.username = employee.username
  } else {
    return undefined
  }

  const keys = Object.keys(item)

  // manage user allowance
  const allowance_list = []
  let allowance = 0
  for (const it of benefit_list) {
    const { benefit_id } = it
    const codeTag = `(${it.code})`
    const name = keys.find((n) => n.includes(codeTag))
    if (name) {
      const val = +item[name] || 0
      allowance += val
      allowance_list.push({
        benefit_id,
        name: it.name,
        name_th: it.name_th,
        amount: val,
      })
    }
  }

  // other income and deduction
  const other_income_list = []
  const other_deduction_list = []
  const expense_list = []
  let other_income = 0
  let other_deduction = 0
  let expense = 0
  const incomeKey = '-income'
  const deductionKey = '-deduction'
  const expenseKey = '-expense'
  const expenseNoTaxKey = '-expense-no-tax'
  for (const n of keys) {
    const txt = item[n] || ''
    const amount = +`${txt}` || 0
    if (n.includes(incomeKey)) {
      const name = n.replace(incomeKey, '')
      other_income_list.push({ name, amount })
      other_income += amount
    } else if (n.includes(deductionKey)) {
      const name = n.replace(deductionKey, '')
      other_deduction_list.push({ name, amount })
      other_deduction += amount
    } else if (n.includes(expenseNoTaxKey)) {
      const name = n.replace(expenseNoTaxKey, '')
      expense_list.push({ name, amount, is_income: 'no' })
      expense += amount
    } else if (n.includes(expenseKey)) {
      const name = n.replace(expenseKey, '')
      expense_list.push({ name, amount, is_income: 'yes' })
      expense += amount
    }
  }

  data.allowance = allowance
  data.allowance_list = allowance_list
  data.total_income += allowance
  data.total_income_tax += allowance

  data.expense = expense
  data.expense_list = expense_list

  data.other_income = other_income
  data.other_income_list = other_income_list

  data.other_deduction = other_deduction
  data.other_deduction_list = other_deduction_list
  // data.other_income_list = getOtherList('Other', data.other_income)
  // data.other_deduction_list = getOtherList('Other', data.other_deduction)

  data.total = data.total_income - data.total_deduction
  const result =
    data.calculation === 'yes'
      ? payroll.calcDeduction(data, employee, setting)
      : data

  return result
}

let state
export class Importer extends BaseStore {
  constructor() {
    super()
    this.observable({
      payroll: {
        result: {
          success: 0,
          error: 0,
        },
        list: [],
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
      },
    })
    state = this
  }

  resetPayroll() {
    runInAction(() => {
      state.payroll = {
        result: {
          success: 0,
          error: 0,
        },
        list: [],
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
      }
    })
  }

  setPayrollPage({ index, per_page, total } = {}) {
    runInAction(() => {
      state.payroll.page = {
        index,
        per_page,
        total,
      }
    })
  }

  loadFilePayrollData(list = [], { setting, employee_list, benefit_list }) {
    const { tax_deduction = {} } = setting
    tax_deduction.tax_list = (tax_deduction.tax_list || []).reverse()
    setting.tax_deduction = tax_deduction

    const result_list = []
    for (const it of list) {
      const item = toEmployeePayroll(it, {
        setting,
        employee_list,
        benefit_list,
      })
      if (item) result_list.push(item)
    }

    runInAction(() => {
      state.payroll = {
        result: {
          success: 0,
          error: 0,
        },
        list: result_list,
        page: {
          index: 1,
          per_page: 20,
          total: result_list.length,
        },
      }
    })
  }

  async createPayroll({ paycycle_type, paycycle }) {
    const date = timer.get(paycycle, 'MMYY').toISOString()
    const url = `${Url}/request/${paycycle_type}/${date}`
    const res = await http.post(url)

    return res.body
  }

  updateItem({ user_id }, item) {
    const { list = [] } = this.toJS().payroll

    const index = list.findIndex((it) => it.user_id === user_id)
    if (index !== -1) {
      list[index] = item
      runInAction(() => {
        state.payroll.list = list
      })
    }
  }

  async importPayroll(working, { payroll_id }) {
    try {
      const { user_id } = working
      if (!user_id) {
        working.status = 'error'
        working.message = 'no user id'
        return working
      }

      const url = `${Url}/importer/${payroll_id}/${user_id}`
      await http.put(url, { json: working })

      working.status = 'success'
    } catch (e) {
      working.status = 'error'
      working.message = e.message
    }

    return working
  }

  async importPayrollData(list = [], { paycycle_type, paycycle }) {
    const { payroll_id } = await this.createPayroll({ paycycle_type, paycycle })

    let result_list = []
    let process_list = []
    for (const item of list) {
      const { status } = item
      if (status !== 'success') {
        const r = this.importPayroll(item, { payroll_id })
        process_list.push(r)
      } else {
        result_list.push(item)
      }

      if (process_list.length > 0) {
        const temps = await Promise.all(process_list)
        result_list = result_list.concat(temps)
        process_list = []
      }
    }

    if (process_list.length !== 0) {
      const temps = await Promise.all(process_list)
      result_list = result_list.concat(temps)
    }

    const url = `${Url}/request/${payroll_id}/summary`
    await http.put(url)

    const result = { success: 0, error: 0 }
    for (const item of result_list) {
      const { status } = item
      if (status === 'success') result.success++
      else result.error++
    }

    runInAction(() => {
      state.payroll = {
        result,
        list: result_list,
        page: {
          index: 1,
          per_page: 20,
          total: list.length,
        },
      }
    })

    return result
  }
}

export default new Importer()
