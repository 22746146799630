if (typeof window === 'undefined')
  global.window = { location: { host: 'testhost' } }

const maps = {
  'admin.hlo.app': 'production',
  'hlo-admin.web.app': 'production',
  'hlo-testing-admin.web.app': 'staging',
  'hlo-testing-admin.firebaseapp.com': 'staging',
}

const host = window.location.host
const found = maps[host]

const env = found ? found : 'localhost'
export const config = require(`./config.${env}`)
