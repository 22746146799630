import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const Url = `${config.api}/v1/user-admin/account/department`
let state
export class Department extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
      menu_list: [],
      data_list: [],
    })
    state = this
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20 } = query

    const q = `index=${index}&per_page=${per_page}`
    return q
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page
    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCountList(query) {
    const q = this.toQuery(query)
    const url = `${Url}/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async getMenuList() {
    const { menu_list } = this.toJS()
    if (menu_list.length > 0) {
      return
    }

    const url = Url
    const resp = await http.get(url)

    const list = resp.body || []
    const menus = list.map((it) => {
      const { department_id, name } = it
      return { name, value: department_id, tag: it }
    })

    runInAction(() => {
      state.menu_list = menus
      state.data_list = list
    })
  }

  async createDepartment(json = {}) {
    const url = Url
    const res = await http.post(url, { json })

    const data = res.body

    const { page, list } = this.toJS()

    list.unshift(data)
    page.total++

    runInAction(() => {
      state.list = list
      state.page = page
      state.menu_list = []
    })
  }

  async updateDepartment(department_id, json = {}) {
    const url = `${Url}/${department_id}/detail`
    await http.put(url, { json })

    const { list } = this.toJS()
    const i = list.findIndex((it) => it.department_id === department_id)
    if (i > -1) {
      list[i] = json
    }
    runInAction(() => {
      state.list = list
      state.menu_list = []
    })
  }

  async deleteDepartment(department_id) {
    const url = `${Url}/${department_id}/delete`
    await http.put(url, {})

    const { list } = this.toJS()
    const i = list.findIndex((it) => it.department_id === department_id)
    if (i > -1) {
      list.splice(i, 1)
    }
    runInAction(() => {
      state.list = list
      state.menu_list = []
    })
  }
}

export default new Department()
