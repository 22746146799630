import styled from 'styled-components'
import { Layout } from 'antd'

import SideMenu from './SideMenu'
import Message from './Message'

const { Content } = Layout

const MainLayout = (props) => {
  const { children } = props

  return (
    <>
      <Message />
      <Wrapper>
        <Layout>
          <SideMenu />
          <Layout>
            <Content>
              <Body>{children}</Body>
            </Content>
          </Layout>
        </Layout>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  min-height: 90vh;
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px 0px;

  .ant-layout {
    background: transparent;
  }

  ${(p) => p.theme.media.wirescreen} {
    padding: 20px 10px;
  }
`

const Body = styled.div`
  width: 100%;
  min-height: 95vh;
  max-width: 1440px;
  padding: 0px 8px 0px 36px;

  @media print {
    min-height: 90vh;
    margin-top: 0px;
    padding: 0px;
  }
`

export default MainLayout
