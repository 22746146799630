import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/payroll`
let state
export class Incompleted extends BaseStore {
  constructor() {
    super()
    this.observable({
      report: {
        list: [],
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
      },
    })
    state = this
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20 } = query
    let q = `index=${index}&per_page=${per_page}`

    return q
  }

  async getReportList(query = {}) {
    const url = `${Url}/incomplete`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.report = {
        list,
        page: {
          index: 1,
          per_page: 20,
          total: list.length,
        },
      }
    })
  }
}

export default new Incompleted()
