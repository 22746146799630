import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#494949' } = props
  const width = `${Math.ceil(20 * size)}`
  const height = `${Math.ceil(20 * size)}`

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2149 6.56203C19.7188 6.05816 20 5.38633 20 4.67154C20 3.95675 19.7227 3.28492 19.2149 2.78105L17.2229 0.789006C16.7151 0.277324 16.0433 0 15.3285 0C14.6137 0 13.9418 0.277324 13.438 0.7851L1.01308 13.21C0.31782 13.9053 -0.0493413 14.8622 0.00534226 15.8426L0.00143629 19.0221C0.00143629 19.2799 0.102992 19.5298 0.286572 19.7134C0.470153 19.897 0.716229 19.9986 0.977929 19.9986L4.15739 19.9947C5.13779 20.0493 6.09475 19.6822 6.79001 18.9869C7.1728 18.6041 7.1728 17.987 6.79001 17.6042C6.40723 17.2214 5.79008 17.2214 5.4073 17.6042C5.10263 17.9089 4.67688 18.069 4.24723 18.0417C4.2277 18.0417 4.20426 18.0378 4.18473 18.0378L1.95442 18.0456L1.95833 15.8153C1.95833 15.7957 1.95833 15.7723 1.95442 15.7528C1.92708 15.3231 2.08722 14.8974 2.39189 14.5927L12.7466 4.23798L15.762 7.25339L7.98133 15.0341C7.59855 15.4169 7.59855 16.034 7.98133 16.4168C8.36412 16.7996 8.98126 16.7996 9.36405 16.4168L19.2149 6.56203ZM14.1293 2.85526L14.8207 2.16391C14.9574 2.0272 15.1371 1.95298 15.3324 1.95298C15.5238 1.95298 15.7073 2.0272 15.8441 2.16391L17.8361 4.15595C18.1173 4.43718 18.1173 4.89809 17.8361 5.17932L17.1447 5.87067L14.1293 2.85526Z"
        fill={color}
      />
      <path
        d="M19.0235 18.0456H10.2155C9.67648 18.0456 9.23901 18.4831 9.23901 19.0221C9.23901 19.5611 9.67648 19.9986 10.2155 19.9986H19.0235C19.5625 19.9986 20 19.5611 20 19.0221C20 18.4831 19.5625 18.0456 19.0235 18.0456Z"
        fill={color}
      />
    </svg>
  )
}

/* eslint-enable */
export default Logo
