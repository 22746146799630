import { runInAction } from 'mobx'

import { http, timer } from 'utils'
import { leaveBalance } from 'constant'
import { config } from 'config'

import BaseStore from '../BaseStore'

const DateFormat = 'MMYY'
const Url = `${config.api}/v1/user-admin/leave-request/leave-balance`
let state
export class Balance extends BaseStore {
  constructor() {
    super()
    this.observable({
      user_id: undefined,
      balance_date: undefined,
      balance_list: [],
      next: {
        balance_date: undefined,
        balance_list: [],
      },

      employee: {
        user_id: undefined,
        date: undefined,
        balance_list: [],
      },
    })

    state = this
  }

  async getBalanceList(store, { user_id, balance_date, force } = {}) {
    const txt = balance_date.toISOString()

    if (!force && store.balance_date && store.user_id === user_id) {
      const current = store.balance_date.format(DateFormat)
      const next = balance_date.format(DateFormat)
      if (current === next) {
        return store.balance_list
      }
    }

    if (user_id) {
      const url = `${Url}/${user_id}/${txt}`
      const res = await http.get(url)

      const list = res.body || []

      return list
    } else {
      return []
    }
  }

  async getLeaveBalance({ user_id, date, force } = {}) {
    const balance_date = date ? date : timer.get()
    const store = this.toJS()
    const list = await this.getBalanceList(store, {
      user_id,
      balance_date,
      force,
    })

    runInAction(() => {
      state.user_id = user_id
      state.balance_date = balance_date
      state.balance_list = list
    })

    return list
  }
  /*
  async getNextLeaveBalance({ date, force } = {}) {
    const balance_date = date ? date : timer.get()
    const store = this.toJS().next
    const list = await this.getBalanceList(store, { balance_date, force })

    runInAction(() => {
      state.next = {
        balance_date,
        balance_list: list,
      }
    })

    return list
  }
  */

  async getAvailableBalance({ leave_config, leave_req, leave_type } = {}) {
    const { isSame } = leaveBalance.isSameBalance({
      leave_config,
      leave_req,
      leave_type,
    })

    const { user_id, start_date } = leave_req
    if (leave_req.leave_type === 'one' || isSame) {
      const balance_list = await this.getLeaveBalance({
        user_id,
        date: start_date,
      })

      const balance_day = leaveBalance.getAvailableBalance(
        balance_list,
        leave_req,
        leave_type
      )
      return balance_day
    } else {
      /*
      const [current_list, next_list] = await Promise.all([
        this.getLeaveBalance({ date: start_date })
        this.getNextLeaveBalance({ date: end_date })
      ])

      const current_day = leaveBalance.getAvailableBalance(
        current_list,
        leave_req,
        leave_type
      )

      const next_day = leaveBalance.getAvailableBalance(
        next_list,
        leave_req,
        leave_type
      )
      */
      return undefined
    }
  }

  async getEmployeeBalanceList({ user_id, date } = {}) {
    if (!user_id || !date) {
      runInAction(() => {
        state.employee = {
          user_id,
          date,
          balance_list: [],
        }
      })

      return
    }

    const txt = date.toISOString()
    const url = `${Url}/${user_id}/${txt}`
    const res = await http.get(url)

    const balance_list = res.body || []

    runInAction(() => {
      state.employee = {
        user_id,
        date,
        balance_list,
      }
    })
  }

  async updateBalance({ index, user_id, date }, json = {}) {
    const { leave_balance_id } = json
    let data
    if (leave_balance_id) {
      const url = `${Url}/${leave_balance_id}`
      const res = await http.put(url, { json })
      data = res.body
    } else {
      const txt = date.toISOString()
      const url = `${Url}/${user_id}/${txt}`
      const res = await http.post(url, { json })
      data = res.body
    }

    const { employee = {} } = this.toJS()
    const balance_list = employee.balance_list || []
    balance_list[index].leave_balance = data

    employee.balance_list = balance_list
    runInAction(() => {
      state.employee = employee
    })
  }
}

export default new Balance()
