import { initMenu } from 'utils/helper'
import { color } from 'utils/color'

// Status List
const statusApprove = {
  name: 'Approved',
  name_th: 'อนุมัติ',
  color: color.dark_grey,
  bg: color.light_green,
  value: 'approved',
}
const statusReject = {
  name: 'Reject',
  name_th: 'ไม่อนุมัตื',
  color: 'white',
  bg: color.red,
  value: 'reject',
}
const statusCancel = {
  name: 'Cancel',
  name_th: 'ยกเลิก',
  color: 'white',
  bg: color.grey,
  value: 'cancel',
}
const StatusList = [
  {
    name: 'Pending',
    name_th: 'รอกำเนินการ',
    color: color.dark_grey,
    bg: color.yellow,
    value: 'pending',
  },
  statusApprove,
  statusReject,
  statusCancel,
]

// Status List
const StatusKey = [
  {
    name: 'Requesting',
    name_th: 'รออนุมัติ',
    color: color.dark_grey,
    bg: color.yellow,
    value: 'requesting',
  },
  statusApprove,
  statusReject,
  statusCancel,
  {
    name: 'Canceling',
    name_th: 'รออนุมัติการยกเลิก',
    color: color.dark_grey,
    bg: color.yellow,
    value: 'canceling',
  },
]
const status = initMenu(StatusList)
const statusKey = initMenu(StatusKey)

export const Status = statusKey.keys
export const StatusMenu = {
  eng: status.menu,
  thai: status.menu_th,
}

export const getStatus = (value) => {
  const item = Status[value] || Status.requesting
  return item
}

// Balance Type List
const BalanceTypeList = [
  { name: 'Unused', name_th: 'ไม่ใช้งาน', value: 'unused' },
  { name: 'Per Request', name_th: 'ต่อครั้ง', value: 'per_request' },
  { name: 'Per Year', name_th: 'ต่อปี', value: 'per_year' },
  { name: 'Per Month', name_th: 'ต่อเดือน', value: 'per_month' },
]
const balanceType = initMenu(BalanceTypeList)

export const BalanceType = balanceType.keys
export const BalanceTypeMenu = {
  eng: balanceType.menu,
  thai: balanceType.menu_th,
}

export const getBalanceType = (value) => {
  const item = BalanceType[value] || BalanceType.unused
  return item
}

// Approval Type List
const ApprovalTypeList = [
  { name: 'No', name_th: 'ปิดการใช้งาน', value: 'no' },
  { name: 'Yes', name_th: 'เปิดการใช้งาน', value: 'yes' },
]
const approvalType = initMenu(ApprovalTypeList)

export const ApprovalType = approvalType.keys
export const ApprovalTypeMenu = {
  eng: approvalType.menu,
  thai: approvalType.menu_th,
}

export const getApprovalType = (value) => {
  const item = ApprovalType[value] || ApprovalType.no
  return item
}
