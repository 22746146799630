import React from 'react'
/* eslint-disable */

const Logo = (props) => {
  const { size = 1, color = '#494949' } = props

  const width = `${Math.ceil(18 * size)}`
  const height = `${Math.ceil(18 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1425 15.7302L13.8994 12.4871C14.8823 11.2004 15.4214 9.63976 15.4216 7.99291C15.4216 6.0083 14.6486 4.14233 13.2451 2.73901C11.8417 1.33569 9.97601 0.562744 7.99115 0.562744C6.00653 0.562744 4.14056 1.33569 2.73724 2.73901C-0.159729 5.63623 -0.159729 10.3501 2.73724 13.2468C4.14056 14.6504 6.00653 15.4233 7.99115 15.4233C9.638 15.4231 11.1987 14.8841 12.4854 13.9012L15.7285 17.1443C15.9235 17.3396 16.1796 17.4372 16.4355 17.4372C16.6913 17.4372 16.9474 17.3396 17.1425 17.1443C17.5331 16.7539 17.5331 16.1206 17.1425 15.7302ZM4.15131 11.8328C2.03412 9.71557 2.03436 6.2705 4.15131 4.15307C5.17694 3.12768 6.54071 2.56274 7.99115 2.56274C9.44183 2.56274 10.8054 3.12768 11.831 4.15307C12.8566 5.17871 13.4216 6.54248 13.4216 7.99291C13.4216 9.4436 12.8566 10.8071 11.831 11.8328C10.8054 12.8584 9.44183 13.4233 7.99115 13.4233C6.54071 13.4233 5.17694 12.8584 4.15131 11.8328Z"
        fill={color}
      />
    </svg>
  )
}
/* eslint-enable */
export default Logo
