/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#626262' } = props
  const width = `${Math.ceil(26 * size)}`
  const height = `${Math.ceil(24 * size)}`

  return (
    <svg width={width} height={height} viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.86243 2C9.86243 1.44772 9.39828 1 8.82573 1C8.25318 1 7.78903 1.44772 7.78903 2V3H6.75233C3.88957 3 1.56885 5.23858 1.56885 8V10V18C1.56885 20.7614 3.88957 23 6.75233 23H19.1927C22.0555 23 24.3762 20.7614 24.3762 18V10V8C24.3762 5.23858 22.0555 3 19.1927 3H18.156V2C18.156 1.44772 17.6919 1 17.1193 1C16.5468 1 16.0826 1.44772 16.0826 2V3H9.86243V2ZM22.3028 9V8C22.3028 6.34315 20.9104 5 19.1927 5H18.156V6C18.156 6.55228 17.6919 7 17.1193 7C16.5468 7 16.0826 6.55228 16.0826 6V5H9.86243V6C9.86243 6.55228 9.39828 7 8.82573 7C8.25318 7 7.78903 6.55228 7.78903 6V5H6.75233C5.03468 5 3.64224 6.34315 3.64224 8V9H22.3028ZM3.64224 11H22.3028V18C22.3028 19.6569 20.9104 21 19.1927 21H6.75233C5.03468 21 3.64224 19.6569 3.64224 18V11Z" fill={color}/>
    </svg>
  )
}

/* eslint-enable */
export default Logo
