import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/attendance/config`

const original = {
  company_id: '',
  tag: '',
  version: '',
  general: {
    gps_range: 0.5,
    is_require_photo: 'no',
  },
  importer: {
    employee_id: '',
    office_code: '',
    date_status: 'active',
    date_name: 'วันที่',
    date_format: 'D/M/YY',

    check_list: [],
    check_format: 'HH:mm',
  },
}

let state

export class AttendanceSetting extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
    })
    state = this
  }

  async getConfig() {
    const url = `${Url}`
    const resp = await http.get(url)

    const data = resp.body
    runInAction(() => {
      state.doc = data
    })
  }

  async updateConfig(json = {}) {
    const url = `${Url}`
    await http.put(url, { json })

    runInAction(() => {
      state.doc = json
    })
  }
}

export default new AttendanceSetting()
