import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  consent_id: undefined,
  ver: '',
  consent_type: 'privacy',
  status: 'active',
  title: '',
  short_content: '',
  content: '',
}

let state
export class Consent extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
    })
    state = this
  }

  async getConsent(typ) {
    const url = `${config.api}/v1/public/consent/${typ}`
    const resp = await http.get(url)

    const data = resp.body || {}

    const tmp = data.consent_id ? data : cloneDeep(original)
    runInAction(() => {
      state.doc = tmp
    })
  }
}

export default new Consent()
