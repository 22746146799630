import { runInAction } from 'mobx'

import { config } from 'config'
import { http } from 'utils'

import BaseStore from './BaseStore'

let state
export class Bank extends BaseStore {
  constructor() {
    super()
    this.observable({
      menu_list: [],
      data_list: [],
    })

    state = this
  }

  async getBankList() {
    const url = `${config.api}/v1/public/account/bank`
    const res = await http.get(url)

    const list = res.body || []
    const menu = list.map((it) => {
      const { name, bank_code } = it
      return {
        name,
        value: bank_code,
        tag: it,
      }
    })

    runInAction(() => {
      state.data_list = list
      state.menu_list = menu
    })
  }
}

export default new Bank()
