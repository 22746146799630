/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#1F1F1F' } = props
  const width = size * 24
  const height = size * 21
  return (
    <svg width={width} height={height} viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_63_1115)">
        <path d="M13.6896 0C10.9556 0.00349649 8.33469 1.11097 6.40152 3.07952C4.46835 5.04807 3.38079 7.71699 3.37736 10.5009C3.37627 11.6608 3.56419 12.8128 3.93347 13.91C3.94538 13.9453 3.96157 13.9789 3.98167 14.0101L0.0888777 19.7258C0.0305377 19.809 0.000417671 19.9093 0.00309377 20.0115C0.00576988 20.1137 0.0410994 20.2122 0.103711 20.2921C0.164262 20.3716 0.248178 20.4294 0.34322 20.4569C0.438262 20.4844 0.53945 20.4802 0.632014 20.445L6.62136 18.1516C8.52625 19.9969 11.0605 21.0168 13.6896 20.9962C16.4233 20.9932 19.0443 19.8862 20.9775 17.9179C22.9107 15.9497 23.9983 13.2809 24.0018 10.4972C23.9973 7.71388 22.9093 5.04592 20.9763 3.07818C19.0432 1.11045 16.4228 0.00349423 13.6896 0ZM13.6896 20.0562C12.4574 20.066 11.2359 19.8239 10.0973 19.3444C8.95864 18.8648 7.92607 18.1575 7.06068 17.2644C6.97273 17.1745 6.85348 17.1236 6.72887 17.1228C6.67382 17.1235 6.61929 17.1337 6.5676 17.153L1.69234 19.0199L4.9975 14.1724C5.0324 14.1217 5.05708 14.0645 5.07011 14.004C5.08313 13.9435 5.08425 13.881 5.07339 13.8201C5.06252 13.7592 5.0399 13.701 5.00683 13.6491C4.97375 13.5971 4.93089 13.5524 4.88072 13.5174C4.84443 13.4907 4.80445 13.4697 4.76209 13.4551C4.45769 12.5011 4.30315 11.5041 4.30422 10.5009C4.30667 7.967 5.29627 5.53756 7.05583 3.7458C8.81539 1.95403 11.2012 0.946319 13.6896 0.94382C16.1778 0.946818 18.5633 1.95469 20.3227 3.74635C22.0822 5.53801 23.072 7.96716 23.0749 10.5009C23.0705 13.0337 22.0801 15.4614 20.3208 17.2519C18.5615 19.0423 16.1768 20.0494 13.6896 20.0524V20.0562Z" fill={color}/>
        <path d="M14.928 8.93604H12.0418C11.9809 8.93579 11.9205 8.94783 11.8642 8.97146C11.8078 8.99508 11.7566 9.02984 11.7136 9.07371C11.6705 9.11758 11.6364 9.1697 11.6132 9.22707C11.59 9.28443 11.5781 9.34591 11.5784 9.40795V17.1341C11.5784 17.2592 11.6272 17.3793 11.7141 17.4678C11.801 17.5563 11.9189 17.606 12.0418 17.606H14.928C15.0509 17.606 15.1688 17.5563 15.2557 17.4678C15.3426 17.3793 15.3915 17.2592 15.3915 17.1341V9.40795C15.3917 9.34591 15.3799 9.28443 15.3567 9.22707C15.3335 9.1697 15.2994 9.11758 15.2563 9.07371C15.2132 9.02984 15.162 8.99508 15.1057 8.97146C15.0493 8.94783 14.989 8.93579 14.928 8.93604ZM14.4646 16.6622H12.5052V9.87986H14.4646V16.6622Z" fill={color}/>
        <path d="M15.0021 3.44103C14.7645 3.24293 14.4904 3.09501 14.196 3.00588C13.9017 2.91676 13.5928 2.88822 13.2874 2.92193C12.9814 2.95237 12.6844 3.04469 12.4139 3.19346C12.1433 3.34223 11.9046 3.54448 11.7118 3.78835C11.517 4.03067 11.3715 4.30998 11.2837 4.61005C11.1959 4.91012 11.1675 5.22497 11.2002 5.53631C11.2605 6.12225 11.5315 6.66478 11.9609 7.05957C12.3904 7.45437 12.948 7.67356 13.5266 7.675C13.6063 7.675 13.686 7.675 13.7675 7.66179C14.3848 7.59609 14.9512 7.28372 15.3425 6.79323C15.7339 6.30273 15.9181 5.67419 15.8548 5.04552C15.8237 4.73401 15.7323 4.43186 15.586 4.15649C15.4396 3.88111 15.2412 3.63795 15.0021 3.44103ZM13.673 6.72175C13.4885 6.74136 13.302 6.72376 13.1241 6.66995C12.9463 6.61615 12.7806 6.5272 12.6365 6.40817C12.4924 6.28915 12.3728 6.14239 12.2844 5.97628C12.196 5.81016 12.1407 5.62795 12.1214 5.44004C12.1017 5.25174 12.1189 5.06132 12.172 4.87985C12.2252 4.69838 12.3132 4.52948 12.431 4.38296C12.5476 4.23597 12.6917 4.11404 12.8549 4.02428C13.0182 3.93451 13.1974 3.8787 13.382 3.86008C13.4314 3.85726 13.4809 3.85726 13.5303 3.86008C13.8537 3.8598 14.1673 3.97315 14.4182 4.18099C14.7103 4.41751 14.8983 4.76238 14.9409 5.1399C14.9596 5.32834 14.9417 5.51867 14.888 5.70002C14.8344 5.88136 14.7462 6.05016 14.6284 6.19674C14.5106 6.34332 14.3656 6.46481 14.2017 6.55425C14.0377 6.64369 13.8581 6.69932 13.673 6.71797V6.72175Z" fill={color}/>
      </g>
      <defs>
      <clipPath id="clip0_63_1115">
        <rect width="24" height="21" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  )
}

/* eslint-enable */
export default Logo
