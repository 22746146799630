const reduceByDimension = (original = {}, limit = {}) => {
  if (original.width <= limit.width && original.height <= limit.height) {
    return original
  }

  const ratioW = original.width / limit.width
  const ratioH = original.height / limit.height
  const ratio = ratioW > ratioH ? ratioW : ratioH

  const newSize = {
    width: Math.round(original.width / ratio) || 0,
    height: Math.round(original.height / ratio) || 0,
  }

  return newSize
}

const reduceBySize = ({ original, limit, dimension }) => {
  if (original <= limit) {
    return dimension
  }

  const offset = limit * 1.6
  const ratio = Math.round(original / offset)
  const newSize = {
    width: Math.floor(dimension.width / ratio),
    height: Math.floor(dimension.height / ratio),
  }

  return newSize
}

export const base64ToImage = (text) => {
  return new Promise((resolve) => {
    const img = new Image()
    img.src = text
    // img.crossOrigin = 'Anonymous'
    img.onload = () => {
      resolve(img)
    }
  })
}

export const compress = async ({ value, limit = 1000000 }) => {
  const img = await base64ToImage(value)
  const size = img.width * img.height
  // image size is good
  if (size < limit) {
    return { text: value, size }
  }

  const dimension = { width: img.width, height: img.height }
  const newSize = reduceBySize({ original: size, limit, dimension })
  if (newSize.width !== img.width || newSize.height !== img.height) {
    const elem = document.createElement('canvas')
    elem.width = newSize.width
    elem.height = newSize.height
    const ctx = elem.getContext('2d')
    ctx.drawImage(img, 0, 0, newSize.width, newSize.height)
    const text = ctx.canvas.toDataURL()
    return { text, size: newSize.width * newSize.height }
  } else {
    return { text: value, size }
  }
}

export const crop = async (image, { x, y, width, height }) => {
  // get crop image
  const img = await base64ToImage(image.value)
  const elem = document.createElement('canvas')
  elem.width = image.width
  elem.height = image.height
  const ctx = elem.getContext('2d')
  ctx.drawImage(img, 0, 0, image.width, image.height)
  const data = ctx.getImageData(x, y, width, height)

  // recreate image
  const cropElem = document.createElement('canvas')
  cropElem.width = width
  cropElem.height = height
  const ctxCropElem = cropElem.getContext('2d')
  ctxCropElem.putImageData(data, 0, 0)
  const base64 = cropElem.toDataURL()

  return base64
}

export const blobToBase64 = (data) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }

    reader.readAsDataURL(data)
  })
}

export const base64toBlob = (data, type) => {
  let byteString = atob(data.split(',')[1])
  let ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type })
}

export const base64toFile = (data, type) => {
  let byteString = atob(data.split(',')[1])
  let ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  let blob = new Blob([ab], { type })
  return new File([blob], 'newFile.jpeg', { type })
}

const file = {
  compress,
  crop,
  base64toFile,
  base64ToImage,
  blobToBase64,
  base64toBlob,
  reduceByDimension,
}

export { file }
