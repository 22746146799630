import { runInAction } from 'mobx'
import * as XLSX from 'xlsx'

import { http, format, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

import payroll from './index'

const Url = `${config.api}/v1/user-admin/payroll`
let state
export class Report extends BaseStore {
  constructor() {
    super()
    this.observable({
      payroll_user: {
        list: [],
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
      },
      calculation: {
        success: 0,
        error_list: [],
      },
    })
    state = this
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20, user_id } = query
    let q = `index=${index}&per_page=${per_page}`

    if (user_id) {
      q += `&user_id=${user_id}`
    }
    return q
  }

  async getUserList(query = {}) {
    const { payroll_id, index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}/payroll-user/${payroll_id}?${q}`
    const res = await http.get(url)

    const list = res.body || []

    const { total } = this.toJS().payroll_user.page
    runInAction(() => {
      state.payroll_user = {
        list,
        page: {
          index,
          per_page,
          total,
        },
      }
    })
  }

  async getUserCounter(query = {}) {
    const { payroll_id } = query
    const q = this.toQuery(query)
    const url = `${Url}/payroll-user/${payroll_id}/counter?${q}`
    const res = await http.get(url)

    const { counter } = res.body

    runInAction(() => {
      state.payroll_user.page.total = counter || 0
    })
  }

  async updatePayrollUser(query = {}, json = {}) {
    const { payroll_id, user_id } = query
    const url = `${Url}/payroll-user/${payroll_id}/${user_id}/detail`
    const resp = await http.put(url, { json })

    const res = resp.body
    const { payroll_user } = res

    const { list = [] } = this.toJS().payroll_user
    const index = list.findIndex((it) => it.user_id === user_id)
    payroll_user.payroll_id = payroll_id
    payroll_user.user_id = user_id
    if (index !== -1) {
      list[index] = payroll_user
    } else {
      list.unshift(payroll_user)
    }

    payroll.setPayroll(res.payroll)
    runInAction(() => {
      state.payroll_user.list = list
    })
  }

  async deletePayrollUser(query = {}) {
    const { payroll_user_id } = query
    const url = `${Url}/payroll-user/${payroll_user_id}`
    const resp = await http.delete(url)

    const res = resp.body

    const { list = [], page } = this.toJS().payroll_user
    const index = list.findIndex((it) => it.payroll_user_id === payroll_user_id)

    if (index !== -1) {
      list.splice(index, 1)
      page.total = page.total - 1
      runInAction(() => {
        state.payroll_user.list = list
        state.payroll_user.page = page
      })
    }

    payroll.setPayroll(res.payroll)
  }

  async calcDeduction(query = {}, json = {}) {
    const { payroll_id, user_id } = query
    const url = `${Url}/payroll-user/${payroll_id}/${user_id}/deduction`
    const resp = await http.put(url, { json })

    const res = resp.body

    return res
  }

  async calcPayrollUser(query = {}, json = {}) {
    const { payroll_id, user_id } = query
    const url = `${Url}/payroll-user/${payroll_id}/${user_id}/calculation`
    const resp = await http.put(url, { json })

    const res = resp.body

    return res
  }

  async calcPayroll(query = {}, user = {}) {
    try {
      const { payroll_id } = query
      const { user_id } = user

      const url = `${Url}/payroll-user/${payroll_id}/${user_id}/update-calculation`
      await http.put(url)
    } catch (e) {
      return { user, msg: e.message }
    }
  }

  async calcPayrollUserList(query = {}, userList = []) {
    const { payroll_id } = query

    let process_list = []
    let result_list = []
    for (const item of userList) {
      const r = this.calcPayroll({ payroll_id }, item)
      process_list.push(r)

      if (process_list.length > 2) {
        const temps = await Promise.all(process_list)
        result_list = result_list.concat(temps)
        process_list = []
      }
    }

    if (process_list.length > 0) {
      const temps = await Promise.all(process_list)
      result_list = result_list.concat(temps)
    }

    let success = 0
    const error_list = []

    for (const item of result_list) {
      if (item) error_list.push(item)
      else success++
    }
    runInAction(() => {
      state.calculation = {
        success,
        error_list,
      }
    })
  }

  async exportPayroll(query = {}) {
    const { payroll_id } = query

    const url = `${Url}/payroll-user/${payroll_id}?per_page=-1`
    const res = await http.get(url)

    const list = res.body || []

    const data_list = list.map((it) => {
      delete it.createdAt
      delete it.updatedAt

      delete it.payroll_user_id
      delete it.company_id
      delete it.user_id
      delete it.payroll_id

      delete it.created_type
      delete it.payslip_url
      delete it.payslip_th_url
      delete it.expense_list
      delete it.allowance_list
      delete it.other_income_list
      delete it.other_deduction_list

      delete it.note
      delete it.request_at
      delete it.reject_at
      delete it.approved_at

      it.paid_at = format.toExportDate(it.paid_at)
      it.start_paycycle_at = format.toExportDate(it.start_paycycle_at)
      it.end_paycycle_at = format.toExportDate(it.end_paycycle_at)

      return it
    })

    const today = timer.get()
    const date = today.format('DDMMYY-HHmm')

    const ws = XLSX.utils.json_to_sheet(data_list)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, ws, 'payroll')

    XLSX.writeFile(workbook, `payroll-${date}.xlsx`)

    return list
  }
}

export default new Report()
