import { runInAction } from 'mobx'

import BaseStore from './BaseStore'

let state
export class Menu extends BaseStore {
  constructor() {
    super()
    this.observable({
      openning: true,
    })

    state = this
  }

  toggleMenu() {
    const openning = this.openning !== true
    runInAction(() => {
      state.openning = openning
    })
  }
}

export default new Menu()
