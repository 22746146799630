import { initMenu } from 'utils/helper'

const AttendanceList = [
  { name: 'Work Hours', name_th: 'จำนวนเวลาทำงาน', value: 'work_minute' },
  { name: 'Working Time', name_th: 'ช่วงเวลาทำงาน', value: 'working_time' },
  { name: 'Work Non-Stop ', name_th: 'ไม่หยุดงาน', value: 'non_stop' },
]

const attendanceMenu = initMenu(AttendanceList)

export const AttendanceType = attendanceMenu.keys
export const AttendanceTypeMenu = {
  eng: attendanceMenu.menu,
  thai: attendanceMenu.menu_th,
}

const DurationList = [
  { name: 'Daily', name_th: 'รายวัน', value: 'daily' },
  { name: 'Monthly', name_th: 'รายเดือน', value: 'monthly' },
  { name: 'Yearly', name_th: 'รายปี', value: 'yearly' },
]

const durationMenu = initMenu(DurationList)

export const DurationType = durationMenu.keys
export const DurationTypeMenu = {
  eng: durationMenu.menu,
  thai: durationMenu.menu_th,
}

const PaymentList = [
  { name: 'This Month', name_th: 'เดือนนี้', value: 'current' },
  { name: 'Next Month', name_th: 'เดือนถัดไป', value: 'next_month' },
]

const paymentMenu = initMenu(PaymentList)

export const PaymentType = paymentMenu.keys
export const PaymentTypeMenu = {
  eng: paymentMenu.menu,
  thai: paymentMenu.menu_th,
}

// function
export const getAttendanceType = (value) => {
  const item = AttendanceType[value] || AttendanceType.work_minute
  return item
}

export const getDurationType = (value) => {
  const item = DurationType[value] || DurationType.daily
  return item
}

export const getPaymentType = (value) => {
  const item = PaymentType[value] || PaymentType.daily
  return item
}
