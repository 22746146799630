import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

const NoPermission = (props) => {
  const { lang } = props
  return (
    <PageView>
      <Title>{lang.getMessage('No Permission', 'ไม่มีสิทธิ์การเข้าถึง')}</Title>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 100px;
  text-align: center;
`

const Title = styled.h2`
  width: 100%;
`

export default inject('lang')(observer(NoPermission))
