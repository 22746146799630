import * as admin from 'firebase/app'
import { initializeAnalytics } from 'firebase/analytics'
import {
  getAuth,
  signInWithCustomToken,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth'

import { config } from 'config'

const app = admin.initializeApp(config.firebase, 'app')

// initializeAnalytics(app)

const auth = () => {
  return getAuth(app)
}

const onAuthStateChanged = () => {
  return new Promise((resolve) => {
    auth().onAuthStateChanged((user) => {
      resolve(user)
    })
  })
}

const signInWithToken = (token) => {
  return new Promise((resolve) => {
    signInWithCustomToken(auth(), token)
      .then((credential) => {
        const { user } = credential
        resolve({ user })
      })
      .catch((error) => {
        resolve({ error })
      })
  })
}

const fetchSignInForEmail = async (email) => {
  const authen = auth()
  return await fetchSignInMethodsForEmail(authen, email)
}

const signInWithEmail = async (email, password) => {
  const authen = auth()
  await signInWithEmailAndPassword(authen, email, password)

  return authen.currentUser
}

const logout = async () => {
  await auth().signOut()
}

const firebase = {
  auth,
  fetchSignInForEmail,
  signInWithEmail,
  onAuthStateChanged,
  signInWithToken,
  logout,
}

const click = (name, options = {}) => {
  // analytics.logEvent(name, options)
}

const exception = (options = {}) => {
  // analytics.logEvent('exception', { ...options, fatal: true })
}

const ga = {
  click,
  exception,
}

export { firebase, ga }
