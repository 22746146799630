import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const reimburse_url = `${config.api}/v1/user-admin/reimbursement/cost-center`
const original = {
  cost_center_name: '',
  approval_type: 'unused',
  approval_user_id: undefined,
}

let state
export class ReimburseType extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
      type_list: [],
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
      menu_list: [],
    })
    state = this
  }

  reset() {
    runInAction(() => {
      state.doc = cloneDeep(original)
    })
  }

  async getDoc(id) {
    const url = `${reimburse_url}/${id}/detail`
    const resp = await http.get(url)

    const doc = resp.body || {}
    runInAction(() => {
      state.doc = doc
    })
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20, category } = query

    let q = `index=${index}&per_page=${per_page}`

    if (category) q += `&category=${category}`

    return q
  }

  async getMenu() {
    const url = `${reimburse_url}/menu`
    const resp = await http.get(url)

    const list = resp.body || []

    const menu_list = list.map((it) => {
      const { cost_center_id, cost_center_name } = it
      const category_list = it.category_list || []
      const tag = category_list.map((cate) => {
        const { reimburse_category_id, name } = cate
        return {
          name,
          value: reimburse_category_id,
        }
      })
      return {
        name: cost_center_name,
        value: cost_center_id,
        tag,
      }
    })

    runInAction(() => {
      state.menu_list = menu_list
    })
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${reimburse_url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCountList(query) {
    const q = this.toQuery(query)
    const url = `${reimburse_url}/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async updateDoc(json = {}) {
    const { cost_center_id } = json

    let res
    if (cost_center_id) {
      const url = `${reimburse_url}/${cost_center_id}/detail`
      delete json.cost_center_id
      res = await http.put(url, { json })
    } else {
      const url = `${reimburse_url}`
      res = await http.post(url, { json })
    }

    const doc = res.body
    runInAction(() => {
      state.doc = doc
    })
    return doc.cost_center_id
  }

  async deleteDoc(cost_center_id) {
    const url = `${reimburse_url}/${cost_center_id}/delete`
    await http.put(url, {})

    const { list } = this.toJS()
    const i = list.findIndex((it) => it.cost_center_id === cost_center_id)
    if (i !== -1) {
      list.splice(i, 1)

      runInAction(() => {
        state.list = list
      })
    }
  }
}

export default new ReimburseType()
