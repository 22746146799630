import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/attendance/working-time`
let state
export class WorkingTime extends BaseStore {
  constructor() {
    super()
    this.observable({
      menu_list: [],
      data_list: [],
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
    })
    state = this
  }

  async getMenuList({ force = false } = {}) {
    if (force === false) {
      if (this.toJS().menu_list.length > 0) {
        return
      }
    }
    const url = `${Url}?status=active&per_page=-1`
    const resp = await http.get(url)

    const list = resp.body || []
    const menu_list = list.map((it) => {
      const { working_time_id } = it
      const n = it.name || ''
      const code = it.code || ''

      const name = code === '' ? n : `${n} (${code})`
      return { name, value: working_time_id, tag: it }
    })
    runInAction(() => {
      state.menu_list = menu_list
      state.data_list = list
    })
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20, status } = query

    let q = `index=${index}&per_page=${per_page}`

    if (status) q += `&status=${status}`

    return q
  }

  async getList(query = {}) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCountList(query = {}) {
    const q = this.toQuery(query)
    const url = `${Url}/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async updateWorkingTime(working_time_id, json = {}) {
    const url = working_time_id ? `${Url}/${working_time_id}/detail` : Url
    const res = working_time_id
      ? await http.put(url, { json })
      : await http.post(url, { json })

    const doc = res.body || {}
    const { list, page } = this.toJS()

    const i = list.findIndex((it) => it.working_time_id === doc.working_time_id)
    if (i !== -1) {
      list[i] = doc

      runInAction(() => {
        state.list = list
        state.menu_list = []
      })
    } else {
      list.unshift(doc)
      page.total++
      runInAction(() => {
        state.list = list
        state.page = page
        state.menu_list = []
      })
    }
  }

  async deleteWorkingTime(working_time_id) {
    const url = `${Url}/${working_time_id}`
    await http.delete(url, {})

    const { list, page } = this.toJS()
    const i = list.findIndex((it) => it.working_time_id === working_time_id)
    if (i !== -1) {
      list.splice(i, 1)
      page.total--
      runInAction(() => {
        state.list = list
        state.page = page
        state.menu_list = []
      })
    }
  }
}

export default new WorkingTime()
