import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = '#494949' } = props
  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5542 0H7.71248C6.3983 0 5.32917 1.00936 5.32917 2.25V3H3.69117C2.79309 3 2.0625 3.73059 2.0625 4.62867V22.3713C2.0625 23.2694 2.79309 24 3.69117 24H17.0422C17.9402 24 18.6708 23.2694 18.6708 22.3713V21H19.5542C20.8683 21 21.9375 19.9906 21.9375 18.75V2.25C21.9375 1.00936 20.8683 0 19.5542 0ZM17.1708 22.3713C17.1708 22.4423 17.1131 22.5 17.0422 22.5H3.69117C3.6202 22.5 3.5625 22.4423 3.5625 22.3713V4.62867C3.5625 4.5577 3.6202 4.5 3.69117 4.5H17.0422C17.1131 4.5 17.1708 4.5577 17.1708 4.62867V22.3713ZM20.4375 18.75C20.4375 19.1635 20.0412 19.5 19.5542 19.5H18.6708V4.62867C18.6708 3.73059 17.9402 3 17.0422 3H6.82917V2.25C6.82917 1.83647 7.22545 1.5 7.71248 1.5H19.5542C20.0412 1.5 20.4375 1.83647 20.4375 2.25V18.75Z"
        fill={color}
      />
      <path
        d="M14.0625 10.125H6.9375C6.52331 10.125 6.1875 9.78919 6.1875 9.375C6.1875 8.96081 6.52331 8.625 6.9375 8.625H14.0625C14.4767 8.625 14.8125 8.96081 14.8125 9.375C14.8125 9.78919 14.4767 10.125 14.0625 10.125Z"
        fill={color}
      />
      <path
        d="M14.0625 13.125H6.9375C6.52331 13.125 6.1875 12.7892 6.1875 12.375C6.1875 11.9608 6.52331 11.625 6.9375 11.625H14.0625C14.4767 11.625 14.8125 11.9608 14.8125 12.375C14.8125 12.7892 14.4767 13.125 14.0625 13.125Z"
        fill={color}
      />
      <path
        d="M14.0625 16.125H6.9375C6.52331 16.125 6.1875 15.7892 6.1875 15.375C6.1875 14.9608 6.52331 14.625 6.9375 14.625H14.0625C14.4767 14.625 14.8125 14.9608 14.8125 15.375C14.8125 15.7892 14.4767 16.125 14.0625 16.125Z"
        fill={color}
      />
    </svg>
  )
}

/* eslint-enable */
export default Logo
