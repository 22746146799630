import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/payroll/welfare-user`
let state
export class WelfareUser extends BaseStore {
  constructor() {
    super()
    this.observable({
      menu_list: [],
      menu_th_list: [],
      data_list: [],
      error_list: [],
      report: {
        list: [],
        sum: 0,
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
      },
    })
    state = this
  }

  async getMenuList({ force = false } = {}) {
    if (force === false) {
      if (this.toJS().menu_list.length > 0) {
        return
      }
    }
    const url = `${Url}?limit=-1`
    const res = await http.get(url)

    const list = res.body || []

    const menu_list = []
    const menu_th_list = []
    for (const it of list) {
      const { welfare_id, name, name_th } = it
      menu_list.push({ name, value: welfare_id, tag: it })
      menu_list.push({ name: name_th, value: welfare_id, tag: it })
    }

    runInAction(() => {
      state.menu_list = menu_list
      state.menu_th_list = menu_th_list
      state.data_list = list
    })
  }

  toQuery(query = {}) {
    const {
      index = 1,
      per_page = 20,
      welfare_id,
      user_id,
      start_date,
      end_date,
    } = query
    let q = `index=${index}&per_page=${per_page}`

    if (welfare_id) {
      q += `&welfare_id=${welfare_id}`
    }

    if (user_id) {
      q += `&user_id=${user_id}`
    }

    if (start_date && end_date) {
      const s = start_date.toISOString()
      const e = end_date.toISOString()
      q += `&start_at=${s}&end_at=${e}`
    }

    return q
  }

  async getList(query = {}) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { sum, page } = this.toJS().report
    const { total } = page
    runInAction(() => {
      state.report = {
        list,
        sum,
        page: {
          index,
          per_page,
          total,
        },
      }
    })
  }

  async getCounter(query = {}) {
    const q = this.toQuery(query)
    const url = `${Url}/counter?${q}`
    const resp = await http.get(url)

    const { counter, sum } = resp.body

    runInAction(() => {
      state.report.sum = sum || 0
      state.report.page.total = counter || 0
    })
  }

  async processWelfareUser({ welfare_id, user_id, start_at, end_at } = {}) {
    try {
      const url = `${Url}/calculate/${welfare_id}/${user_id}/${start_at}/${end_at}`
      await http.put(url)
      return undefined
    } catch (e) {
      return e.message
    }
  }

  async processWelfare({ welfare_id, start_at, end_at } = {}, userList = []) {
    const s = start_at.toISOString()
    const e = end_at.toISOString()

    const errorList = []
    for (const it of userList) {
      const { user_id } = it

      const msg = await this.processWelfareUser({
        welfare_id,
        user_id,
        start_at: s,
        end_at: e,
      })

      if (msg) {
        errorList.push({
          user: it,
          msg,
        })
      }
    }

    runInAction(() => {
      state.error_list = errorList
    })

    return errorList
  }

  async createWelfareUser(params = {}) {
    const {
      welfare_id,
      user_id,
      start_at,
      amount = 0,
      name,
      surname,
      nickname,
      welfare_name,
      welfare_name_th,
    } = params
    const s = start_at.toISOString()
    // const e = end_at.toISOString()
    const url = `${Url}/${welfare_id}/${user_id}/${s}/${s}`
    const res = await http.post(url, { json: { amount } })

    const data = res.body
    data.name = name
    data.surname = surname
    data.nickname = nickname
    data.welfare_name = welfare_name
    data.welfare_name_th = welfare_name_th

    const { welfare_user_id } = data

    const report = this.toJS().report
    const list = report.list || []

    const i = list.findIndex((it) => it.welfare_user_id === welfare_user_id)
    if (i !== -1) {
      list[i].amount = amount
    } else {
      list.unshift(data)
      report.total = report.total + 1
    }

    report.list = list
    runInAction(() => {
      state.report = report
    })
  }

  async updateWelfareUser({ welfare_user_id } = {}, { amount = 0 } = {}) {
    const url = `${Url}/${welfare_user_id}`
    await http.put(url, { json: { amount } })

    const report = this.toJS().report
    const list = report.list || []
    const i = list.findIndex((it) => it.welfare_user_id === welfare_user_id)
    if (i !== -1) {
      list[i].amount = amount
      report.list = list

      runInAction(() => {
        state.report = report
      })
    }
  }

  async removeWelfareUser({ welfare_user_id } = {}) {
    const url = `${Url}/${welfare_user_id}`
    await http.delete(url)

    const report = this.toJS().report
    const list = report.list || []
    const i = list.findIndex((it) => it.welfare_user_id === welfare_user_id)
    if (i !== -1) {
      list.splice(i, 1)
      report.list = list
      report.total = report.total - 1

      runInAction(() => {
        state.report = report
      })
    }
  }
}

export default new WelfareUser()
