import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'
import * as XLSX from 'xlsx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const month_name = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

const getNumMonth = (leave, month_list = []) => {
  let counter = 0
  if (leave.leave_type_id) {
    month_list.forEach((item) => {
      if (item.leave_type_id === leave.leave_type_id) {
        counter += item.day_counter
      }
    })
  } else {
    month_list.forEach((item) => {
      if (item.leave_category === leave.value) {
        counter += item.day_counter
      }
    })
  }
  return counter
}

const getCounteLeave = (leave, user) => {
  let total = 0
  if (user.summary) {
    month_name.forEach((name) => {
      const item = user.summary[name] || { leave: [] }
      total += getNumMonth(leave, item.leave)
    })
  }

  return total
}

let state
export class LeaveBalanceReport extends BaseStore {
  constructor() {
    super()
    this.observable({
      summary: {
        filter: {
          category: [],
          user_id: undefined,
          year: timer.get().year(),
          month: undefined,
        },
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },

        header: [],
        source_list: [],
        data_list: [],
        key: undefined,
        leave_list: [],
      },
    })

    state = this
  }

  setSummaryReportFilter(filter) {
    const summary = this.toJS().summary
    const header = []
    filter.category.forEach((cate) => {
      if (cate.checked) {
        // category
        header.push(cate)
      }

      // leave type
      cate.list.forEach((item) => {
        if (item.checked) {
          header.push(item)
        }
      })
    })

    summary.header = header
    summary.filter = filter
    runInAction(() => {
      state.summary = summary
    })
  }

  async getSummartList(year, query) {
    const url = `${config.api}/v1/user-admin/leave-request/summary/user/${year}${query}`

    const res = await http.get(url)
    const list = res.body || []

    return list
  }

  calcSummaryReport({ month, leave_list = [], user_list = [] }) {
    const resp_list = user_list.map((it) => {
      const newDoc = cloneDeep(it)
      const s = leave_list.find((s) => s.user_id === it.user_id) || {}

      const summary = cloneDeep(s)
      newDoc.summary = summary
      month_name.forEach((n, i) => {
        const m = summary[n] || { leave: [] }
        const isNotFilter = month > 0 ? i + 1 === month : true
        newDoc.summary[n] = isNotFilter ? m : { leave: [] }
      })

      return newDoc
    })

    return resp_list
  }

  getDate(year, month, isStart) {
    const date = timer.get(`01/01/${year}`, 'DD/MM/YYYY')

    let dateType = 'year'
    if (month) {
      date.set('month', month)
      dateType = 'month'
    }

    return isStart ? date.startOf(dateType) : date.endOf(dateType)
  }

  async getSummartReport({ index = 1, per_page = 20, employee_list = [] }) {
    const summary = this.toJS().summary
    const { filter, page } = summary

    const { user_id, year, month } = filter

    let userIds = []
    let user_list = []
    if (user_id) {
      const user = employee_list.find((it) => it.user_id === user_id)
      user_list = [user]
      userIds = [user_id]
    } else {
      const i = index - 1
      user_list = employee_list.slice(i, i + 20)
      userIds = user_list.map((it) => it.user_id)
    }
    const ids = userIds.join()
    const query = `?user_id=${ids}`

    const key = `${year}-${query}`
    const leave_list =
      summary.key === key
        ? summary.leave_list
        : await this.getSummartList(year, query)

    const data_list = this.calcSummaryReport({
      month,
      leave_list,
      user_list,
    })

    summary.page.index = index
    summary.data_list = data_list
    summary.key = key
    summary.leave_list = leave_list

    page.index = index
    page.per_page = per_page
    page.total = employee_list.length

    runInAction(() => {
      state.summary = summary
    })
  }

  async export(employee_list = [], { isThai } = {}) {
    const { filter, header } = this.toJS().summary
    const { user_id, year, month } = filter
    const userIds = user_id ? [user_id] : employee_list.map((it) => it.user_id)
    const ids = userIds.join()
    const query = `?user_id=${ids}`
    const leave_list = await this.getSummartList(year, query)

    const user_list = user_id
      ? employee_list.filter((it) => it.user_id === user_id)
      : employee_list
    const data_list = this.calcSummaryReport({
      month,
      leave_list,
      user_list,
    })

    const list = []
    const width = 16
    const sizeList = [{ width: 20 }]

    const headerList = header.map((item) => {
      const name = item.leave_type_id
        ? item.name
        : isThai
        ? item.name_th
        : item.name

      sizeList.push({ width })

      return name
    })

    for (const user of data_list) {
      const contentList = header.map((leave) => {
        const num = getCounteLeave(leave, user)
        return num > 0 ? num : ''
      })

      const { name, name_th, surname, surname_th } = user
      const fullname = isThai
        ? `${name_th || ''} ${surname_th || ''}`
        : `${name || ''} ${surname || ''}`

      contentList.unshift(fullname)

      list.push(contentList)
    }

    headerList.unshift('Employee')
    list.unshift(headerList)

    const today = timer.get()
    const date = today.format('DDMMHHmm')

    const ws = XLSX.utils.aoa_to_sheet(list)
    ws['!cols'] = sizeList

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, ws, 'leave-balance')

    XLSX.writeFile(workbook, `leave-balance-${date}.xlsx`)
  }
}

export default new LeaveBalanceReport()
