import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'
import * as XLSX from 'xlsx'

import { http, format, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const getColumnSize = (size = 0) => {
  const len = size
  const width = 20

  const list = []
  for (let i = 0; i < len; i++) {
    if (i === 0) list.push({ width: 25 })
    else list.push({ width })
  }

  return list
}

const original = {
  user_id: undefined,
  company_id: undefined,
  status: 'none',

  start_date: undefined,
  end_date: undefined,

  created_at: undefined,
  updated_at: undefined,
}

const Url = `${config.api}/v1/user-admin/attendance`
let state
export class Attendance extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
      doc: cloneDeep(original),
    })
    state = this
  }

  toQuery(query = {}) {
    const {
      index = 1,
      per_page = 20,
      user_id,
      status,
      office_id,
      working_time_id,
      start_date,
      end_date,
    } = query

    let q = `index=${index}&per_page=${per_page}`

    if (status) q += `&status=${status}`
    if (user_id) q += `&user_id=${user_id}`
    if (office_id) q += `&office_id=${office_id}`
    if (working_time_id) q += `&working_time_id=${working_time_id}`
    if (start_date) q += `&start_date=${start_date.toISOString()}`
    if (end_date) q += `&end_date=${end_date.toISOString()}`

    return q
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}/report?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCountList(query) {
    const q = this.toQuery(query)
    const url = `${Url}/report/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async getExportList(query) {
    const q = this.toQuery({ ...query, per_page: -1 })
    const url = `${Url}/report?${q}`
    const resp = await http.get(url)

    const attendanceList = resp.body || []
    const list = attendanceList.map((it) => {
      const employee = `${it.name || ''} ${it.surname || ''}`
      const nickname = it.nickname || ''

      const checkin_office_name = it.checkin_office_name || ''
      const checkout_office_name = it.checkout_office_name || ''

      const working_time_name = it.working_time_name || ''
      const work_at = format.toExportDay(it.work_at)
      const work_start_at = format.toExportDate(it.work_start_at)
      const work_end_at = format.toExportDate(it.work_end_at)

      const checkin_at = format.toExportDate(it.checkin_at)
      const checkout_at = format.toExportDate(it.checkout_at)
      // it.checkin_break_at = format.toExportDate(it.checkin_break_at)
      // it.checkout_break_at = format.toExportDate(it.checkout_break_at)

      return [
        employee,
        nickname,
        work_at,
        work_start_at,
        work_end_at,
        working_time_name,

        checkin_office_name,
        checkout_office_name,
        checkin_at,
        checkout_at,
      ]
    })

    const today = timer.get()
    const date = today.format('DDMMYY-HHmm')

    const headers = [
      'employee',
      'nickname',

      'work date',
      'start work',
      'end work',
      'working time',

      'office in',
      'office out',
      'checkin',
      'checkout',
    ]

    list.unshift(headers)

    const ws = XLSX.utils.aoa_to_sheet(list)
    ws['!cols'] = getColumnSize(headers.length)

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, ws, 'attendance')

    XLSX.writeFile(workbook, `attendance-${date}.xlsx`)

    return list
  }

  updateInList(id, doc) {
    const { list = [] } = this.toJS()
    const i = list.findIndex((it) => it.attendance_user_id === id)
    if (i !== -1) {
      list[i] = doc
      runInAction(() => {
        state.list = list
      })
    }
  }

  async updateAttendance(json = {}) {
    const { user_id, working_time_id } = json

    const url = `${Url}/request/check/user-id/${user_id}/${working_time_id}`
    const res = await http.put(url, { json })

    const newDoc = res.body
    const { attendance_user_id } = newDoc
    this.updateInList(attendance_user_id, newDoc)
  }

  setDoc(doc) {
    runInAction(() => {
      state.doc = doc
    })
  }
}

export default new Attendance()
