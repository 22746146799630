import { initMenu } from 'utils/helper'

// No/Yes List
const NoYesList = [
  { name: 'No', name_th: 'ไม่ใช่', value: 0 },
  { name: 'Yes', name_th: 'ใช่', value: 1 },
]
const noYes = initMenu(NoYesList)

export const NoYes = noYes.keys
export const NoYesMenu = {
  eng: noYes.menu,
  thai: noYes.menu_th,
}

// No/Yes Text List
const NoYesTextList = [
  { name: 'No', name_th: 'ไม่ใช่', value: 'no' },
  { name: 'Yes', name_th: 'ใช่', value: 'yes' },
]
const noYesText = initMenu(NoYesTextList)

export const NoYesText = noYesText.keys
export const NoYesTextMenu = {
  eng: noYesText.menu,
  thai: noYesText.menu_th,
}

// No/Yes Text List
const NoYesBoolList = [
  { name: 'No', name_th: 'ไม่ใช่', value: false },
  { name: 'Yes', name_th: 'ใช่', value: true },
]
const noYesBool = initMenu(NoYesBoolList)

export const NoYesBool = noYesBool.keys
export const NoYesBoolMenu = {
  eng: noYesBool.menu,
  thai: noYesBool.menu_th,
}

// No/Yes List
const EnableList = [
  { name: 'Disable', name_th: 'ปิดใช้งาน', value: false },
  { name: 'Enable', name_th: 'เปิดใช้งาน', value: true },
]
const enable = initMenu(EnableList)

export const Enable = enable.keys
export const EnableMenu = {
  eng: enable.menu,
  thai: enable.menu_th,
}

// Approval Type List
const ApprovalTypeList = [
  { name: 'Employee', name_th: 'ระบุคน', value: 'user' },
  { name: 'Manager', name_th: 'หัวหน้างาน', value: 'manager' },
]
const approvalType = initMenu(ApprovalTypeList)

export const ApprovalType = approvalType.keys
export const ApprovalTypeMenu = {
  eng: approvalType.menu,
  thai: approvalType.menu_th,
}

// Status List
const StatusList = [
  {
    name: 'Disable',
    name_th: 'ปิดใช้งาน',
    color: 'white',
    bg: '#8A8A8A',
    value: 'inactive',
  },
  {
    name: 'Enable',
    name_th: 'เปิดใช้งาน',
    color: 'white',
    bg: '#F36273',
    value: 'active',
  },
]
const status = initMenu(StatusList)

export const Status = status.keys
export const StatusMenu = {
  eng: status.menu,
  thai: status.menu_th,
}

// Math Type List
const MathTypeList = [
  { name: 'Decimal Point', name_th: 'จุดทศนิยม', value: 'none' },
  { name: 'Ceil Up', name_th: 'ปัดขึ้นเสมอ', value: 'ceil' },
  { name: 'Round Up', name_th: 'ปัดขึ้น (0.5)', value: 'round' },
  { name: 'Floor Down', name_th: 'ปัดลงเสมอ', value: 'floor' },
]
const mathType = initMenu(MathTypeList)

export const MathType = mathType.keys
export const MathTypeMenu = {
  eng: mathType.menu,
  thai: mathType.menu_th,
}

// function
export const getNoYes = (value) => {
  const item = NoYes[value] || NoYes[0]
  return item
}

export const getNoYesText = (value) => {
  const item = NoYesText[value] || NoYesText.no
  return item
}

export const getNoYesBool = (value) => {
  const item = NoYesBool[value] || NoYesBool[false]
  return item
}

export const getApprovalType = (value) => {
  const item = ApprovalType[value] || ApprovalType.active
  return item
}

export const getStatus = (value) => {
  const item = Status[value] || Status.active
  return item
}

export const getMathType = (value) => {
  const item = MathType[value] || MathType.none
  return item
}
