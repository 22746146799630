import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

let state
export class DocumentRequest extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
    })
    state = this
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20, user_id, status, category } = query

    let q = `index=${index}&per_page=${per_page}`

    if (status) q += `&status=${status}`
    if (user_id) q += `&user_id=${user_id}`
    if (category) q += `&category=${category}`

    return q
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${config.api}/v1/user-admin/leave-request/leave?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCountList(query) {
    const q = this.toQuery(query)
    const url = `${config.api}/v1/user-admin/leave-request/leave/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }
}

export default new DocumentRequest()
