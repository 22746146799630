import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/payroll`
let state
export class Tvi50Report extends BaseStore {
  constructor() {
    super()
    this.observable({
      report: {
        list: [],
        total: 0,
        page: {
          index: 1,
          per_page: 20,
        },
      },
      info: {},
      employee_menu: {
        en: [],
        th: [],
      },
      tvi50_employee: {
        list: [],
        total: 0,
        page: {
          index: 1,
          per_page: 20,
        },
      },
      calculation: {
        success: 0,
        error_list: [],
      },
    })
    state = this
  }

  async create(json) {
    const { payroll_year } = json
    const url = `${Url}/request/${payroll_year}/tvi50`
    const res = await http.post(url, { json })

    const doc = res.body || {}

    return doc
  }

  async update({ tvi50_id } = {}, json) {
    const url = `${Url}/request/${tvi50_id}/tvi50`
    const res = await http.put(url, { json })

    const doc = res.body || {}
    runInAction(() => {
      state.info = doc
    })
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20, date } = query
    let q = `index=${index}&per_page=${per_page}`

    if (date) {
      const year = date.year()
      q += `&payroll_year=${year}`
    }

    return q
  }

  async getReportList(query = {}) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}/tvi50?${q}`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.report.list = list
      state.report.page = {
        index,
        per_page,
      }
    })
  }

  async getReportCounter(query = {}) {
    const q = this.toQuery(query)
    const url = `${Url}/tvi50/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.report.total = counter || 0
    })
  }

  async getInfo(query = {}) {
    const { tvi50_id } = query
    const url = `${Url}/tvi50/${tvi50_id}/information`
    const res = await http.get(url)

    const doc = res.body || {}

    runInAction(() => {
      state.info = doc
    })

    return doc
  }

  setInfo(doc) {
    runInAction(() => {
      state.info = doc
    })
  }

  async getEmployeeMenu(query = {}) {
    const { payroll_year } = query
    const url = `${Url}/tvi50/${payroll_year}/employee-name`
    const res = await http.get(url)

    const list = res.body || []

    const enList = []
    const thList = []
    for (const item of list) {
      const { user_id, name, surname, name_th, surname_th } = item
      enList.push({
        name: `${name || ''} ${surname || ''}`,
        value: user_id,
      })

      thList.push({
        name: `${name_th || ''} ${surname_th || ''}`,
        value: user_id,
      })
    }

    runInAction(() => {
      state.employee_menu = {
        en: enList,
        th: thList,
      }

      state.employee_list = list
    })
  }

  async getEmployeeList(query = {}) {
    const { tvi50_id, index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}/tvi50/${tvi50_id}/employee?${q}`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.tvi50_employee.list = list
      state.tvi50_employee.page = {
        index,
        per_page,
      }
    })
  }

  async getEmployeeCounter(query = {}) {
    const { tvi50_id } = query
    const q = this.toQuery(query)
    const url = `${Url}/tvi50/${tvi50_id}/employee/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.tvi50_employee.total = counter || 0
    })
  }

  setInformation(info, tvi50, tvi50_employee) {
    info.total = tvi50.total
    info.total_wht = tvi50.total_wht
    info.total_ssf = tvi50.total_ssf
    info.total_provident_fund = tvi50.total_provident_fund

    runInAction(() => {
      state.info = info
      state.tvi50_employee = tvi50_employee
    })
  }

  async createTvi50Employee(json) {
    const { tvi50_id, user_id } = json
    const url = `${Url}/tvi50-user/${tvi50_id}/${user_id}`
    const res = await http.post(url, {})

    const { tvi50, tvi50_user } = res.body || {}
    const { tvi50_user_id } = tvi50_user

    const { info, tvi50_employee } = this.toJS()
    const i = tvi50_employee.list.findIndex(
      (it) => it.tvi50_user_id === tvi50_user_id
    )

    if (i !== -1) {
      tvi50_employee.list[i] = tvi50_user
    } else {
      tvi50_employee.list.unshift(tvi50_user)
      tvi50_employee.total++
    }

    this.setInformation(info, tvi50, tvi50_employee)
    return tvi50_user
  }

  async updateTvi50Employee({ tvi50_user_id } = {}, json) {
    const url = `${Url}/tvi50-user/${tvi50_user_id}/detail`
    const res = await http.put(url, { json })

    const { tvi50, tvi50_user } = res.body || {}
    const { info, tvi50_employee } = this.toJS()
    const i = tvi50_employee.list.findIndex(
      (it) => it.tvi50_user_id === tvi50_user_id
    )

    if (i !== -1) {
      const current = tvi50_employee.list[i]
      tvi50_user.name = current.name
      tvi50_user.surname = current.surname
      tvi50_user.name_th = current.name_th
      tvi50_user.surname_th = current.surname_th
      tvi50_user.nickname = current.nickname

      tvi50_employee.list[i] = tvi50_user
    }

    this.setInformation(info, tvi50, tvi50_employee)
  }

  async removeTvi50Employee({ tvi50_user_id }) {
    const url = `${Url}/tvi50-user/${tvi50_user_id}`
    const res = await http.delete(url)

    const { tvi50 } = res.body || {}

    const { info, tvi50_employee } = this.toJS()
    const i = tvi50_employee.list.findIndex(
      (it) => it.tvi50_user_id === tvi50_user_id
    )

    if (i !== -1) {
      tvi50_employee.list.splice(i, 1)
      tvi50_employee.total--
    }

    this.setInformation(info, tvi50, tvi50_employee)
  }

  async resetTvi50Employee({ tvi50_id, user_id }) {
    const url = `${Url}/tvi50-user/${tvi50_id}/${user_id}/calculation`
    const res = await http.get(url)

    return res.body || {}
  }

  async getFileTvi50Employee({ tvi50_id, tvi50_user_id }) {
    const url = `${Url}/tvi50-user/${tvi50_id}/${tvi50_user_id}/file`
    const res = await http.get(url)

    return res.body || {}
  }

  async calcTvi50Summary({ tvi50_id }) {
    try {
      const url = `${Url}/tvi50/${tvi50_id}/calculation`
      const res = await http.put(url)

      runInAction(() => {
        state.info = res.body || {}
        state.tvi50_employee = {
          list: [],
          total: 0,
          page: {
            index: 1,
            per_page: 20,
          },
        }
      })
    } catch (e) {
      return { msg: e.message }
    }
  }

  async calcTvi50Employee({ tvi50_id, payroll_year }, user) {
    try {
      const { user_id } = user
      const url = `${Url}/tvi50-user/${tvi50_id}/${user_id}/${payroll_year}/calculation`
      await http.put(url)

      return { user }
    } catch (e) {
      return { user, msg: e.message }
    }
  }

  async calcTviUserList({ tvi50_id, payroll_year }, userList = []) {
    let processList = []
    let resultList = []
    let success = 0

    const error_list = []
    for (const user of userList) {
      processList.push(this.calcTvi50Employee({ tvi50_id, payroll_year }, user))

      if (processList.length >= 5) {
        const list = await Promise.all(processList)
        processList = []
        resultList = resultList.concat(list)
      }
    }

    if (processList.length >= 0) {
      const list = await Promise.all(processList)
      resultList = resultList.concat(list)
    }

    for (const result of resultList) {
      if (result.msg) {
        error_list.push(result)
      } else {
        success++
      }
    }

    const res = await this.calcTvi50Summary({ tvi50_id })
    if (res) {
      error_list.push(res)
    }

    runInAction(() => {
      state.calculation = {
        success,
        error_list,
      }
    })
  }
}

export default new Tvi50Report()
