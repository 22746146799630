const config = {
  mode: 'localhost',
  firebase: {
    apiKey: 'AIzaSyDigCO_mjybeebbChrBjuJJwg4fAxGBcTk',
    authDomain: 'hlo-testing.firebaseapp.com',
    databaseURL: 'https://hlo-testing.firebaseio.com',
    projectId: 'hlo-testing',
    storageBucket: 'hlo-testing.appspot.com',
    messagingSenderId: '185162095130',
    appId: '1:185162095130:web:353045b744df53d752cfa3',
  },
  api: 'https://api-staging.hlo.app/api',
  host: 'http://localhost:3120',
  admin_host: 'http://localhost:3100',
  tcp: 'ws://localhost:8100',
  image: {
    width: 1000,
    height: 1000,
    size: 1000000, // 1mb
    default: '/hlo.png',
  },
}

module.exports = config
