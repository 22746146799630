export const save = (cname, cvalue) => {
  const message = JSON.stringify(cvalue)
  localStorage.setItem(cname, message)
}

export const load = (cname) => {
  const temp = localStorage.getItem(cname)
  if (temp !== undefined && temp !== '') {
    return JSON.parse(temp)
  } else {
    return undefined
  }
}

export const remove = (cname) => {
  localStorage.removeItem(cname)
}
