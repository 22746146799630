import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const Url = `${config.api}/v1/user-admin/account/company`
let state
export class Company extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: {},
      admin_list: [],
    })
    state = this
  }

  async getDoc() {
    const url = `${Url}/detail`
    const resp = await http.get(url)

    const data = resp.body

    runInAction(() => {
      state.doc = data
    })
  }

  async updateCompany(json = {}) {
    const url = `${Url}/detail`
    const resp = await http.put(url, { json })

    return resp.body
  }

  async getAdminList() {
    const url = `${Url}/admin`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.admin_list = list
    })
  }

  async createAdmin(json = {}) {
    const url = `${Url}/admin`
    const resp = await http.post(url, { json })

    const data = resp.body

    const list = this.toJS().admin_list
    const i = list.findIndex((it) => it.user_id === data.user_id)
    if (i !== -1) {
      list[i] = data
    } else {
      list.push(data)
    }

    runInAction(() => {
      state.admin_list = list
    })
  }

  async deleteAdmin(user_id) {
    const url = `${Url}/admin/${user_id}`
    await http.put(url)

    const list = this.toJS().admin_list
    const i = list.findIndex((it) => it.user_id === user_id)
    if (i !== -1) {
      list.splice(i, 1)

      runInAction(() => {
        state.admin_list = list
      })
    }
  }

  setCompany(data) {
    runInAction(() => {
      state.doc = data
    })
  }
}

export default new Company()
