import { runInAction } from 'mobx'

import { helper, http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/account/privacy`
let state
export class EmployeeDocument extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: { file_list: [] },
      privacy: {},
      bank: {},
    })
    state = this
  }

  async getPrivacy(user_id) {
    const url = `${Url}/${user_id}`
    const resp = await http.get(url)

    const { doc, privacy, bank } = resp.body

    runInAction(() => {
      state.doc = doc
      state.privacy = privacy
      state.bank = bank
    })
  }

  async updateDocument({ user_id, code }, json = {}) {
    const c = code ? code : helper.random({ len: 5 })
    const url = `${Url}/${user_id}/document/${c}`
    const res = await http.put(url, { json })

    const doc = res.body

    const { file_list = [] } = this.toJS().doc
    const i = file_list.findIndex((it) => it.code === doc.code)

    if (i === -1) {
      file_list.push(doc)
    } else {
      file_list[i] = doc
    }

    runInAction(() => {
      state.doc = { file_list }
    })
  }

  async deleteDocument({ user_id, code }) {
    const url = `${Url}/${user_id}/document/${code}/delete`
    await http.put(url)

    const { file_list = [] } = this.toJS().doc
    const i = file_list.findIndex((it) => it.code === code)

    if (i !== -1) {
      file_list.splice(i, 1)
      runInAction(() => {
        state.doc = { file_list }
      })
    }
  }

  async updateBank({ user_id }, json = {}) {
    const url = `${Url}/${user_id}/bank`
    const res = await http.put(url, { json })

    runInAction(() => {
      state.bank = res.body
    })
  }

  async updatePrivacy({ user_id }, json = {}) {
    const url = `${Url}/${user_id}/privacy`
    const res = await http.put(url, { json })

    runInAction(() => {
      state.privacy = res.body
    })
  }
}

export default new EmployeeDocument()
