import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/account/general`
let state
export class EmployeeGeneral extends BaseStore {
  constructor() {
    super()
    this.observable({
      emergency: {},
      address: {},
    })
    state = this
  }

  async getGeneral(user_id) {
    const url = `${Url}/${user_id}`
    const resp = await http.get(url)

    const { address, emergency } = resp.body || {}

    runInAction(() => {
      state.address = address || {}
      state.emergency = emergency || {}
    })
  }

  async updateEmergency(user_id, json = {}) {
    const url = `${Url}/${user_id}/emergency`
    await http.put(url, { json })

    runInAction(() => {
      state.emergency = json
    })
  }

  async updateAddress(user_id, json = {}) {
    const url = `${Url}/${user_id}/address`
    await http.put(url, { json })

    runInAction(() => {
      state.address = json
    })
  }
}

export default new EmployeeGeneral()
