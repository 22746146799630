import { runInAction } from 'mobx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Mapping = [
  { column: 'user id', name: 'user_id', type: 'int' },
  { column: 'status', name: 'status', lowercase: true },
  { column: 'date', name: 'date', lowercase: true },
  { column: 'category id', name: 'reimburse_category_id', type: 'int' },
  { column: 'balance', name: 'balance', type: 'int' },
]

const toBalanceItem = (item = {}, { employee_list, category_list }) => {
  const data = {}

  for (const ob of Mapping) {
    const { column, name } = ob

    let val = `${item[column] || ''}`.trim()
    if (ob.type === 'int') {
      val = +val || 0
    } else if (ob.lowercase) {
      val = val.toLocaleLowerCase()
    }
    data[name] = val
  }

  const { user_id, reimburse_category_id } = data
  const employee = employee_list.find((it) => it.user_id === user_id)
  if (!employee) {
    data.message = 'Employee not found'
    data.message_th = 'ไม่พบพนักงานในระบบ'
    return data
  }

  const category = category_list.find(
    (it) => it.reimburse_category_id === reimburse_category_id
  )
  if (!category) {
    data.message = 'Category not found'
    data.message_th = 'ไม่พบหมวดหมู่ในระบบ'
    return data
  }

  const { balance_type } = category
  data.name = employee.name
  data.surname = employee.surname
  data.name_th = employee.name_th
  data.surname_th = employee.surname_th
  data.nickname = employee.nickname
  data.img_url = employee.img_url

  data.cost_center_name = category.cost_center_name
  data.category_name = category.name

  data.balance_type = balance_type

  const date = `${item.date || ''}`
  data.date = date

  const format = balance_type === 'per_year' ? 'YYYY' : 'MM/YYYY'
  const balance_date = timer.validate(date, format)
  if (!balance_date.isValid()) {
    data.message = 'Date format is invalid'
    data.message_th = 'รูปแบบวันที่ไม่ถูกต้อง'
  }
  data.balance_date = balance_date

  return data
}

const Url = `${config.api}/v1/user-admin/reimbursement/balance`
let state
export class ImportBalance extends BaseStore {
  constructor() {
    super()
    this.observable({
      result: {
        success: 0,
        error: 0,
      },
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
    })

    state = this
  }

  setPagination(page = {}) {
    runInAction(() => {
      state.page = page
    })
  }

  reset() {
    runInAction(() => {
      state.result = { success: 0, error: 0 }
      state.list = []
      state.page = {
        index: 1,
        per_page: 20,
        total: 0,
      }
    })
  }

  async updateBalance(data = {}) {
    try {
      const { status, user_id, reimburse_category_id, balance, balance_date } =
        data

      const json = {
        status,
        option_amount: balance,
        option_type: 'manual',
      }
      const txt = balance_date.toISOString()
      const url = `${Url}/${user_id}/${reimburse_category_id}/${txt}`
      await http.put(url, { json })
    } catch (e) {
      data.message = e.message
      data.message_th = e.message
    }

    return data
  }

  async processBalanceList(list = []) {
    let result_list = []
    let process_list = []
    for (const item of list) {
      const { message } = item
      if (!message) {
        const r = this.updateBalance(item)
        process_list.push(r)
      } else {
        result_list.push(item)
      }

      if (process_list.length > 4) {
        const temps = await Promise.all(process_list)
        result_list = result_list.concat(temps)
        process_list = []
      }
    }

    if (process_list.length !== 0) {
      const temps = await Promise.all(process_list)
      result_list = result_list.concat(temps)
    }

    const result = { success: 0, error: 0 }
    for (const item of result_list) {
      const { message } = item
      if (!message) result.success++
      else result.error++
    }

    runInAction(() => {
      state.result = result
      state.list = result_list
    })

    return result
  }

  loadFile(lines, { employee_list, category_list } = {}) {
    const list = []
    for (const item of lines) {
      const balance = toBalanceItem(item, { employee_list, category_list })

      list.push(balance)
    }

    runInAction(() => {
      state.list = list
      state.page = {
        index: 1,
        per_page: 20,
        total: list.length,
      }
    })
  }
}

export default new ImportBalance()
