import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/leave-request/leave-type`
let state
export class LeaveReqType extends BaseStore {
  constructor() {
    super()
    this.observable({
      type_list: [],
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
    })
    state = this
  }

  async getTypeList({ force = false } = {}) {
    if (force === false) {
      if (this.toJS().type_list.length > 0) {
        return
      }
    }
    const url = `${config.api}/v1/user-admin/leave-request/leave-type`
    const resp = await http.get(url)

    const list = resp.body || []
    runInAction(() => {
      state.type_list = list
    })
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20, category } = query

    let q = `index=${index}&per_page=${per_page}`

    if (category) q += `&category=${category}`

    return q
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCountList(query) {
    const q = this.toQuery(query)
    const url = `${Url}/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async createLeaveReqType(json) {
    const url = `${Url}`
    const res = await http.post(url, { json })

    const { list, page } = this.toJS()

    list.unshift(res.body)
    list.pop()
    page.total++
    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async updateLeaveReqType(leave_type_id, json = {}) {
    const url = `${Url}/${leave_type_id}/detail`
    await http.put(url, { json })

    const { list } = this.toJS()

    const i = list.findIndex((it) => it.leave_type_id === leave_type_id)
    if (i !== -1) {
      json.leave_type_id = leave_type_id
      list[i] = json

      runInAction(() => {
        state.list = list
      })
    }
  }

  async deleteLeaveReq(leave_type_id) {
    const url = `${Url}/${leave_type_id}/delete`
    await http.put(url, {})

    const { list } = this.toJS()
    const i = list.findIndex((it) => it.leave_type_id === leave_type_id)
    if (i !== -1) {
      list.splice(i, 1)

      runInAction(() => {
        state.list = list
      })
    }
  }
}

export default new LeaveReqType()
