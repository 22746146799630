import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'
import * as XLSX from 'xlsx'

import { http, format, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const original = {
  user_id: undefined,
  company_id: undefined,
  status: 'draft',
  leave_type_id: undefined,
  leave_name: '',
  leave_category: undefined,
  leave_type: 'one',
  start_date: undefined,
  start_type: 'full',
  end_date: undefined,
  end_type: 'full',
  reason: '',
  note: '',

  leave_day: 1,
  file_url: undefined,
  sent_email: 0,
  updated_balance: 0,
  action_by: undefined,
  action_at: undefined,
  created_at: undefined,
  updated_at: undefined,
  notify: 0,
}

let state
export class LeaveRequest extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
      doc: cloneDeep(original),
      leave_type: undefined,
      available_day: undefined,
    })
    state = this
  }

  toQuery(query = {}) {
    const {
      index = 1,
      per_page = 20,
      user_id,
      status,
      category,
      start_date,
      end_date,
    } = query

    let q = `index=${index}&per_page=${per_page}`

    if (status) q += `&status=${status}`
    if (user_id) q += `&user_id=${user_id}`
    if (category) q += `&category=${category}`
    if (start_date) q += `&start_date=${start_date.toISOString()}`
    if (end_date) q += `&end_date=${end_date.toISOString()}`

    return q
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${config.api}/v1/user-admin/leave-request/leave?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getReportList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${config.api}/v1/user-admin/leave-request/leave/report?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCountList(query) {
    const q = this.toQuery(query)
    const url = `${config.api}/v1/user-admin/leave-request/leave/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async getExportList(query) {
    const q = this.toQuery(query)
    const url = `${config.api}/v1/user-admin/leave-request/leave/report?no_limit=yes&${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const data_list = list.map((it) => {
      delete it.createdAt
      delete it.updatedAt
      delete it.holiday
      delete it.start_unix
      delete it.end_unix
      delete it.request_list
      delete it.company_id
      delete it.leave_type_id
      delete it.cancel_user_id
      delete it.approved_user_id
      delete it.reject_user_id
      delete it.sent_email
      delete it.sent_slack
      delete it.balance_status
      delete it.img_url

      it.start_date = format.toExportDate(it.start_date)
      it.end_date = format.toExportDate(it.end_date)
      it.approved_at = format.toExportDate(it.approved_at)
      it.reject_at = format.toExportDate(it.reject_at)
      it.cancel_at = format.toExportDate(it.cancel_at)
      it.created_at = format.toExportDate(it.created_at)
      it.updated_at = format.toExportDate(it.updated_at)

      return it
    })

    const today = timer.get()
    const date = today.format('DDMMYY-HHmm')

    const ws = XLSX.utils.json_to_sheet(data_list)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, ws, 'leave-request')

    XLSX.writeFile(workbook, `leave-request-${date}.xlsx`)

    return list
  }

  updateInList(id, doc) {
    const { list = [] } = this.toJS()
    const i = list.findIndex((it) => it.leave_request_id === id)
    if (i !== -1) {
      list[i].status = doc.status
      runInAction(() => {
        state.list = list
      })
    }
  }

  async rejectLeaveReq({ leave_request_id, reason = '' }) {
    reason = reason.trim()

    const url = `${config.api}/v1/user-admin/leave-request/leave/${leave_request_id}/reject`
    const res = await http.put(url, { json: { reason } })

    const newDoc = res.body
    this.updateInList(leave_request_id, newDoc)
  }

  async approveLeaveReq({ leave_request_id }) {
    const url = `${config.api}/v1/user-admin/leave-request/leave/${leave_request_id}/approve`
    const res = await http.put(url)

    const newDoc = res.body
    this.updateInList(leave_request_id, newDoc)
  }

  reset(holiday_list = []) {
    const doc = cloneDeep(original)
    const start_date = timer.getDateNoHoliday(timer.getDate(), holiday_list)
    doc.start_date = start_date

    const next = start_date.clone().add(1, 'days')
    const end_date = timer.getDateNoHoliday(next, holiday_list)
    doc.end_date = end_date

    runInAction(() => {
      state.doc = doc
      state.leave_type = undefined
      state.available_day = undefined
    })
  }

  setDoc(doc, leave_type) {
    runInAction(() => {
      state.doc = doc
    })
  }

  setDocLeaveType(doc, leave_type) {
    runInAction(() => {
      state.doc = doc
      state.leave_type = leave_type
    })
  }

  setAvailableDay(val) {
    runInAction(() => {
      state.available_day = val
    })
  }

  async submitLeaveRequest(json = {}) {
    const { user_id } = json
    delete json.user_id

    const url = `${config.api}/v1/user-admin/leave-request/leave/${user_id}`
    await http.post(url, { json })

    this.reset()
  }
}

export default new LeaveRequest()
