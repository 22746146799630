import { initMenu } from 'utils/helper'
import { timer } from 'utils/timer'
import { color } from 'utils/color'
// Status List
const statusApprove = {
  name: 'Approved',
  name_th: 'อนุมัติ',
  color: color.dark_grey,
  bg: color.light_green,
  value: 'approved',
}
const statusReject = {
  name: 'Reject',
  name_th: 'ไม่อนุมัติ',
  color: 'white',
  bg: color.red,
  value: 'reject',
}
const statusCancel = {
  name: 'Cancel',
  name_th: 'ยกเลิก',
  color: 'white',
  bg: color.grey,
  value: 'cancel',
}

const statusPending = {
  name: 'Pending',
  name_th: 'รอกำเนินการ',
  color: color.dark_grey,
  bg: color.yellow,
  value: 'pending',
}

const statusRequesting = {
  name: 'Requesting',
  name_th: 'รออนุมัติ',
  color: color.dark_grey,
  bg: color.yellow,
  value: 'requesting',
}

const statusCanceling = {
  name: 'Canceling',
  name_th: 'รออนุมัติการยกเลิก',
  color: color.dark_grey,
  bg: color.yellow,
  value: 'canceling',
}

const StatusList = [statusPending, statusApprove, statusReject, statusCancel]

// Status List
const StatusKey = [
  statusRequesting,
  statusApprove,
  statusReject,
  statusCancel,
  statusCanceling,
]
const status = initMenu(StatusList)
const statusKey = initMenu(StatusKey)

export const Status = statusKey.keys
export const StatusMenu = {
  eng: status.menu,
  thai: status.menu_th,
}

// Leave Category List
const LeaveCategoryList = [
  { name: 'Sick', name_th: 'ลาป่วย', icon: '', value: 'sick' },
  { name: 'Business', name_th: 'ลากิจ', icon: '', value: 'business' },
  { name: 'Information', name_th: 'ข่าวสาร', icon: '', value: 'information' },
  { name: 'Vacation', name_th: 'ลาพักร้อน', icon: '', value: 'vacation' },
  { name: 'Other', name_th: 'อื่นๆ', icon: '', value: 'other' },
]
const category = initMenu(LeaveCategoryList)

export const LeaveCategory = category.keys
export const LeaveCategoryMenu = {
  eng: category.menu,
  thai: category.menu_th,
}

// Balance Type List
const BalanceTypeList = [
  { name: 'Unused', name_th: 'ไม่ใช้งาน', value: 'unused' },
  { name: 'Per Year', name_th: 'ต่อปี', value: 'per_year' },
  { name: 'Per Month', name_th: 'ต่อเดือน', value: 'per_month' },
]
const balanceType = initMenu(BalanceTypeList)

export const BalanceType = balanceType.keys
export const BalanceTypeMenu = {
  eng: balanceType.menu,
  thai: balanceType.menu_th,
}

// function
export const getLeaveCategory = (value) => {
  const item = LeaveCategory[value] || LeaveCategory.other
  return item
}

export const getBalanceType = (value) => {
  const item = BalanceType[value] || BalanceType.unused
  return item
}

const toDate = (date, typ = '') => {
  const txt = timer.get(date).format('DD/MM/YY')
  return typ === 'full' ? txt : `${txt} ${typ.toUpperCase()}`
}

export const getLeaveDate = (item = {}) => {
  const { leave_type, start_date, start_type, end_date, end_type } = item

  const start = toDate(start_date, start_type)
  return leave_type === 'one'
    ? start
    : `${start} - ${toDate(end_date, end_type)}`
}

export const calcLeaveDay = (item = {}, holiday_list = [], leave_type = {}) => {
  if (item.leave_type === 'one') {
    return item.start_type === 'full' ? 1 : 0.5
  }

  let counter = 0
  if (leave_type.count_holiday === 'yes') {
    counter = item.end_date.diff(item.start_date, 'days') + 1
  } else {
    let start_date = item.start_date.clone()
    while (start_date <= item.end_date) {
      if (timer.isHoliday(start_date, holiday_list) === false) {
        counter++
      }
      start_date.add(1, 'days')
    }
  }

  if (item.start_type !== 'full') counter -= 0.5
  if (item.end_type !== 'full') counter -= 0.5

  return counter
}

export const getTotalBalance = (leave_type = {}, leave_balance = {}) => {
  const { option_type = 'unused', option_day } = leave_balance
  const total = option_type === 'unused' ? leave_type.balance_day : option_day

  return +total || 0
}
