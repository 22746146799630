import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/account/employee-benefit`
let state
export class EmployeeBenefit extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: { benefit_list: [] },
    })
    state = this
  }

  async getBenefit(user_id) {
    const url = `${Url}/${user_id}`
    const resp = await http.get(url)

    const benefit_list = resp.body.benefit_list || []

    runInAction(() => {
      state.doc = { benefit_list }
    })
  }

  async updateBenefit(user_id, json = {}) {
    const url = `${Url}/${user_id}`
    await http.put(url, { json })

    runInAction(() => {
      state.doc = json
    })
  }

  setDoc(data) {
    runInAction(() => {
      state.doc = data
    })
  }
}

export default new EmployeeBenefit()
