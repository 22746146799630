export const color = {
  red: '#F36273',
  yellow: '#F7E293',
  blue: '#305FED',
  green: '#2a9c9d',
  light_green: '#85E3D1',
  black: '#1F1F1F',
  dark_grey: '#3F3F3F',
  grey: '#8A8A8A',
}
