import { runInAction } from 'mobx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const sortCounter = (a, b) => {
  if (a.counter < b.counter) {
    return 1
  }
  if (a.counter > b.counter) {
    return -1
  }
  return 0
}

let state
export class Dashboard extends BaseStore {
  constructor() {
    super()
    this.observable({
      employee: {
        code: 0,
        total_new_employee: 0,
        total_employee: 0,
        total_employee_left: 0,
        label_list: [],
        label_th_list: [],
        list: [],
      },
      leave_req: {},
      pending_leave_req_list: [],
    })
    state = this
  }

  async getEmployee({ date } = {}) {
    const txt = date.toISOString()
    const url = `${config.api}/v1/user-admin/account/dashboard/employee/${txt}`
    const res = await http.get(url)

    const {
      leave_list = [],
      new_employee_list = [],
      total_employee = 0,
    } = res.body || {}

    const label_list = ['New Employee', 'Employee Left']
    const label_th_list = ['พนักงานใหม่', 'พนักงานออก']

    const total_new_employee = new_employee_list.length
    const total_employee_left = leave_list.length

    runInAction(() => {
      state.employee = {
        code: timer.getUnix(),
        label_list,
        label_th_list,
        list: [total_new_employee, total_employee_left],

        total_new_employee,
        total_employee: total_employee + total_new_employee,
        total_employee_left,
      }
    })
  }

  async getLeaveRequest({ date } = {}) {
    const txt = date.toISOString()
    const url = `${config.api}/v1/user-admin/leave-request/dashboard/leave-request/${txt}`
    const res = await http.get(url)

    const list = res.body || []

    const counter = {}
    for (const item of list) {
      const { leave_type_id, leave_name, leave_day } = item
      let data = counter[leave_type_id]
      if (!data) {
        data = {
          leave_name,
          leave_name_th: leave_name,
          counter: 0,
        }
        counter[leave_type_id] = data
      }

      data.counter += leave_day
    }

    const names = Object.keys(counter)
    const data_list = names
      .map((n) => {
        return counter[n]
      })
      .sort(sortCounter)

    const result_list = []
    const other = {
      leave_name: 'other',
      leave_name_th: 'อื่นๆ',
      counter: 0,
    }

    let i = 1
    const max = 8
    for (const item of data_list) {
      if (i++ < max) {
        result_list.push(item)
      } else {
        other.counter += item.counter
      }
    }

    if (data_list.length >= max) {
      result_list.push(other)
    }

    runInAction(() => {
      state.leave_req = {
        list: result_list,
      }
    })
  }

  async getPendingLeaveRequest() {
    const url = `${config.api}/v1/user-admin/leave-request/leave?status=pending&no_limit=yes`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.pending_leave_req_list = list
    })
  }
}

export default new Dashboard()
