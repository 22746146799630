import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/payroll/benefit`
let state
export class BenefitType extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
    })
    state = this
  }

  async getList() {
    const url = `${Url}`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.list = list
    })
  }

  async createBenefit(json) {
    const url = `${Url}`
    const res = await http.post(url, { json })

    const { list } = this.toJS()

    list.unshift(res.body)
    runInAction(() => {
      state.list = list
    })
  }

  async updateBenefit(benefit_id, json = {}) {
    const url = `${Url}/${benefit_id}/detail`
    await http.put(url, { json })

    const { list } = this.toJS()

    const i = list.findIndex((it) => it.benefit_id === benefit_id)
    if (i !== -1) {
      json.benefit_id = benefit_id
      list[i] = json

      runInAction(() => {
        state.list = list
      })
    }
  }

  async deleteBenefit(benefit_id) {
    const url = `${Url}/${benefit_id}`
    await http.delete(url, {})

    const { list } = this.toJS()
    const i = list.findIndex((it) => it.benefit_id === benefit_id)
    if (i !== -1) {
      list.splice(i, 1)

      runInAction(() => {
        state.list = list
      })
    }
  }
}

export default new BenefitType()
