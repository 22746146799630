import { runInAction } from 'mobx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

let state
export class DashboardTimesheet extends BaseStore {
  constructor() {
    super()
    this.observable({
      project: {
        code: 0,
        total: 0,
        label_list: ['Jan 24', 'Feb 24', 'Mar 24'],
        list: [
          {
            name: 'Survey',
            data_list: [1, 20, 15],
          },
        ],
      },
      activity: {},
    })
    state = this
  }

  async getDashboard({ date } = {}) {
    const url = `${config.api}/v1/user-admin/timesheet/dashboard`
    const res = await http.get(url)

    const data = res.body || {}

    const manday_total = data.manday_total

    const label_list = data.label_list || []
    const category_list = data.category_list || []
    const activity_list = data.activity_list || []

    const code = timer.getUnix()
    runInAction(() => {
      state.project = {
        code,
        manday_total,
        label_list: label_list,
        list: category_list,
      }

      state.activity = {
        code,
        manday_total,
        label_list: label_list,
        list: activity_list,
      }
    })
  }
}

export default new DashboardTimesheet()
