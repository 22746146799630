import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/reimbursement/config`

const original = {
  company_id: '',
  notification: {
    hr_email: '',
  },
  request: {
    submit_disable: {
      type: 'unused',
      past_day: 0,
      future_day: 0,
    },
    cancel_disable: {
      type: 'unused',
      past_day: 0,
      future_day: 0,
    },
  },
  title: '',
  content: '',
}

let state

const toReimburseReq = (item = {}) => {
  item.past_day = +item.past_day || 0
  item.future_day = +item.future_day || 0
  return item
}

const verify = (data = {}) => {
  const request = data.request
  request.submit_disable = toReimburseReq(request.submit_disable)
  request.cancel_disable = toReimburseReq(request.cancel_disable)

  data.request = request

  return data
}

export class ReimburseConfig extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
    })
    state = this
  }

  async getConfig() {
    const url = `${Url}`
    const resp = await http.get(url)

    const data = resp.body
    runInAction(() => {
      state.doc = data
    })
  }

  async updateConfig(data = {}) {
    const json = verify(data)
    const url = `${Url}`
    await http.put(url, { json })

    runInAction(() => {
      state.doc = json
    })
  }
}

export default new ReimburseConfig()
