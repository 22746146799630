import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/leave-request/leave-config`

const original_leave_type = {
  type: 'unused',
  value: 0,
  carry_day: 0,
  display_balance: true,
}

const original = {
  company_id: '',
  tag: '',
  version: '',
  notification: {
    email: '',
    hr_email: '',
    slack: {
      enabled: false,
      channel: '',
      token: '',
    },
  },
  leave_type: {
    sick: cloneDeep(original_leave_type),
    business: cloneDeep(original_leave_type),
    vacation: cloneDeep(original_leave_type),
    information: cloneDeep(original_leave_type),
    other: cloneDeep(original_leave_type),
  },
  leave_request: {
    submit_disable: {
      type: 'unused',
      past_day: 0,
      future_day: 0,
    },
    cancel_disable: {
      type: 'unused',
      past_day: 0,
      future_day: 0,
    },

    submit_approve: {
      type: 'verify',
      past_day: 1,
    },
    cancel_approve: {
      type: 'verify',
      past_day: 1,
    },

    approval: {
      line_manager: true,
      project_manager: true,
    },
  },
}

let state
const toLeaveType = (item = {}) => {
  item.value = +item.value || 0
  item.carry_day = +item.carry_day || 0
  return item
}

const toLeaveReq = (item = {}) => {
  item.past_day = +item.past_day || 0
  item.future_day = +item.future_day || 0
  return item
}

const verify = (data = {}) => {
  const leave_type = data.leave_type
  leave_type.sick = toLeaveType(leave_type.sick)
  leave_type.business = toLeaveType(leave_type.business)
  leave_type.vacation = toLeaveType(leave_type.vacation)
  leave_type.information = toLeaveType(leave_type.information)
  leave_type.other = toLeaveType(leave_type.other)

  data.leave_type = leave_type

  const leave_request = data.leave_request
  leave_request.submit_disable = toLeaveReq(leave_request.submit_disable)
  leave_request.cancel_disable = toLeaveReq(leave_request.cancel_disable)
  leave_request.submit_approve = toLeaveReq(leave_request.submit_approve)
  leave_request.cancel_approve = toLeaveReq(leave_request.cancel_approve)

  data.leave_request = leave_request

  return data
}

export class LeaveReqSetting extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: cloneDeep(original),
    })
    state = this
  }

  async getConfig() {
    const url = `${Url}`
    const resp = await http.get(url)

    const data = resp.body
    runInAction(() => {
      state.doc = data
    })
  }

  async updateConfig(data = {}) {
    const json = verify(data)
    const url = `${Url}`
    await http.put(url, { json })

    runInAction(() => {
      state.doc = json
    })
  }
}

export default new LeaveReqSetting()
