/* eslint-disable */

const Logo = (props) => {
  const { size = 1 } = props

  const width = `${Math.ceil(34 * size)}`
  const height = `${Math.ceil(34 * size)}`
  return (
    <svg width={width} height={height} version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
      <g>
         <path d="m244.71 294.83c2.1875 0.31641 4.9961 0.47266 8.1289 0.47266 14.051 0 26.082-3.4297 34.195-11.086 6.2578-5.9336 9.6953-14.684 9.6953-24.988 0-10.309-4.5234-19.047-11.254-24.367-7.0156-5.6172-17.492-8.4336-32.164-8.4336-14.535 0-24.84 0.9375-32.172 2.1875v103.87h23.582l-0.003906-37.656zm0-49.508c1.7148-0.47266 4.9961-0.9375 9.8438-0.9375 11.863 0 18.586 5.7852 18.586 15.461 0 10.781-7.8047 17.184-20.465 17.184-3.4297 0-5.9219-0.14844-7.9609-0.62109z"/>
         <path d="m385.96 319.99c10.148-8.5938 17.492-22.488 17.492-42.648 0-18.586-6.8672-31.543-17.805-39.664-10.148-7.6562-23.109-11.254-43.102-11.254-12.031 0-23.441 0.77734-32.172 2.1875v103.4c5.9219 0.77734 14.672 1.5664 27.012 1.5664 20.602 0.003906 37.48-4.3711 48.574-13.586zm-51.703-74.348c2.0195-0.46484 5.4609-0.9375 10.77-0.9375 20.465 0 33.266 11.559 33.117 33.426 0 25.148-14.07 37.02-35.613 36.863-2.9766 0-6.2578 0-8.2773-0.47266z"/>
         <path d="m441.47 290.16h37.801v-19.375h-37.801v-24.051h40.441v-19.523h-64.336v105.27h23.895z"/>
         <path d="m195.82 560h308.36c35.664 0 64.574-28.91 64.574-64.566v-430.87c0-35.656-28.91-64.566-64.566-64.566h-308.37c-35.656 0-64.566 28.91-64.566 64.566v430.87c0 35.656 28.91 64.566 64.566 64.566zm308.37-8.75h-59.266l115.08-115.12v59.309c0 30.773-25.043 55.816-55.816 55.816zm-308.37-542.5h59.273l-115.09 115.11v-59.297c0-30.773 25.043-55.816 55.816-55.816zm-55.816 124.5 1.4961 1.4961 125.43-125.47-0.52344-0.52344h237.78c30.773 0 55.816 25.043 55.816 55.816v362.18l-1.4961-1.4961-125.43 125.48 0.52344 0.52344h-237.78c-30.773 0-55.816-25.043-55.816-55.816z"/>
      </g>
   </svg>
  )
}
/* eslint-enable */
export default Logo
