import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/attendance/office`
const original = {
  status: 'active',
  name: '',
  address: '',
  mobile: '',
  gps_status: 'inactive',
  wifi_status: 'inactive',
  latitude: 13.7248936,
  longitude: 100.4930264,
  beacon_status: 'inactive',
  beacon_list: [],
}
let state
export class Office extends BaseStore {
  constructor() {
    super()
    this.observable({
      menu_list: [],
      data_list: [],
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
      doc: cloneDeep(original),
    })
    state = this
  }

  reset() {
    runInAction(() => {
      state.doc = cloneDeep(original)
    })
  }

  setDoc(data) {
    runInAction(() => {
      state.doc = data
    })
  }

  async getOffice(id) {
    const { office_id } = this.toJS().doc
    if (+office_id === +id) return

    const url = `${Url}/${id}/detail`
    const res = await http.get(url)

    const doc = res.body || cloneDeep(original)

    runInAction(() => {
      state.doc = doc
    })
  }

  async getMenuList({ force = false } = {}) {
    if (force === false) {
      if (this.toJS().menu_list.length > 0) {
        return
      }
    }
    const url = `${Url}?status=active&per_page=-1`
    const resp = await http.get(url)

    const list = resp.body || []
    const menu_list = list.map((it) => {
      const { office_id, name } = it
      return { name, value: office_id, tag: it }
    })
    runInAction(() => {
      state.menu_list = menu_list
      state.data_list = list
    })
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20, status } = query

    let q = `index=${index}&per_page=${per_page}`
    if (status) q += `&status=${status}`

    return q
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCountList(query) {
    const q = this.toQuery(query)
    const url = `${Url}/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async updateOffice(office_id, json = {}) {
    const url = office_id ? `${Url}/${office_id}/detail` : Url
    const res = office_id
      ? await http.put(url, { json })
      : await http.post(url, { json })

    const doc = res.body

    runInAction(() => {
      state.menu_list = []
      state.doc = doc
    })

    return doc
  }

  async deleteOffice(office_id) {
    const url = `${Url}/${office_id}`
    await http.delete(url, {})

    runInAction(() => {
      state.menu_list = []
    })

    const { page, list } = this.toJS()

    const i = list.findIndex((it) => it.office_id === office_id)
    if (i > -1) {
      page.total = page.total - 1
      list.splice(i, 1)

      runInAction(() => {
        state.list = list
        state.page = page
      })
    }
  }
}

export default new Office()
