import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/payroll/payroll-company`
let state
export class PayrollPayslip extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: {},
    })
    state = this
  }

  async getSetting() {
    const url = `${Url}`
    const resp = await http.get(url)

    const data = resp.body || {}

    runInAction(() => {
      state.doc = data
    })
  }

  async updateSetting(json = {}) {
    const url = `${Url}`
    await http.put(url, { json })

    runInAction(() => {
      state.doc = json
    })
  }

  async getPayslip({ payroll_user_id, language }) {
    const url = `${config.api}/v1/user-admin/payroll/payslip/${payroll_user_id}/${language}`
    const resp = await http.get(url)

    const data = resp.body || {}

    return data.payslip_url
  }
}

export default new PayrollPayslip()
