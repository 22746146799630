import { initMenu } from 'utils/helper'
import { color } from 'utils/color'
// Status List
const approve = {
  name: 'Approved',
  name_th: 'อนุมัติ',
  color: color.dark_grey,
  bg: color.light_green,
  value: 'approved',
}
const reject = {
  name: 'Reject',
  name_th: 'ไม่อนุมัติ',
  color: 'white',
  bg: color.red,
  value: 'reject',
}
const cancel = {
  name: 'Cancel',
  name_th: 'ยกเลิก',
  color: 'white',
  bg: color.grey,
  value: 'cancel',
}

const requesting = {
  name: 'Requesting',
  name_th: 'รออนุมัติ',
  color: color.dark_grey,
  bg: color.yellow,
  value: 'requesting',
}

// Status List
const StatusKey = [requesting, approve, reject, cancel]
const statusKey = initMenu(StatusKey)

export const Status = statusKey.keys
export const StatusMenu = {
  eng: statusKey.menu,
  thai: statusKey.menu_th,
}

// function
export const getStatus = (value) => {
  const item = Status[value] || Status.requesting
  return item
}

// Request Type

const both = {
  name: 'Both',
  name_th: 'ทั้งคู่',
  value: 'both',
}

const employee = {
  name: 'Employee',
  name_th: 'พนักงาน',
  value: 'employee',
}

const manager = {
  name: 'Manager',
  name_th: 'ผู้ดูแล',
  value: 'manager',
}

const RequestTypeList = [both, employee, manager]
const requestType = initMenu(RequestTypeList)

export const RequestType = requestType.keys
export const RequestTypeMenu = {
  eng: requestType.menu,
  thai: requestType.menu_th,
}

// function
export const getRequestType = (value) => {
  const item = RequestType[value] || RequestType.both
  return item
}
