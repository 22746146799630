import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/account/admin-customer`
let state
export class Admin extends BaseStore {
  constructor() {
    super()
    this.observable({
      admin_list: [],
    })
    state = this
  }

  async getAdminList() {
    const url = `${Url}`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.admin_list = list
    })
  }

  async updateAdminAccount(json = {}) {
    const { admin_customer_id, user_role_id } = json
    const url = admin_customer_id
      ? `${Url}/${admin_customer_id}/${user_role_id}`
      : `${Url}/${user_role_id}`
    const res = admin_customer_id
      ? await http.put(url, { json })
      : await http.post(url, { json })

    const data = res.body

    const list = this.toJS().admin_list
    const i = list.findIndex((it) => it.admin_customer_id === admin_customer_id)
    if (i !== -1) {
      list[i] = data
    } else {
      list.push(data)
    }

    runInAction(() => {
      state.admin_list = list
    })
  }

  async deleteAdmin({ admin_customer_id } = {}) {
    const url = `${Url}/${admin_customer_id}`
    await http.delete(url)

    const list = this.toJS().admin_list
    const i = list.findIndex((it) => it.admin_customer_id === admin_customer_id)
    if (i !== -1) {
      list.splice(i, 1)

      runInAction(() => {
        state.admin_list = list
      })
    }
  }

  async updatePasswordAdminAccount(json = {}) {
    const { admin_customer_id } = json
    const url = `${Url}/password/${admin_customer_id}`
    await http.put(url, { json })
  }
}

export default new Admin()
