import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/timesheet/config`
let state
export class TimesheetConfig extends BaseStore {
  constructor() {
    super()
    this.observable({
      setting: {
        edit_part_day: 15,
        edit_future_day: 0,
        minimum_minute_weekly: 2400,
      },
    })
    state = this
  }

  async getSetting(isForce = true) {
    if (!isForce) {
      const setting = this.toJS().setting
      if (setting.ts_config_id) return
    }

    const url = `${Url}`
    const res = await http.get(url)

    const data = res.body || {}

    runInAction(() => {
      state.setting = data
    })
  }

  async updateSetting(json = {}) {
    const url = `${Url}`
    await http.put(url, { json })

    runInAction(() => {
      state.setting = json
    })
  }
}

export default new TimesheetConfig()
