const toTextNumber = (val, index) => {
  switch (`${val}`) {
    case '1':
      return index === 0 ? 'เอ็ด' : 'หนึ่ง'
    case '2':
      return index === 1 ? 'ยี่' : 'สอง'
    case '3':
      return 'สาม'
    case '4':
      return 'สี่'
    case '5':
      return 'ห้า'
    case '6':
      return 'หก'
    case '7':
      return 'เจ็ด'
    case '8':
      return 'แปด'
    case '9':
      return 'เก้า'
    default:
      return 'ศูนย์'
  }
}

const toTextUnit = (val) => {
  switch (`${val}`) {
    case '1':
      return 'สิบ'
    case '2':
      return 'ร้อย'
    case '3':
      return 'พัน'
    case '4':
      return 'หมื่น'
    case '5':
      return 'แสน'
    case '6':
      return 'ล้าน'
    case '7':
      return 'สิบ'
    case '8':
      return 'ร้อย'
    case '9':
      return 'พัน'
    default:
      return ''
  }
}

const toTextNumberList = (txt) => {
  const len = txt.length
  let text = ''
  for (let i = 0; i < len; i++) {
    const digi = txt[i]
    const index = len - i - 1

    const t = toTextNumber(digi, index)
    const u = toTextUnit(index)

    if (digi !== '0') {
      text += `${t}${u}`
    }
  }

  return text
}

const toSatang = (txt) => {
  const len = txt.length
  if (len === 0) return ''

  const text = toTextNumberList(txt)
  return `${text}สตางค์`
}

export const toTextMoney = (total) => {
  if (!total) return ''

  const list = `${total}`.split('.')

  const len = list.length
  let remainder = len >= 2 ? list[1] : ''
  remainder = remainder.length === 1 ? `${remainder}0` : remainder
  const num = len >= 1 ? list[0] : ''

  const remainderTxt = toSatang(remainder)
  const numTxt = toTextNumberList(num)
  return `${numTxt}บาท${remainderTxt}ถ้วน`
}
