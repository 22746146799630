const color = {
  red: '#F36273',
  yellow: '#F7E293',
  blue: '#305FED',
  green: '#2a9c9d',
  light_green: '#85E3D1',
  black: '#1F1F1F',
  grey: '#8A8A8A',

  success: '#00C767',
  error: '#DE001A',
  warning: '#EDAE30',
  action: '#305FED',
  disable: '#F5F6F9',
  backgroundColor: '#F6F7FB',
  border: '#cccccc',
}

const leave_category = {
  sick: { color: 'white', bg: '#2A9C9D' },
  business: { color: 'white', bg: '#85E3D1' },
  information: { color: '#333333', bg: '#F7E293' },
  vacation: { color: 'white', bg: '#F36273' },
  other: { color: '#333333', bg: '#82C3FF' },
}

const input = {
  disable: '#F5F6F9',
  border: '#cccccc',
}

const table = {
  header: '#FFF6F6',
  line: '#EBF3E8',
}

const color_level = {
  red: {
    high: '#D70004',
    medium: '#F36273',
    low: '#ffbaba',
  },

  yellow: {
    high: '#D78F00',
    medium: '#EDAE30',
    low: '#F5BF57',
  },

  blue: {
    high: '#134EE4',
    medium: '#305FED',
    low: '#577AF5',
  },

  green: {
    high: '#00B377',
    medium: '#00C767',
    low: '#D1EADE',
    light: '#F3F4F4',
  },

  grey: {
    dark: '#1F1F1F',
    high: '#3F3F3F',
    medium: '#626262',
    low: '#CCCCCC',
  },
}

const media = {
  mobile_xs: '@media screen and (max-width: 350px)',
  mobile: '@media screen and (max-width: 500px)',
  tablet: '@media screen and (max-width: 800px)',
  pc: '@media screen and (max-width: 1024px)',
  wirescreen: '@media screen and (max-width: 1440px)',
}

const theme = {
  max: '1024px',
  leave_category,
  color,
  color_level,
  table,
  input,
  media,
}

export default theme
