import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/attendance/report/summary`

let state
export class Summary extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
    })
    state = this
  }

  toQuery(query = {}) {
    const {
      index = 1,
      per_page = 20,
      date,
      office_id,
      working_time_id,
      user_id,
      department_id,
      project_id,
    } = query

    let q = `index=${index}&per_page=${per_page}`

    if (date) {
      const start_date = date.clone().startOf('month').toISOString()
      const end_date = date.clone().endOf('month').toISOString()

      q += `&start_date=${start_date}&end_date=${end_date}`
    }
    if (office_id) q += `&office_id=${office_id}`
    if (working_time_id) q += `&working_time_id=${working_time_id}`
    if (user_id) q += `&user_id=${user_id}`
    if (department_id) q += `&department_id=${department_id}`
    if (project_id) q += `&project_id=${project_id}`

    return q
  }

  async getExportList(query) {
    const q = this.toQuery({ ...query, per_page: -1 })
    const url = `${Url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    return list
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCountList(query) {
    const q = this.toQuery(query)
    const url = `${Url}/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }
}

export default new Summary()
