/* eslint-disable */
const Logo = (props) => {
  const { size = 1, color = 'white' } = props
  const width = size * 24
  const height = size * 21
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.83333 25.6667H22.1667C22.7855 25.6667 23.379 25.4208 23.8166 24.9832C24.2542 24.5457 24.5 23.9522 24.5 23.3333V12.8333C24.5009 12.6798 24.4715 12.5276 24.4134 12.3854C24.3553 12.2433 24.2698 12.114 24.1617 12.005L14.8283 2.67166C14.6097 2.45436 14.314 2.3324 14.0058 2.3324C13.6976 2.3324 13.4019 2.45436 13.1833 2.67166L3.85 12.005C3.73976 12.113 3.65207 12.2419 3.59199 12.3841C3.53191 12.5263 3.50064 12.679 3.5 12.8333V23.3333C3.5 23.9522 3.74583 24.5457 4.18342 24.9832C4.621 25.4208 5.21449 25.6667 5.83333 25.6667ZM11.6667 23.3333V17.5H16.3333V23.3333H11.6667ZM5.83333 13.3117L14 5.14499L22.1667 13.3117V23.3333H18.6667V17.5C18.6667 16.8811 18.4208 16.2877 17.9832 15.8501C17.5457 15.4125 16.9522 15.1667 16.3333 15.1667H11.6667C11.0478 15.1667 10.4543 15.4125 10.0168 15.8501C9.57917 16.2877 9.33333 16.8811 9.33333 17.5V23.3333H5.83333V13.3117Z" fill={color}/>
    </svg>

  )
}

/* eslint-enable */
export default Logo
