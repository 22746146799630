import { useState, useEffect, useCallback, lazy, Suspense } from 'react'
import { observer, inject } from 'mobx-react'
import { Routes, Route } from 'react-router-dom'

import Loading from 'components/loading'
import MainLayout from 'menu'
import NoPermission from 'pages/noPermission'

const Dashboard = lazy(() => import('pages/dashboard'))
const LoginPage = lazy(() => import('pages/login'))
const CompanyInfo = lazy(() => import('pages/company/information'))
const Department = lazy(() => import('pages/department'))
const TeamList = lazy(() => import('pages/team/list'))
const TeamInfo = lazy(() => import('pages/team/detail'))
const HolidayInfo = lazy(() => import('pages/holiday'))

const EmployeeApp = lazy(() => import('./EmployeeApp'))
const LeaveReqApp = lazy(() => import('./LeaveReqApp'))
const ReimburseApp = lazy(() => import('./ReimburseApp'))
const DocumentApp = lazy(() => import('./DocumentApp'))
const AttendanceApp = lazy(() => import('./AttendanceApp'))
const TimesheetApp = lazy(() => import('./TimesheetApp'))
const OvertimeApp = lazy(() => import('./OvertimeApp'))
const PayrollApp = lazy(() => import('./PayrollApp'))
const AdminApp = lazy(() => import('./AdminApp'))

const App = (props) => {
  const [loading, setLoading] = useState(true)
  const isLogin = props.member.isLogin()
  const { role = {} } = props.member.toJS()
  const onCheck = useCallback(async () => {
    try {
      setLoading(true)
      await props.lang.init()
      await props.member.checkLoginUser()
    } catch (e) {
      console.log('check user:', e.message)
    }
    setLoading(false)
  }, [props.member, props.lang])

  useEffect(() => {
    onCheck()
  }, [onCheck])

  if (loading) {
    return (
      <Routes>
        <Route path="*" element={<Loading />} />
      </Routes>
    )
  } else if (!isLogin) {
    return (
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="*" element={<LoginPage />} />
        </Routes>
      </Suspense>
    )
  }

  const getElement = (Element, key, name = 'module') => {
    const m = role[key] || {}
    const val = m[name] || 'none'
    return val === 'none' ? (
      <NoPermission />
    ) : (
      <Element role={m} isReadonly={val === 'view'} />
    )
  }

  return (
    <MainLayout>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={getElement(Dashboard, 'dashboard')} />
          <Route path="/company" element={getElement(CompanyInfo, 'company')} />
          <Route
            path="/department"
            element={getElement(Department, 'company')}
          />
          <Route path="/team" element={getElement(TeamList, 'company')} />
          <Route
            path="/team/create"
            element={getElement(TeamInfo, 'company')}
          />
          <Route
            path="/team/:team_id/edit"
            element={getElement(TeamInfo, 'company')}
          />
          <Route path="/holiday" element={getElement(HolidayInfo, 'setting')} />
          <Route
            path="/employee/*"
            element={getElement(EmployeeApp, 'employee')}
          />
          <Route
            path="/leave-req/*"
            element={getElement(LeaveReqApp, 'leave_req')}
          />
          <Route
            path="/reimburse/*"
            element={getElement(ReimburseApp, 'reimburse')}
          />
          <Route path="/document/*" element={getElement(DocumentApp, 'doc')} />
          <Route
            path="/attendance/*"
            element={getElement(AttendanceApp, 'attendance')}
          />
          <Route
            path="/timesheet/*"
            element={getElement(TimesheetApp, 'timesheet')}
          />
          <Route
            path="/overtime/*"
            element={getElement(OvertimeApp, 'overtime')}
          />
          <Route
            path="/payroll/*"
            element={getElement(PayrollApp, 'payroll')}
          />
          <Route path="/admin/*" element={getElement(AdminApp, 'admin')} />
        </Routes>
      </Suspense>
    </MainLayout>
  )
}
// <AdminApp />} />

export default inject('member', 'lang')(observer(App))
