import { runInAction } from 'mobx'
import * as XLSX from 'xlsx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Headers = [
  'user id',
  'name',
  'nickname',
  'date',
  'cost center',
  'category',
  'category id',
  'status',
  'balance',
]

const getDefaultBalance = (user = {}, category = {}) => {
  const { user_id, name, surname, name_th, surname_th, nickname, img_url } =
    user
  const { cost_center_id, reimburse_category_id, balance_type, amount } =
    category

  const category_name = category.name || ''
  const cost_center_name = category.cost_center_name || ''

  return {
    user_id,
    name,
    surname,
    name_th,
    surname_th,
    nickname,
    img_url,
    status: 'active',

    cost_center_id,
    reimburse_category_id,
    category_name,
    cost_center_name,

    balance_type,
    amount: 0,
    option_amount: 0,
    limit_amount: amount,
    option_type: 'unused',
  }
}

const getListForUser = (
  { user_id },
  balanceList = [],
  employeeList = [],
  categoryList = []
) => {
  const employee = employeeList.find((it) => it.user_id === user_id) || {}
  const list = categoryList.map((it) => {
    const { reimburse_category_id } = it
    const balanceItem = balanceList.find(
      (b) => b.reimburse_category_id === reimburse_category_id
    )

    const balance = balanceItem ? balanceItem : getDefaultBalance(employee, it)
    balance.limit_amount = it.amount
    return balance
  })

  return list
}

const getListForCategory = (
  { reimburse_category_id },
  balanceList = [],
  employeeList = [],
  categoryList = []
) => {
  const category =
    categoryList.find(
      (it) => it.reimburse_category_id === reimburse_category_id
    ) || {}

  const list = employeeList.map((employee) => {
    const { user_id } = employee
    const balanceItem = balanceList.find((b) => b.user_id === user_id)
    const balance = balanceItem
      ? balanceItem
      : getDefaultBalance(employee, category)

    balance.limit_amount = category.amount
    return balance
  })

  return list
}

const getListForOne = (
  { user_id, reimburse_category_id },
  balanceList = [],
  employeeList = [],
  categoryList = []
) => {
  const category =
    categoryList.find(
      (it) => it.reimburse_category_id === reimburse_category_id
    ) || {}

  if (balanceList.length > 0) {
    balanceList[0].limit_amount = category.amount
    return balanceList
  }

  const employee = employeeList.find((it) => it.user_id === user_id) || {}
  const balance = getDefaultBalance(employee, category)
  balance.limit_amount = category.amount
  return [balance]
}

const Url = `${config.api}/v1/user-admin/reimbursement/balance`
let state
export class UserBalance extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
      category_list: [],
      balance_list: [],
      balance_menu: [],
    })

    state = this
  }

  reset() {
    runInAction(() => {
      state.page = {
        index: 1,
        per_page: 20,
        total: 0,
      }
      state.list = []
    })
  }

  getFilter(params = {}) {
    const { user_id, reimburse_category_id } = params
    if (!user_id && !reimburse_category_id) return ''

    let query = ''
    if (user_id) query += `&user_id=${user_id}`
    if (reimburse_category_id) {
      query += `&reimburse_category_id=${reimburse_category_id}`
    }

    return query
  }

  async getBalanceList(params = {}, employeeList, categoryList) {
    const { date, user_id, reimburse_category_id } = params
    const txt = date.toISOString()
    const query = this.getFilter(params)
    if (query === '') {
      this.reset()
      return
    }
    const url = `${Url}/${txt}?${query}`
    const res = await http.get(url)

    const balanceList = res.body || []
    let list = []

    const isCategoryId = !!reimburse_category_id
    if (user_id && isCategoryId) {
      list = getListForOne(params, balanceList, employeeList, categoryList)
    } else if (user_id) {
      list = getListForUser(params, balanceList, employeeList, categoryList)
    } else if (isCategoryId) {
      list = getListForCategory(params, balanceList, employeeList, categoryList)
    }

    runInAction(() => {
      state.page = {
        index: 1,
        per_page: 10,
        total: list.length,
      }
      state.list = list
    })
  }

  setPagination(page = {}) {
    runInAction(() => {
      state.page = page
    })
  }

  async updateBalance({ date }, json = {}) {
    const { reimburse_category_id, user_id } = json

    const txt = date.toISOString()
    const url = `${Url}/${user_id}/${reimburse_category_id}/${txt}`
    await http.put(url, { json })

    const { list = [] } = this.toJS()
    const index = list.findIndex((it) => {
      return (
        it.user_id === user_id &&
        it.reimburse_category_id === reimburse_category_id
      )
    })

    if (index !== -1) {
      list[index] = json

      runInAction(() => {
        state.list = list
      })
    }
  }

  async getBalanceMenu() {
    const balance_menu = this.toJS().balance_menu
    if (balance_menu.length > 0) return balance_menu

    const url = `${Url}/cost-center/menu`
    const res = await http.get(url)

    const list = res.body || []
    const category_list = []

    const menuList = list.map((it) => {
      const item = { ...it }
      item.category_list = it.category_list.map((cate) => {
        category_list.push(cate)
        return {
          name: cate.name,
          value: cate.reimburse_category_id,
        }
      })
      return {
        name: it.cost_center_name,
        value: it.cost_center_id,
        tag: item,
      }
    })

    runInAction(() => {
      state.category_list = category_list
      state.balance_list = list
      state.balance_menu = menuList
    })

    return menuList
  }

  exportToFile(balance_date) {
    const balanceList = this.toJS().list || []
    const list = balanceList.map((it) => {
      const {
        status,
        user_id,
        name,
        surname,
        nickname,
        reimburse_category_id,
        category_name,
        cost_center_name,

        balance_type,
        option_amount,
        limit_amount,
        option_type,
      } = it
      const total = option_type === 'unused' ? limit_amount : option_amount
      const format = balance_type === 'per_year' ? 'YYYY' : 'MM/YYYY'
      const dateTxt = balance_date.format(format)
      return [
        user_id,
        `${name || ''} ${surname || ''}`,
        nickname,
        dateTxt,
        cost_center_name,
        category_name,
        reimburse_category_id,
        status,
        total || 0,
      ]
    })

    list.unshift(Headers)
    const today = timer.get()
    const date = today.format('DDMMYY')

    const ws = XLSX.utils.aoa_to_sheet(list)
    ws['!cols'] = [10, 18, 12, 12, 12, 18, 18, 12, 18].map((width) => {
      return { width }
    })

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, ws, 'expense')

    XLSX.writeFile(workbook, `expense-${date}.xlsx`)
  }
}

export default new UserBalance()
