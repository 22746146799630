import { runInAction } from 'mobx'
import numeral from 'numeral'
import * as XLSX from 'xlsx'

import { http, timer } from 'utils'
import { config } from 'config'
import { leaveReq } from 'constant'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/account/report/working`

const toWorkingTime = (start_at, end_at) => {
  if (!start_at || !end_at) return ''

  const startTxt = timer.get(start_at).format('HH:mm')
  const endTxt = timer.get(end_at).format('HH:mm')
  return `${startTxt}-${endTxt}`
}

const toTime = (date) => {
  if (!date) return ''

  return timer.get(date).format('HH:mm')
}

const toDate = (date) => {
  const dateTxt = timer.get(date).format('DD MMM YYYY')
  return dateTxt
}

const toTimeFromMinutes = (value) => {
  if (!value) return ''

  const hour = Math.trunc(value / 60)
  const minute = Math.trunc(value % 60)

  const hourTxt = numeral(hour).format('00')
  const minTxt = numeral(minute).format('00')
  return `${hourTxt}:${minTxt}`
}

const toLeaveReq = (params = {}, { counter } = {}) => {
  const { leave_category, leave_name, leave_date_type } = params
  if (!leave_category) return { text: '', leave_day: 0 }

  const isOneDay = leave_date_type === 'full'
  const leaveType = isOneDay ? '' : leave_date_type
  const cateItem = leaveReq.LeaveCategory[leave_category] || {}

  const text = `${cateItem.name || ''}/${leave_name || ''} ${leaveType}`
  const count_day = isOneDay ? 1 : 0.5
  let leave_day = count_day

  switch (leave_category) {
    case 'vocation':
      counter.vocation_leave += count_day
      break
    case 'sick':
      counter.sick_leave += count_day
      break
    case 'business':
      counter.business_leave += count_day
      break
    case 'information':
      counter.information_leave += count_day
      leave_day = 0
      break
    case 'other':
      counter.other_leave += count_day
      break
    default:
  }

  counter.total_leave += count_day
  return { text, leave_day }
}

const toTabname = (employee = {}, { isThai }) => {
  const { name, name_th, surname, surname_th } = employee || {}
  const firstname = isThai ? `${name_th || name || ''}` : `${name || ''}`
  const lastname = isThai
    ? `${surname_th || surname || ''}`
    : `${surname || ''}`

  const first_prefix = firstname.slice(0, 16)
  const last_prefix = lastname.slice(0, 2)

  const fullname = `${firstname} ${lastname}`
  const tabname = `${first_prefix} ${last_prefix}`

  return { fullname, tabname }
}

const Header = {
  th: [
    'วันที่',
    'วันหยุด',
    'เวลาทำงาน',
    'เวลาเข้างาน',
    'เวลาออกงาน',
    'เวลาสาย',
    'ออกงานก่อน',
    'ชั่วโมงทำงาน',
    'งานนอกเวลา',
    'ข้อความ',
  ],
  en: [
    'Date',
    'Holiday',
    'Work time',
    'Clock in',
    'Clock out',
    'Late time',
    'Leave early',
    'Man hour',
    'OT',
    'Note',
  ],
}

const Table = {
  th: {
    name: 'พนักงาน',
    work_day: 'วันทำงาน',
    missing_work: 'ขาดงาน',
    late_work: 'มาสาย',
    leave_early: 'ออกก่อนเวลา',
    overtime: 'งานล่วงเวลา',
    total_leave: 'รวมการลาทั้งหมด',
    vocation_leave: 'ลาพักร้อน',
    sick_leave: 'ลาป่วย',
    business_leave: 'ลากิจ',
    information_leave: 'แจ้งข่าวสาร',
    other_leave: 'แจ้งลาอื่นๆ',
  },
  en: {
    name: 'Employee',
    work_day: 'Work day',
    missing_work: 'Missing work',
    late_work: 'Late work',
    leave_early: 'Leave early',
    overtime: 'Overtime',
    total_leave: 'Total leave',
    vocation_leave: 'Vocation leave',
    sick_leave: 'Sick leave',
    business_leave: 'Business leave',
    information_leave: 'Information',
    other_leave: 'Other leave',
  },
}

const Width = {
  date: 16,
  text: 18,
  time: 14,
  content: 30,
}
const ColumnList = [
  { width: Width.date }, // date
  { width: Width.text }, // holiday
  { width: Width.date }, // working time
  { width: Width.time }, // checkin
  { width: Width.time }, // checkout
  { width: Width.time }, // late
  { width: Width.time }, // early
  { width: Width.time }, // working hour
  { width: Width.time }, // OT
  { width: Width.content }, // note
  { width: Width.time }, // temp
  { width: Width.time }, // temp
  { width: Width.time }, // temp
  { width: Width.time }, // temp
]

let state
export class EmployeeSummary extends BaseStore {
  constructor() {
    super()
    this.observable({
      summary: {},
      list: [],
    })
    state = this
  }

  async getEmployee(query, { isThai, employee } = {}) {
    const { date, user_id } = query
    const dateTxt = date.format('MMYYYY')
    const url = `${Url}/${dateTxt}/${user_id}`
    const res = await http.get(url)

    const list = res.body || []

    const counter = {
      work_day: 0,
      missing_work: 0,
      late_minute: 0,
      early_minute: 0,
      work_minute: 0,

      total_leave: 0,
      vocation_leave: 0,
      sick_leave: 0,
      business_leave: 0,
      information_leave: 0,
      other_leave: 0,
    }
    const content_list = list.map((it) => {
      const {
        is_holiday,
        holiday_name,
        start_work_at,
        end_work_at,
        checkin_at,
        checkout_at,
      } = it

      const { text, leave_day } = toLeaveReq(it, { counter, isThai })

      const one_day_minute = it.setting_work_minute || 0
      const half_day_minute =
        one_day_minute > 0 ? Math.round(one_day_minute / 2) : 0
      const late_minute = it.late_minute || 0
      const early_minute = it.early_minute || 0
      const work_minute = it.work_minute || 0

      counter.late_minute += late_minute
      counter.early_minute += early_minute
      counter.work_minute += work_minute

      const isMissingWork = !is_holiday && leave_day === 0
      if (work_minute <= 0 || one_day_minute <= 0) {
        if (isMissingWork) counter.missing_work += 1 - leave_day
      } else if (work_minute >= one_day_minute) {
        counter.work_day++
      } else if (work_minute >= half_day_minute) {
        counter.work_day += 0.5
        if (isMissingWork) counter.missing_work += 0.5
      }
      return [
        toDate(it.date, { isThai }),
        is_holiday ? holiday_name : '',
        toWorkingTime(start_work_at, end_work_at),
        toTime(checkin_at),
        toTime(checkout_at),
        toTimeFromMinutes(late_minute),
        toTimeFromMinutes(early_minute),
        toTimeFromMinutes(work_minute),
        '', // OT
        text,
      ]
    })

    const summary = {
      work_day: counter.work_day,
      missing_work: counter.missing_work,
      late_minute: toTimeFromMinutes(counter.late_minute),
      early_minute: toTimeFromMinutes(counter.early_minute),
      work_minute: toTimeFromMinutes(counter.work_minute),

      total_leave: counter.total_leave,
      vocation_leave: counter.vocation_leave,
      sick_leave: counter.sick_leave,
      business_leave: counter.business_leave,
      information_leave: counter.information_leave,
      other_leave: counter.other_leave,
    }
    return { employee, summary, content_list }
  }

  async getList(query, { isThai } = {}) {
    const { user_id } = query
    if (!user_id) {
      runInAction(() => {
        state.summary = {}
        state.list = []
      })
      return
    }

    const { summary, content_list } = await this.getEmployee(query, { isThai })

    runInAction(() => {
      state.summary = summary || {}
      state.list = content_list || []
    })
  }

  async export({ date, user_id }, { employee_list = [], isThai } = {}) {
    let contentList = []
    if (user_id) {
      const employee = employee_list.find((it) => it.value === user_id)
      const result = this.toJS()

      contentList.push({
        employee: employee ? employee.tag : {},
        summary: result.summary,
        content_list: result.list,
      })
    } else {
      let processList = []
      for (const item of employee_list) {
        const employee = item ? item.tag : {}

        processList.push(
          this.getEmployee(
            { date, user_id: employee.user_id },
            { isThai, employee }
          )
        )

        if (processList.length >= 5) {
          const resultList = await Promise.all(processList)

          contentList = contentList.concat(resultList)
          processList = []
        }
      }

      if (processList.length > 0) {
        const resultList = await Promise.all(processList)

        contentList = contentList.concat(resultList)
      }
    }

    await this.exportFile(date, contentList, { isThai })
  }

  async exportFile(date, list = [], { isThai } = {}) {
    const dateTxt = date.format('DDMMYYYY')

    const workbook = XLSX.utils.book_new()
    const names = {}
    for (const item of list) {
      const { employee, summary = {}, content_list = [] } = item
      let { fullname, tabname } = toTabname(employee, { isThai })

      const Text = isThai ? Table.th : Table.en
      const dataList = [[Text.name, fullname]]

      // Put Summary
      dataList.push([
        Text.work_day,
        summary.work_day,
        Text.missing_work,
        summary.missing_work,
        Text.late_work,
        summary.late_minute,
        Text.leave_early,
        summary.early_minute,
        Text.overtime,
        '',
      ])

      dataList.push([
        Text.total_leave,
        summary.total_leave,
        Text.vocation_leave,
        summary.vocation_leave,
        Text.sick_leave,
        summary.sick_leave,
        Text.business_leave,
        summary.business_leave,
        Text.information_leave,
        summary.information_leave,
        Text.other_leave,
        summary.other_leave,
      ])

      // Put Header
      dataList.push([])
      dataList.push(isThai ? Header.th : Header.en)
      for (const content of content_list) {
        dataList.push(content)
      }

      const ws = XLSX.utils.aoa_to_sheet(dataList)
      ws['!cols'] = ColumnList

      if (names[tabname]) {
        names[tabname] = names[tabname] + 1
        tabname += ' ' + names[tabname]
      } else {
        names[tabname] = 1
      }

      XLSX.utils.book_append_sheet(workbook, ws, tabname)
    }

    XLSX.writeFile(workbook, `monthly-summary-${dateTxt}.xlsx`)
  }
}

export default new EmployeeSummary()
