import { runInAction } from 'mobx'
import * as XLSX from 'xlsx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Headers = [
  'user id',
  'date',
  'category',
  'leave id',
  'name',
  'surname',
  'nickname',
  'used',
  'balance',
]

const getDefault = (user = {}, options = {}) => {
  const { user_id, name, surname, nickname, img_url } = user
  const {
    leave_type_id,
    leave_type,
    leave_category,
    balance_type,
    balance_day,
  } = options

  const leaveName = options.name || ''
  const leaveNameTh = options.name_th || ''

  return {
    user_id,
    name,
    surname,
    nickname,
    img_url,
    status: 'active',
    leave_type,
    leave_type_id,
    leave_category,
    leave_name: leaveName,
    leave_name_th: leaveNameTh,
    balance_type,
    balance_day: 0, // user used the leave request
    option_type: 'unused',
    total_day: balance_day, // total from leave type
    calc_balance_day: 0, // total from manual
  }
}

const getListForUser = (
  balanceList = [],
  leaveBalanceList = [],
  userList = [],
  options = {}
) => {
  const { leave_type_id, leave_category } = options
  const leaveBalanceItem = leaveBalanceList.find((it) => {
    if (leave_type_id) {
      return it.leave_type_id === leave_type_id
    }

    return it.leave_category === leave_category
  })

  const leaveType = leaveBalanceItem || {}
  const list = []
  for (const user of userList) {
    const { user_id } = user
    const i = balanceList.findIndex((it) => it.user_id === user_id)
    let item = {}
    if (i !== -1) {
      const balance = balanceList[i]
      balanceList.splice(i, 1)

      balance.leave_name = leaveType.name
      balance.leave_name_th = leaveType.name_th
      balance.total_day = leaveType.balance_day
      item = balance
    } else {
      item = getDefault(user, leaveType)
    }

    list.push(item)
  }
  return list
}

const getListForLeaveType = (balanceList = [], leaveBalanceList = [], user) => {
  const list = []
  for (const leaveType of leaveBalanceList) {
    const { leave_type_id, leave_category } = leaveType
    const i = balanceList.findIndex((it) => {
      if (it.leave_type === 'leave_type') {
        return it.leave_type_id === leave_type_id
      }
      return it.leave_category === leave_category
    })

    let item = {}
    if (i !== -1) {
      const balance = balanceList[i]
      balanceList.splice(i, 1)

      balance.leave_name = leaveType.name
      balance.leave_name_th = leaveType.name_th
      balance.total_day = leaveType.balance_day
      item = balance
    } else {
      item = getDefault(user, leaveType)
    }

    list.push(item)
  }
  return list
}

const getItemForLeaveType = (
  balanceList = [],
  leaveBalanceList = [],
  options = {}
) => {
  const { user_id, leave_type_id, leave_category } = options
  const leaveBalanceItem = leaveBalanceList.find((it) => {
    if (leave_type_id) {
      return it.leave_type_id === leave_type_id
    }

    return it.leave_category === leave_category
  })

  const item = balanceList.find((it) => {
    return (
      it.user_id === user_id &&
      it.leave_category === leave_category &&
      it.leave_type_id === leave_type_id
    )
  })

  const leaveType = leaveBalanceItem || {}
  if (item) {
    item.leave_name = leaveType.name
    item.leave_name_th = leaveType.name_th
    item.total_day = leaveType.balance_day
    return [item]
  } else {
    const balanceItem = getDefault(options, leaveType)
    return [balanceItem]
  }
}

const Url = `${config.api}/v1/user-admin/leave-request/leave-balance`
let state
export class UserBalance extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
      leave_balance_list: [],
      leave_balance_menu: {
        th: [],
        en: [],
      },
    })

    state = this
  }

  reset() {
    runInAction(() => {
      state.page = {
        index: 1,
        per_page: 20,
        total: 0,
      }
      state.list = []
    })
  }

  getFilter(params = {}) {
    const { user_id, leave_type_id, leave_category } = params
    if (!user_id && !leave_category) return ''

    let query = '?index=0'
    if (user_id) query += `&user_id=${user_id}`

    if (leave_type_id) query += `&leave_type_id=${leave_type_id}`
    if (leave_category) query += `&leave_category=${leave_category}`

    return query
  }

  async getBalanceList(params = {}, leaveBalanceList, employeeList) {
    const { date, user_id, leave_category } = params
    const txt = date.toISOString()
    const query = this.getFilter(params)
    if (query === '') {
      this.reset()
      return
    }
    const url = `${Url}/report/${txt}${query}`
    const res = await http.get(url)

    const balanceList = res.body || []
    let list = []

    const isLeaveType = !!leave_category
    if (user_id && isLeaveType) {
      list = getItemForLeaveType(balanceList, leaveBalanceList, params)
    } else if (user_id) {
      list = getListForLeaveType(balanceList, leaveBalanceList, params)
    } else if (isLeaveType) {
      list = getListForUser(balanceList, leaveBalanceList, employeeList, params)
    }

    runInAction(() => {
      state.page = {
        index: 1,
        per_page: 10,
        total: list.length,
      }
      state.list = list
    })
  }

  setPagination(page = {}) {
    runInAction(() => {
      state.page = page
    })
  }

  async updateBalance({ date }, json = {}) {
    const {
      leave_balance_id,
      leave_type,
      leave_type_id,
      leave_category,
      user_id,
    } = json

    let data
    if (leave_balance_id) {
      const url = `${Url}/${leave_balance_id}`
      const res = await http.put(url, { json })
      data = res.body
    } else {
      const txt = date.toISOString()
      const url = `${Url}/${user_id}/${txt}`
      const res = await http.post(url, { json })
      data = res.body
    }

    const { list = [] } = this.toJS()
    const index = list.findIndex((it) => {
      if (it.user_id !== user_id) {
        return false
      }
      if (leave_balance_id) {
        return it.leave_balance_id === leave_balance_id
      }
      if (leave_type === 'leave_type' && leave_type_id) {
        return it.leave_type_id === leave_type_id
      }

      return it.leave_category === leave_category
    })

    if (index !== -1) {
      json.leave_balance_id = data.leave_balance_id
      list[index] = json

      runInAction(() => {
        state.list = list
      })
    }
  }

  async getLeaveBalanceMenu() {
    const leave_balance_list = this.toJS().leave_balance_list
    if (leave_balance_list.length > 0) return

    const url = `${Url}/leave-type`
    const res = await http.get(url)

    const list = res.body || []

    const th = []
    const en = []
    let index = 0
    for (const item of list) {
      const value = `${index}`
      th.push({
        name: item.name_th || item.name,
        value,
        tag: item,
      })

      en.push({
        name: item.name,
        value,
        tag: item,
      })

      index++
    }

    runInAction(() => {
      state.leave_balance_list = list
      state.leave_balance_menu = {
        th,
        en,
      }
    })
  }

  exportToFile(balance_date) {
    const balanceList = this.toJS().list || []
    const list = balanceList.map((it) => {
      const {
        user_id,
        balance_type,
        leave_type,
        leave_type_id,
        leave_category,
        name,
        surname,
        nickname,
        balance_day,
        option_type,
      } = it

      const total = option_type === 'unused' ? it.total_day : it.option_day
      const format = balance_type === 'per_year' ? 'YYYY' : 'MM/YYYY'
      const dateTxt = balance_date.format(format)
      return [
        user_id,
        dateTxt,
        leave_category,
        leave_type === 'leave_type' ? leave_type_id : '',
        name,
        surname,
        nickname,
        balance_day || 0,
        total || 0,
      ]
    })

    list.unshift(Headers)
    const today = timer.get()
    const date = today.format('DDMMYY')

    const ws = XLSX.utils.aoa_to_sheet(list)
    ws['!cols'] = [12, 12, 18, 12, 18, 18, 18, 12, 12].map((width) => {
      return { width }
    })

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, ws, 'leave-balance')

    XLSX.writeFile(workbook, `leave-balance-${date}.xlsx`)
  }
}

export default new UserBalance()
