/* eslint-disable */
const Logo = (props) => {
  const { size = 1, stroke = '2', color = '#494949' } = props // 2A9C9D
  const width = `${Math.ceil(32 * size)}`
  const height = `${Math.ceil(32 * size)}`

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 18V28C1 29.6569 2.34315 31 4 31H28C29.6569 31 31 29.6569 31 28V18" stroke={color} strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 22V2" stroke={color} strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.5 11L16 1L25.5 11" stroke={color} strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

/* eslint-enable */
export default Logo
