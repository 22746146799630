import * as XLSX from 'xlsx'

import { timer } from './timer'

export const sleep = (ms = 1000) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const isNull = (val) => {
  return val === null || val === undefined
}

export const notNull = (val) => {
  return val !== null && val !== undefined
}

export const isValue = (val) => {
  return val !== null && val !== undefined && val !== ''
}

export const isTextError = (val, text) => {
  return val === '' || val === null ? text : undefined
}

export const random = ({
  len,
  prefix = '',
  postfix = '',
  character = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
}) => {
  let need = len - (prefix.length + postfix.length)
  let max = character.length

  if (need < 0) return
  if (max === 0) return
  let c = ''

  for (let i = 0; i < need; i++) {
    c += character.charAt(Math.floor(Math.random() * max))
  }

  return `${prefix}${c}${postfix}`
}

export const isUrl = (val) => {
  if (isValue(val)) {
    let prefix = val.substring(0, 4)
    return prefix === 'http'
  }

  return false
}

export const trim = (data, list = []) => {
  list.forEach((name) => {
    let temp = data[name]
    if (temp) data[name] = temp.trim()
  })

  return data
}

export const compareString = (src, target) => {
  return String(src).toLocaleLowerCase() === String(target).toLocaleLowerCase()
}

export const getUrl = (list = []) => {
  const img_url = list.length > 0 ? list[0] : undefined
  return img_url
}

export const initMenu = (list = []) => {
  const keys = {}
  const menu = []
  const menu_th = []
  for (const item of list) {
    const { name, name_th, value } = item

    keys[value] = item
    menu_th.push({ name: name_th, value })
    menu.push({ name, value })
  }

  return { keys, menu, menu_th }
}

export const loadFile = (file, onLoadData) => {
  try {
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onload = (e) => {
      //const content = e.target.result
      //const lines = content.split(/\r\n|\n/)
      //const headerLine = lines.shift()
      //const headerTabs = headerLine.split('\t')

      // Parse data
      const bstr = e.target.result
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' })
      // Get first worksheet
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      // Convert array of arrays
      const data = XLSX.utils.sheet_to_json(ws, { header: 2 })
      onLoadData(data)
    }

    reader.onerror = (e) => {
      onLoadData(null, e.target.error.name)
    }

    //reader.readAsText(file)
    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)
  } catch (e) {
    console.log('load error:', e.message)
    onLoadData(null, e.message)
  }
}

export const getDateRange = (val, { start_date, end_date }) => {
  const today = timer.get()

  switch (val) {
    case 'today':
      start_date = today
      end_date = today
      break
    case 'yesterday':
      start_date = today.subtract(1, 'day')
      end_date = start_date
      break
    case 'this_month':
      start_date = today.startOf('month')
      end_date = timer.get(start_date).endOf('month')
      break
    case 'last_month':
      start_date = today.subtract(1, 'month').startOf('month')
      end_date = timer.get(start_date).endOf('month')
      break
    case '3_month':
      start_date = timer.get().subtract(3, 'month')
      end_date = today
      break
    case 'this_year':
      start_date = today.startOf('year')
      end_date = timer.get(start_date).endOf('year')
      break
    default:
  }

  return { start_date, end_date }
}

export const toTvi50Id = (value = '', list = []) => {
  let index = 0
  const len = value.length
  const content = list.map((it, i) => {
    const { style, isBar } = it
    if (isBar) {
      return <div key={i} style={style} />
    } else {
      const txt = index > len ? '' : value[index]
      const box =
        txt === '' ? (
          <div key={i} style={style}>
            &nbsp;
          </div>
        ) : (
          <div key={i} style={style}>
            {txt}
          </div>
        )
      index++
      return box
    }
  })

  return content
}
