import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/payroll/welfare`
let state
export class Welfare extends BaseStore {
  constructor() {
    super()
    this.observable({
      menu_list: [],
      menu_th_list: [],
      data_list: [],
      report: {
        list: [],
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
      },
    })
    state = this
  }

  async getMenuList({ force = false } = {}) {
    if (force === false) {
      if (this.toJS().menu_list.length > 0) {
        return
      }
    }
    const url = `${Url}?limit=-1`
    const res = await http.get(url)

    const list = res.body || []

    const menu_list = []
    const menu_th_list = []
    for (const it of list) {
      const { welfare_id, name, name_th } = it
      menu_list.push({ name, value: welfare_id, tag: it })
      menu_th_list.push({ name: name_th, value: welfare_id, tag: it })
    }

    runInAction(() => {
      state.menu_list = menu_list
      state.menu_th_list = menu_th_list
      state.data_list = list
    })
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20, date } = query
    let q = `index=${index}&per_page=${per_page}`

    if (date) {
      const month = date.clone().startOf('month').toISOString()
      q += `&month=${month}`
    }

    return q
  }

  async getList(query = {}) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { total } = this.toJS().report.page
    runInAction(() => {
      state.report = {
        list,
        page: {
          index,
          per_page,
          total,
        },
      }
    })
  }

  async getCounter(query = {}) {
    const q = this.toQuery(query)
    const url = `${Url}/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.report.page.total = counter || 0
    })
  }

  async createWelfare(json = {}) {
    const url = Url
    const resp = await http.post(url, { json })

    const data = resp.body || {}
    const report = this.toJS().report
    const list = report.list || []

    list.push(data)
    report.list = list

    runInAction(() => {
      state.menu_list = []
      state.menu_th_list = []
      state.data_list = []
      state.report = report
    })
  }

  async updateWelfare({ welfare_id } = {}, json = {}) {
    const url = `${Url}/${welfare_id}`
    const resp = await http.put(url, { json })

    const data = resp.body || {}

    const report = this.toJS().report
    const list = report.list || []
    const i = list.findIndex((it) => it.welfare_id === welfare_id)
    if (i !== -1) {
      list[i] = data
      report.list = list
    }

    runInAction(() => {
      state.menu_list = []
      state.menu_th_list = []
      state.data_list = []
      state.report = report
    })
  }

  async deleteWelfare({ welfare_id } = {}) {
    const url = `${Url}/${welfare_id}`
    await http.delete(url)

    const report = this.toJS().report

    const list = report.list || []
    const i = list.findIndex((it) => it.welfare_id === welfare_id)
    if (i !== -1) {
      list.splice(i, 1)
      report.list = list
      report.total = report.total - 1
    }

    runInAction(() => {
      state.menu_list = []
      state.menu_th_list = []
      state.data_list = []
      state.report = report
    })
  }
}

export default new Welfare()
