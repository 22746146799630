import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const Url = `${config.api}/v1/user-admin/account/project`
const original = {
  project_id: undefined,
  project_name: '',
  detail: '',
  user_id: undefined,
  email: '',
  member_list: [],
}
let state
export class Team extends BaseStore {
  constructor() {
    super()
    this.observable({
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
      doc: cloneDeep(original),
      menu_list: [],
      data_list: [],
    })
    state = this
  }

  async getMenuList({ force = false } = {}) {
    if (force === false) {
      if (this.toJS().menu_list.length > 0) {
        return
      }
    }
    const url = `${Url}?status=active&per_page=-1`
    const resp = await http.get(url)

    const list = resp.body || []
    const menu_list = list.map((it) => {
      const { project_id, project_name } = it
      return { name: project_name, value: project_id, tag: it }
    })
    runInAction(() => {
      state.menu_list = menu_list
      state.data_list = list
    })
  }

  async getDoc(project_id) {
    if (!project_id) {
      runInAction(() => {
        state.doc = cloneDeep(original)
      })
      return
    }

    const doc = this.toJS().doc
    if (doc.project_id === project_id) {
      return
    }

    const url = `${Url}/${project_id}/detail`
    const resp = await http.get(url)

    const data = resp.body || {}
    runInAction(() => {
      state.doc = data
    })
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20 } = query

    const q = `index=${index}&per_page=${per_page}`
    return q
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page
    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCountList(query) {
    const q = this.toQuery(query)
    const url = `${Url}/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async updateTeam(json = {}) {
    const { project_id } = json

    let res
    if (project_id) {
      const url = `${Url}/${project_id}/detail`
      res = await http.put(url, { json })
    } else {
      const url = `${Url}`
      res = await http.post(url, { json })
    }

    const doc = res.body
    runInAction(() => {
      state.doc = doc
    })

    return doc
  }

  async deleteTeam(project_id) {
    const url = `${Url}/${project_id}/delete`
    await http.put(url, {})

    const { list, page } = this.toJS()
    const index = list.findIndex((it) => it.project_id === project_id)
    if (index !== -1) {
      list.splice(index, 1)
    }
    page.total -= 1
    runInAction(() => {
      state.list = list
      state.page = page
    })
  }
}

export default new Team()
