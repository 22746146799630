import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import BaseStore from './BaseStore'

const original = {
  email: '',
  name: '',
  surname: '',
}

let state
export class Account extends BaseStore {
  constructor() {
    super()
    this.observable({
      user: cloneDeep(original),
    })

    state = this
  }

  setProfile(doc) {
    runInAction(() => {
      state.user = doc
    })
  }
}

export default new Account()
